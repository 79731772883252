/* global kendo IntersectionObserver */
// @require "./**/*.html"
import ScannerDetector from 'js-scanner-detection'
import _ from 'lodash'
import angular from 'angular'
import 'angular-translate'
import 'angular-ui-tree'
import 'angular-socket-io'
import DOMPurify from 'dompurify'
import 'angular-aria'
import 'ngstorage'
import 'ng-idle/index'
import 'angular-animate'
import AngularCookies from 'angular-cookies'
import AngularResource from 'angular-resource'
import AngularMaterial from 'angular-material'
import '@uirouter/angularjs'
import '@uirouter/angularjs/release/stateEvents'
import config from './index.config'
import routeConfig from './index.route'
import runBlock from './index.run'
import IndexController from './index.controller'
import nextplusSocket from './index.socket'
import customTranslateLoader from './services/custom-translate-service'
import lbServices from './lb-services'
import flatpickr from 'flatpickr'
import oclazyload from 'oclazyload'
import angularBind from 'angular-bind-html-compile'
import 'angular-formly-material'
import ngSanitize from 'angular-sanitize'
import 'angular-formly'
import '../vendors/angularjs-color-picker.min'
import ngMessages from 'angular-messages'
import angularMoment from 'angular-moment'
import alertDialog from './alertDialog'

import DateUtils from './modules/core/services/date-utils.service'
import LanguageUtils from './modules/core/services/language-utils.factory.js'
import '../vendors/anLoader/anLoader'
import MainCtrl from './modules/main/main.controller.js'

import jsonLogic from 'json-logic-js'
import rangy from 'rangy/lib/rangy-core'
import jsonlint from '../vendors/jsonlint'

import 'rangy/lib/rangy-selectionsaverestore'

import 'root/node_modules/textangular/dist/textAngular-sanitize.min.js'
import 'root/node_modules/textangular/dist/textAngularSetup.js'
import 'root/node_modules/textangular/dist/textAngular.min.js'
import 'hotkeys-js'
// APPS

import AppLogin from './modules/main/login/Login'
import AppNavigation from './modules/navigation/navigation.module'
import TinyMce from './modules/tinymce/tinymce.module'

import AppCore from './modules/core/Core'
import ForumConfig from './modules/main/forum/forum.config.js'
import FormConfig from './modules/main/form/form.config.js'
import TimelineConfig from './modules/main/timeline/timeline.config.js'
import TimeLoggingConfig from './modules/main/time-logging/time-logging.config.js'
import TableConfig from './modules/main/tables/tables.config.js'
import AppToolbar from './modules/toolbar/toolbar.module.js'
import AppQuickPanel from './modules/quick-panel/quick-panel.module.js'
import AppComponents from './modules/components/components.module.js'
import AppFileManager from './modules/main/file-manager/file-manager.module.js'
import AppComment from './modules/main/comment/comment.module.js'
import AppLocation from './modules/main/location/location.module.js'
import AppReports from './modules/main/reports/reports.module.js'
import AppTool from './modules/main/tool/tool.module.js'
import AppWorkorder from './modules/main/workorder/workorder.module.js'
import AppUser from './modules/main/user/user.module.js'
import AppResetPassword from './modules/main/reset-password/reset-password.module.js'
import WorkflowConfig from './modules/main/workflow/workflow.config.js'
import PlaygroundConfig from './modules/main/playground/playground.config.js'
import AppPagesConfig from './modules/main/pages-management.module/pages-management.config.js'
import AppMenusConfig from './modules/main/menu-management/menu-management.config.js'
import SettingsConfig from './modules/main/settings/settings.config.js'
import IoTConfig from './modules/main/iot/iot.config.js'
import AppProductionEntity from './modules/main/production-entity/production-entity.module.js'
import AppShift from './modules/main/shift/shift.module.js'
import Catalog from './catalog/app'
import Document from './document/app'
import LocalStorageUtils from './services/local-storage-service.js'
import DocumentUtils from './services/document-utils-service.js'
import ErrorHandlerService from './services/error-handler-service.js'
import WireUtils from './services/wire-utils-service.js'
import WorkflowUtilsService from './services/workflow-utils-service'
import DialogService from './services/dialog-service'
import FieldUtilsService from './services/field-utils-service'
import VariablesUtilsService from './services/variables-utils-service'
import UsersService from './services/users-service.js'
import CatalogUtils from './services/catalog-utils-service.js'
import SyncUtils from './services/sync-service.js'
import NotificationUtils from './services/notification-service.js'
import visionService from './services/vision-service.js'
import WizardConditionsService from './services/wizard-conditions-service.js'
import FormUtils from './services/form-utils-service.js'
import WorkorderUtils from './services/workorder-utils-service.js'
import UserUtils from './services/user-utils-service.js'
import ViewsService from './services/views-service.js'
import VariantsService from './services/variants-service.js'
import ResourceUtils from './services/resource-service.js'
import PermissionUtils from './services/permission-service.js'
import FormlyHelper from './services/formly-helper-service.js'
import PanelHelper from './services/panel-helper-service.js'
import FormlyValidations from './services/formly-validations.js'
import KendoGridHelper from './services/kendo-grid-helper-service.js'
import KendoDataSourceHelper from './services/kendo-datasource-helper-service.js'
import KendoFilterHelper from './services/kendo-filter-helper-service.js'
import KendoTreeViewHelper from './services/kendo-tree-view-helper-service.js'
import UploadService from './services/upload-service.js'
import BreakService from './services/break-service'
import AIService from './services/ai-service'
import EvalService from './services/eval-service'
import EventService from './services/event-service'
import IntercomService from './services/intercom-service'
import MultiTranslateService from './services/multitranslate-service'
import NewVersionDialog from './services/new-version'
import htmlWork from './factory/htmlWork.js'
import WorkflowCustomFieldsController from './modules/main/workflow/workflowCustomFields/workflow.custom-fields.controller'
import WorkorderStatusFieldsEditController from './modules/main/workorder/statuses/workorder-status-fields-dialog-edit.controller.js'
import CameraCaptureDialogController from './common/dialog/camera-capture.dialog.controller.js'
import PartCreateDialogController from './common/dialog/part-create-dialog/part-create.dialog.controller.js'
import ConditionsDialogController from './common/dialog/conditions-dialog/conditions-dialog.js'
import VisionDialogController from './common/dialog/vision.dialog.controller.js'
import ViewsDialogController from './common/dialog/views.dialog.controller.js'
import PrintPresetsController from './modules/main/workflow/edit/dialog/print.presets.controller'
import timeLogReportService from './services/timeLogReportService'
import DateTimeFormatService from './services/date-time-format-service'
import AuthenticationTimeoutService from './services/authentication-timeout-service'
import productionEntityService from './modules/main/workflow/show/production-entity/production-entity.service'

import FileTypes from './constants/file-types.js'
import jsonFormatter from 'jsonformatter'
import './modules/core/scss/global.scss'
import 'iv-viewer/dist/iv-viewer.css'

import 'root/node_modules/@progress/kendo-ui/js/kendo.angular.min'
import 'root/node_modules/@progress/kendo-ui/js/kendo.filter'
import 'root/node_modules/@progress/kendo-ui/js/kendo.filtercell'
import 'root/node_modules/@progress/kendo-ui/js/kendo.core'
import 'root/node_modules/@progress/kendo-ui/js/kendo.grid'
import 'root/node_modules/@progress/kendo-ui/js/kendo.filtercell.min'
import 'root/node_modules/@progress/kendo-ui/js/kendo.multiselect.min'
import 'root/node_modules/@progress/kendo-ui/js/kendo.datetimepicker.min'
import 'root/node_modules/@progress/kendo-ui/js/kendo.dropdowntree.min'
import 'root/node_modules/@progress/kendo-ui/js/kendo.colorpicker.min'
import 'root/node_modules/@progress/kendo-ui/js/cultures/kendo.culture.he.min'
import 'root/node_modules/@progress/kendo-ui/js/cultures/kendo.culture.en.min'
import expr from '../vendors/expr'
window.expr = expr
kendo.pdf.defineFont({
  Rubik: 'assets/fonts/Rubik/Rubik-Regular.ttf',
  'Rubik-Regular': 'assets/fonts/Rubik/Rubik-Regular.ttf',
  'Rubik|Light': 'assets/fonts/Rubik/Rubik-Light.ttf',
  'Rubik|Regular': 'assets/fonts/Rubik/Rubik-Regular.ttf',
  'Rubik|Bold': 'assets/fonts/Rubik/Rubik-Bold.ttf',
  Roboto: 'assets/fonts/Roboto/Roboto-Regular.ttf',
  'Roboto|Light': 'assets/fonts/Roboto/Roboto-Light.ttf',
  'Roboto|Regular': 'assets/fonts/Roboto/Roboto-Regular.ttf',
  'Roboto|Bold': 'assets/fonts/Roboto/Roboto-Bold.ttf'
})
require('root/node_modules/jsonformatter/dist/json-formatter.min.css')

// disable scrolling on number fields
document.addEventListener('wheel', function (event) {
  if (
    document.activeElement.nodeName === 'INPUT' &&
    document.activeElement.type === 'number'
  ) {
    document.activeElement.blur()
  }
})
require('./jquery-extends.js')
const Mustache = require('mustache')
const apiCheck = require('api-check')
window.cloneLayer = require('./../vendors/clone-layer')

apiCheck.globalConfig.disabled = true

require('moment').locale('he')

// require('async')
global.EventEmitter = require('eventemitter3')
require('socket.io-client')
require('./directives/angular-avatar')
;(() => {
  window.DOMPurify = DOMPurify
  window.rangy = rangy
  window.jsonLogic = jsonLogic
  window.Mustache = Mustache
  window.jsonlint = jsonlint
  window.flatpickr = flatpickr
  window.ScannerDetector = ScannerDetector
  window.DOMPurify = DOMPurify
})()
const MODULE_NAME = 'nextplus'

angular
  .module(MODULE_NAME, [
    require('angular-input-masks'),
    'ngAvatar',
    'kendo.directives',
    'pascalprecht.translate',
    'ngStorage',
    'textAngular',
    AngularMaterial,
    'color.picker',
    'anLoader',
    'ui.router',
    'ngIdle',
    'ui.router.state.events',
    AngularCookies,
    'btford.socket-io',
    jsonFormatter,
    AngularResource,
    lbServices,
    // require('./react/angularReactLoader').name,
    oclazyload,
    angularBind,
    'formlyMaterial',
    ngMessages,
    ngSanitize,
    angularMoment,
    AppCore,
    AppLogin,
    AppNavigation,
    TinyMce,
    AppToolbar,
    AppQuickPanel,
    AppLogin,
    AppResetPassword,
    AppFileManager,
    AppFileManager,
    AppLocation,
    AppComment,
    AppReports,
    AppTool,
    AppUser,
    AppWorkorder,
    AppComponents,
    Catalog,
    Document,
    AppProductionEntity,
    AppShift
  ])
  .config(config)
  .config(routeConfig)
  .config([
    'TitleProvider',
    function (TitleProvider) {
      TitleProvider.enabled(false) // it is enabled by default
    }
  ])
  /** @ngInject */
  .config(function () {
    angular.lowercase = angular.$$lowercase
  })
  .config(AppPagesConfig)
  .config(AppMenusConfig)
  .config(SettingsConfig)
  .config(IoTConfig)
  .config(ForumConfig)
  .config(PlaygroundConfig)
  .config(WorkflowConfig)
  .config(FormConfig)
  .config(TimelineConfig)
  .config(TimeLoggingConfig)
  .config(TableConfig)
  .factory('customTranslateLoader', customTranslateLoader)
  .factory('httpRequestInterceptor', $rootScope => {
    return {
      request: function (config) {
        config.headers.socketId = $rootScope.socketId
        config.headers.lang = $rootScope.currentLang
        return config
      }
    }
  })

  .config(function (IdleProvider, KeepaliveProvider) {
    // configure Idle settings
    IdleProvider.idle(60 * 60)
    IdleProvider.timeout(60 * 60)
  })
  .config(function ($compileProvider, $httpProvider) {
    $httpProvider.interceptors.push('httpRequestInterceptor')
    $httpProvider.interceptors.push(
      /* @ngInject */
      function ($rootScope, $window) {
        return {
          request: function (config) {
            if ($rootScope?.currentUser?.accessTokenId) {
              config.headers.authorization =
                $rootScope.currentUser.accessTokenId
            }
            return config
          }
        }
      }
    )
    // configure new 'compile' directive by passing a directive
    // factory function. The factory function injects the '$compile'
    $compileProvider.directive('compile', function ($compile) {
      // directive factory creates a link function
      return function (scope, element, attrs) {
        scope.$watch(
          // first param is name of property in scope to watch
          function (scope) {
            // watch the 'compile' expression for changes
            return scope.$eval(attrs.compile)
          },
          // second param is function to run if that property value changes.
          function (value) {
            // when the 'compile' expression changes
            // assign it into the current DOM
            element.html(value)

            // compile the new DOM and link it to the current
            // scope.
            // NOTE: we only compile .childNodes so that
            // we don't get into infinite loop compiling ourselves
            $compile(element.contents())(scope)
          }
        )
      }
    })
  })
  .factory('nextplusSocket', nextplusSocket)
  .controller('IndexController', IndexController)
  .controller('MainController', MainCtrl)
  .controller('WorkflowCustomFieldsController', WorkflowCustomFieldsController)
  .controller('PrintPresetsController', PrintPresetsController)
  .controller(
    'WorkorderStatusFieldsEditController',
    WorkorderStatusFieldsEditController
  )
  .controller('PartCreateDialogController', PartCreateDialogController)
  .controller('VisionDialogController', VisionDialogController)
  .controller('ConditionsDialogController', ConditionsDialogController)
  .controller('CameraCaptureDialogController', CameraCaptureDialogController)
  .controller('ViewsDialogController', ViewsDialogController)
  .service('alertDialog', alertDialog)
  .service('DateUtils', DateUtils)
  .factory('LanguageUtils', LanguageUtils)
  .service('newVersionDialog', NewVersionDialog)
  .factory('LocalStorageUtils', LocalStorageUtils)
  .factory('DocumentUtils', DocumentUtils)
  .factory('ErrorHandlerService', ErrorHandlerService)
  .factory('WireUtils', WireUtils)
  .factory('FormUtils', FormUtils)
  .factory('WorkorderUtils', WorkorderUtils)
  .factory('UserUtils', UserUtils)
  .factory('WorkflowUtilsService', WorkflowUtilsService)
  .factory('DialogService', DialogService)
  .factory('FieldUtilsService', FieldUtilsService)
  .factory('VariablesUtilsService', VariablesUtilsService)
  .factory('UsersService', UsersService)
  .factory('CatalogUtils', CatalogUtils)
  .factory('Page', require('./factory/Page.js'))
  .factory(
    'httpResponseInterceptor',
    require('./factory/httpResponseInterceptor.js')
  )
  .factory('SyncUtils', SyncUtils)
  .factory('NotificationUtils', NotificationUtils)
  .factory('visionService', visionService)
  .factory('WizardConditionsService', WizardConditionsService)
  .factory('ViewsService', ViewsService)
  .factory('timeLogReportService', timeLogReportService)
  .factory('AuthenticationTimeoutService', AuthenticationTimeoutService)
  .factory('DateTimeFormatService', DateTimeFormatService)
  .factory('VariantsService', VariantsService)
  .factory('ResourceUtils', ResourceUtils)
  .factory('PermissionUtils', PermissionUtils)
  .factory('FormlyValidations', FormlyValidations)
  .factory('FormlyHelper', FormlyHelper)
  .factory('PanelHelper', PanelHelper)
  .factory('KendoGridHelper', KendoGridHelper)
  .factory('KendoDataSourceHelper', KendoDataSourceHelper)
  .factory('KendoFilterHelper', KendoFilterHelper)
  .factory('KendoTreeViewHelper', KendoTreeViewHelper)
  .factory('UploadService', UploadService)
  .factory('BreakService', BreakService)
  .service('AIService', AIService)
  .factory('EvalService', EvalService)
  .factory('EventService', EventService)
  .factory('IntercomService', IntercomService)
  .service('productionEntityService', productionEntityService)
  .service('MultiTranslateService', MultiTranslateService)
  .factory('htmlWork', htmlWork)
  .constant('FileTypes', FileTypes)
  .factory('getUrlFromObj', function (htmlWork) {
    return obj => {
      return obj
        ? '/api/containers/' +
            htmlWork.escapeString(obj.container) +
            '/download/' +
            htmlWork.escapeString(obj.name)
        : ''
    }
  })
  .config(function ($provide) {
    $provide.decorator('ColorPickerOptions', function ($delegate) {
      const options = angular.copy($delegate)
      options.swatchOnly = true
      options.hue = true
      options.saturation = false
      options.lightness = false
      options.alpha = false
      options.dynamicHue = false
      options.dynamicSaturation = false
      options.dynamicLightness = false
      options.dynamicAlpha = false
      return options
    })
  })
  .filter('trim', function () {
    return function (value) {
      if (!angular.isString(value)) {
        return value
      }
      return value.replace(/^\s+|\s+$/g, '') // you could use .trim, but it's not going to work in IE<9
    }
  })
  .filter('trusted', function ($sce) {
    const div = document.createElement('div')
    return function (text) {
      div.innerHTML = text
      return $sce.trustAsHtml(div.textContent)
    }
  })
  .filter('nametag', function ($sce) {
    return function (values, search) {
      let items = values
      items = items.filter(item => {
        if (
          item.name &&
          item.name.toString().toLowerCase().includes(search.toLowerCase())
        ) {
          return true
        }
        const tag = _.some(
          item.tags,
          tag => tag.toLowerCase().indexOf(search.toLowerCase()) > -1
        )
        if (tag) return true

        return false
      })
      return items
    }
  })
  .filter('custom', function () {
    return function (values, selectedCustomFields) {
      if (_.compact(_.flatten(_.values(selectedCustomFields))).length === 0) {
        return values
      }
      const selectedCustomFieldNames = _.keys(selectedCustomFields)
      return values.filter(value => {
        for (let i = 0; i < selectedCustomFieldNames.length; i++) {
          if (!value[selectedCustomFieldNames[i]]) return false
          if (selectedCustomFields[selectedCustomFieldNames[i]].length === 0) {
            return true
          }
          if (
            _.intersection(
              value[selectedCustomFieldNames[i]],
              selectedCustomFields[selectedCustomFieldNames[i]]
            ).length > 0
          ) {
            return true
          }
        }
        return false
      })
    }
  })
  .directive('onErrorSrc', function () {
    return {
      link: function (scope, element, attrs) {
        element.bind('error', function () {
          if (attrs.src !== attrs.onErrorSrc) {
            attrs.$set('src', attrs.onErrorSrc)
          }
        })
      }
    }
  })
  .directive('noResults', $translate => {
    return {
      restrict: 'E',
      link: function (scope, element, attrs) {
        const elm = angular.element(element)[0]
        const text = $translate.instant('COMMON.NO_RESULTS')
        const includeImg = attrs.includeImg === 'true'
        let html = `
          <div class="not-found">`
        if (includeImg) {
          html += `<img src="assets/images/cat.svg"/>`
        }
        html += `
            <div layout="column" layout-align="center" class="text-div">
              <h2>${text}</h2>
            </div>
          </div>`
        elm.innerHTML = html
      }
    }
  })
  .directive('loadingScreen', $translate => {
    return {
      restrict: 'E',
      link: function (scope, element, attrs) {
        const elm = angular.element(element)[0]
        const text = $translate.instant('COMMON.LOADING_SESSION')
        elm.innerHTML = `
          <div flex class="loading-screen" layout="column" layout-align="center center">
            <img src="assets/images/cat.svg"/>
            <div flex layout="column" layout-align="center" class="text-div">
              <span>${text}</span>
            </div>
          </div>`
      }
    }
  })
  .directive(
    'mdInputContainer',
    function mdInputContainerDirective ($mdTheming, $parse) {
      const INPUT_TAGS = ['INPUT', 'TEXTAREA', 'SELECT', 'MD-SELECT']

      const LEFT_SELECTORS = INPUT_TAGS.reduce(function (selectors, isel) {
        return selectors.concat([
          ':not(.selectboxWithSearch) md-icon ~ ' + isel,
          ':not(.selectboxWithSearch) .md-icon ~ ' + isel
        ])
      }, []).join(',')

      const RIGHT_SELECTORS = INPUT_TAGS.reduce(function (selectors, isel) {
        return selectors.concat([
          ':not(.selectboxWithSearch) ' + isel + ' ~ md-icon',
          ':not(.selectboxWithSearch) ' + isel + ' ~ .md-icon'
        ])
      }, []).join(',')

      return {
        restrict: 'E',
        compile,
        controller: ContainerCtrl
      }

      function compile (tElement) {
        // Check for both a left & right icon
        const leftIcon = tElement[0].querySelector(LEFT_SELECTORS)
        const rightIcon = tElement[0].querySelector(RIGHT_SELECTORS)

        if (leftIcon) {
          tElement.addClass('md-icon-left')
        }
        if (rightIcon) {
          tElement.addClass('md-icon-right')
        }

        return function postLink (scope, element) {
          $mdTheming(element)
        }
      }

      function ContainerCtrl ($scope, $element, $attrs, $animate) {
        const self = this

        self.isErrorGetter = $attrs.mdIsError && $parse($attrs.mdIsError)

        self.delegateClick = function () {
          self.input.focus()
        }
        self.element = $element
        self.setFocused = function (isFocused) {
          $element.toggleClass('md-input-focused', !!isFocused)
        }
        self.setHasValue = function (hasValue) {
          $element.toggleClass('md-input-has-value', !!hasValue)
        }
        self.setHasPlaceholder = function (hasPlaceholder) {
          $element.toggleClass('md-input-has-placeholder', !!hasPlaceholder)
        }
        self.setInvalid = function (isInvalid) {
          if (isInvalid) {
            $animate.addClass($element, 'md-input-invalid')
          } else {
            $animate.removeClass($element, 'md-input-invalid')
          }
        }
        $scope.$watch(
          function () {
            return self.label && self.input
          },
          function (hasLabelAndInput) {
            if (hasLabelAndInput && !self.label.attr('for')) {
              self.label.attr('for', self.input.attr('id'))
            }
          }
        )
      }
    }
  )
  .decorator(
    'mdInputContainerDirective',
    function mdInputContainerDirective ($delegate) {
      // Return a new array that contains only the next plus version
      // of the directive.
      return [$delegate[1]]
    }
  )
  .directive('lazyLoad', () => {
    return {
      restrict: 'AC',
      link: function (scope, element, attrs) {
        const img = angular.element(element)[0]
        const src = attrs.lazyLoad
        // Disable images lazy-load for FF 43
        if (typeof IntersectionObserver === 'undefined') {
          img.src = src
          return
        }
        img.src = './assets/transparent-1-1.png'
        const originalWidth = img.style.width
        const originalHeight = img.style.height
        const observer = new IntersectionObserver(changesCB)
        if (img.height && !img.style.height) {
          img.style.height = isNaN(img.height) ? img.height : `${img.height}px`
        }
        if (img.width && !img.style.width) {
          img.style.width = isNaN(img.width) ? img.width : `${img.width}px`
        }
        img.style.backgroundColor = '#e3e3e3'
        observer.observe(img)
        function changesCB (changes) {
          changes.forEach(change => {
            if (change.intersectionRatio > 0) {
              observer.disconnect()
              change.target.src = src
              if (!originalWidth) img.style.width = ''
              if (!originalHeight) img.style.height = ''
              img.style.backgroundColor = ''
              img.onerror = () => {
                img.style.backgroundColor = ''
              }
              img.onload = () => {
                img.style.backgroundColor = ''
              }
            }
          })
        }
      }
    }
  })
  .directive('ngSetFocus', [
    '$timeout',
    function ($timeout) {
      return {
        restrict: 'A',
        link: function (scope, element, attrs) {
          const delay = 300

          // set focus on broadcast
          scope.$on(attrs.ngSetFocus, function (e) {
            $timeout(function () {
              element[0].focus()
            }, delay)
          })

          // apply default focus after other events have complete
          $timeout(function () {
            if (
              Object.prototype.hasOwnProperty.call(attrs, 'setFocusDefault')
            ) {
              element[0].focus()
            }
          }, delay)

          // fix for default focus on iOS. Does not show keyboard
          element.on('touchstart', function (event) {
            element[0].blur()
          })
          scope.$on('$destroy', function () {
            element.off('touchstart')
          })
        }
      }
    }
  ])

  .factory('templates', require('./factory/templates.js'))
  .factory('PartLeafleat', require('./factory/part-leafleat.js'))
  .directive('variable', require('./directives/variable.js'))
  .directive(
    'inputFormula',
    require('./directives/input-formula/inputFormula.js')
  )
  .directive(
    'selectAssembly',
    require('./directives/catalogSelectAssembly/catalogSelectAssembly.js')
  )
  .directive('cssEditor', require('./directives/css-editor/cssEditor'))
  .directive('jsEditor', require('./directives/js-editor/jsEditor'))
  .directive('jsonEditor', require('./directives/json-editor/jsonEditor'))
  .directive('goToTop', require('./directives/goToTop/goToTop'))
  .directive('customStoryline', require('./directives/storyline.js'))
  .directive('resourceLink', require('./directives/resource-link.js'))
  .directive('post', require('./directives/post.js'))
  .directive('postWrapper', require('./directives/posts-wrapper.js'))
  .directive('whenscrollends', function () {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        element.css('height', '300px')
        const visibleHeight = 300
        const threshold = 100

        element.on('scroll', function (e) {
          const scrollableHeight = element.prop('scrollHeight')
          const hiddenContentHeight = scrollableHeight - visibleHeight
          if (hiddenContentHeight - element[0].scrollTop <= threshold) {
            scope.$apply(attrs.whenscrollends)
          }
        })
        // Cleanup
        scope.$on('$destroy', function () {
          element.off('scroll')
        })
      }
    }
  })
  .directive('embeddedForum', require('./directives/embedded-forum.js'))
  .directive('customIframe', require('./directives/iframe.js'))
  .directive(
    'searchResult',
    require('./directives/search-result/search-result.js')
  )
  .directive(
    'translateBar',
    require('./directives/translate-bar/translate-bar.js')
  )
  .directive('treeMenu', require('./directives/tree-menu/tree-menu.js'))
  .directive('cardGrid', require('./directives/card-grid/card-grid.js'))
  .directive(
    'treeCategoryGrid',
    require('./directives/tree-category-grid/tree-category-grid.js')
  )
  .directive(
    'variablesSelect',
    require('./directives/variables-select/variables-select.js')
  )
  .directive(
    'licenseSerial',
    require('./directives/license-serial/license-serial.js')
  )
  .component('datePicker', require('./components/date-picker/date-picker.js')())
  .component(
    'loadVariant',
    require('./components/variant/loadVariant/load-variant.js')()
  )
  .component(
    'conditionsWizard',
    require('./components/conditions-wizard/conditions-wizard.js')()
  )
  .component('aiPrompt', require('./components/ai-prompt/ai-prompt.js')())
  .component(
    'loadingText',
    require('./components/loading-text/loading-text.js')()
  )
  .component(
    'versionTable',
    require('./components/versions-table/versions-table.js')()
  )
  .component('stockView', require('./components/stock-view/stock-view.js')())
  .component(
    'stockDetails',
    require('./components/stock-details/stock-details.js')()
  )
  .component('swiper', require('./directives/swiper/swiper.js')())
  .component('userAvatar', require('./directives/user-avatar/user-avatar.js')())
  .component(
    'iconTextButton',
    require('./directives/icon-text-button/icon-text-button.js')()
  )

  .component('partEdit', require('./components/part-edit/part-edit.js')())
  .component('partView', require('./components/part-view/part-view.js')())
  .component(
    'gridWrapper',
    require('./components/grid-wrapper/grid-wrapper.js')()
  )
  .component(
    'gridToolbar',
    require('./components/grid-toolbar/grid-toolbar.js')()
  )

  .component('mapEditor', require('./components/map-editor/map-editor.js')())
  .component('grid', require('./components/grid/grid.js')())
  .component(
    'dynamicTable',
    require('./components/dynamic-table/dynamic-table.js')()
  )
  .component('starwars', require('./directives/starwars/starwars.js')())
  .component(
    'usersAvatar',
    require('./directives/users-avatar/users-avatar.js')()
  )
  .component('nextBox', require('./directives/next-box/next-box.js')())
  .component(
    'formHistory',
    require('./components/form-history/form-history.js')()
  )
  .component(
    'inlineIvViewer',
    require('./components/inline-iv-viewer/inline-iv-viewer.js')()
  )
  .component(
    'formApproval',
    require('./components/form-approval/form-approval.js')()
  )
  .directive('customForm', require('./directives/custom-form/custom-form.js'))
  .directive('customPdf', require('./directives/pdf.js'))
  .directive('customMetabase', require('./directives/metabase.js'))
  .directive('customVideo', require('./directives/video.js'))
  .directive(
    'processValidator',
    require('./directives/process-validator/process-validator.js')
  )
  .directive(
    'ngRightClick',
    require('./directives/ngRightClick/ngRightClick.js')
  )
  .directive(
    'mdCollapsible',
    require('./directives/collapsiblePanels/MdCollapsible.js')
  )
  .directive(
    'mdCollapsibleItem',
    require('./directives/collapsiblePanels/MdCollapsibleItem.js')
  )
  .directive(
    'mdCollapsibleHeader',
    require('./directives/collapsiblePanels/MdCollapsibleHeader.js')
  )
  .directive(
    'mdCollapsibleBody',
    require('./directives/collapsiblePanels/MdCollapsibleBody.js')
  )
  .config(require('./config/textAngular.js'))
  .config(function (formlyConfigProvider, DateTimeFormatServiceProvider) {
    formlyConfigProvider.disableWarnings = true
    formlyConfigProvider.templateManipulators.preWrapper.push(function (
      template,
      options,
      scope
    ) {
      if (!options.ngModelAttrs) options.ngModelAttrs = {}
      options.ngModelAttrs.dataCy = {
        bound: 'data-testid',
        attribute: 'data-testid'
      }
      options.ngModelAttrs['data-wml-speech-command'] = {
        bound: 'data-wml-speech-command',
        attribute: 'data-wml-speech-command'
      }
      options.ngModelAttrs['data-wml-style'] = {
        bound: 'data-wml-style',
        attribute: 'data-wml-style'
      }
      options.ngModelAttrs.readonly = {
        bound: 'ng-readonly',
        attribute: 'ng-readonly'
      }
      if (!options.templateOptions) options.templateOptions = {}
      options.templateOptions.dataCy = `${options.key}`
      // options.templateOptions['data-wml-speech-command'] = `${options.type}`
      return template
    })
    formlyConfigProvider.setWrapper({
      template: `
        <div layout="column" flex>
          <variables-select ng-if="to.showVariables === true"
                            model="model"
                            key="{{options.key}}"
                            is-disabled="to.disabled"
                            variables="to.variables()"
                            on-change="to.onChange()">
          </variables-select>
          <formly-transclude></formly-transclude>
        </div>
        `,
      types: ['inputTextAngular', 'variableInput', 'variableTextarea']
    })
    formlyConfigProvider.setWrapper({
      template: `
            <formly-transclude></formly-transclude>
            <div my-messages="options"></div>
            <div ng-show="{{to.hint !== undefined}}" class="formly-hint">
              {{to.hint}}
            </div>
            <div ng-if="to.tooltip" class="formly-tooltip">
              <md-icon class="s16" md-font-icon="icon-help-circle-outline">
                <md-tooltip>{{to.tooltip}}</md-tooltip>
              </md-icon>
            </div>
            <div class="formly-help-image-wrapper" ng-if="to.helpImage" layout="column" layout-align="start start">
              <img  ng-if="!to.resource || to.resource.resourceType !== 'video'"
                    class="formly-help-image"
                    alt="help-image"
                    md-lightbox
                    lightbox-src="{{'api/Resources/' + to.helpImage + '/0/0' }}"
                    ng-src="{{'api/Resources/' + to.helpImage + '/medium/0' }}"/>
              <img ng-if="to.resource && to.resource.resourceType === 'video'"
                    preview="true"
                    autoplay="false"
                    class="customVideo"
                    ta-insert-video="{{ to.resource.videoSrc }}"
                    ta-original-video="{{ to.resource.url }}"
                    poster="{{ to.resource.posterURL }}"
                    alt="Video poster"/>
            </div>
            `,
      types: [
        'inlineButton',
        'input',
        'switch',
        'checkbox',
        'select',
        'textarea',
        'radio',
        'dateTimePicker',
        'datePicker',
        'selectMultiCheckBox',
        'gpsInput',
        'chips',
        'upload',
        'inputTextAngular',
        'tinymce',
        'selectIcon',
        'selectUser',
        'select_assembly',
        'cssEditor',
        'jsEditor',
        'selectRealwear',
        'modelSelect',
        'selectWithSearch',
        'radioMaterial',
        'inputWithPrefix',
        'phoneInput',
        'repeatedInput',
        'repeatedList',
        'wizardGroup',
        'labeledSwitch',
        'subForm',
        'header',
        'lookupSelect',
        'customInput',
        'customChips',
        'customTextarea',
        'imageBasedForm'
      ]
    })
    formlyConfigProvider.setWrapper({
      name: 'collapsibleItem',
      template: require('./formly/wrappers/collapsible/collapsibleItem/collapsibleItem.html')
    })
    formlyConfigProvider.setWrapper({
      name: 'collapsibleGroup',
      template: require('./formly/wrappers/collapsible/collapsibleGroup/collapsibleGroup.html')
    })
    formlyConfigProvider.setWrapper({
      name: 'wizardItem',
      template: require('./formly/wrappers/wizard/wizardItem/wizardItem.html')
    })
    formlyConfigProvider.setWrapper({
      name: 'inputContainer',
      template: require('./formly/wrappers/inputContainer/inputContainer.html')
    })
    formlyConfigProvider.setWrapper({
      name: 'messages',
      template: require('./formly/wrappers/messages/messages.html')
    })
    formlyConfigProvider.setWrapper({
      name: 'label',
      template: require('./formly/wrappers/label/label.html')
    })
    formlyConfigProvider.setWrapper({
      name: 'div',
      template: '<div> <formly-transclude></formly-transclude></div>'
    })
    formlyConfigProvider.setWrapper({
      name: 'selectContainer',
      template: require('./formly/wrappers/selectContainer/selectContainer.html')
    })
    formlyConfigProvider.setType({
      name: 'wizardGroup',
      template: require('./formly/wrappers/wizard/wizardGroup/wizardGroup.html'),
      controller: require('./formly/wrappers/wizard/wizardGroup/wizardGroup.js')
    })
    formlyConfigProvider.setType({
      name: 'upload',
      template: require('./formly/types/upload/upload.html'),
      controller: require('./formly/types/upload/upload.js')
    })
    formlyConfigProvider.setType({
      name: 'variableInput',
      template: require('./formly/types/variable-input/variable-input.html'),
      controller: require('./formly/types/variable-input/variable-input.js')
    })
    formlyConfigProvider.setType({
      name: 'variableTextarea',
      template: require('./formly/types/variable-textarea/variable-textarea.html'),
      controller: require('./formly/types/variable-textarea/variable-textarea.js')
    })
    formlyConfigProvider.setType({
      name: 'repeatedList',
      template: require('./formly/types/repeatedList/repeatedList.html'),
      controller: require('./formly/types/repeatedList/repeatedList.js')
    })
    formlyConfigProvider.setType({
      name: 'quantityRow',
      template: require('./formly/types/quantityRow/quantityRow.html'),
      controller: require('./formly/types/quantityRow/quantityRow.js')
    })
    formlyConfigProvider.setType({
      name: 'timePicker',
      wrapper: ['label', 'messages', 'inputContainer'],
      template: require('./formly/types/timePicker/timePicker.html'),
      controller: require('./formly/types/timePicker/timePicker.js')
    })
    formlyConfigProvider.setType({
      name: 'dateTimePicker',
      wrapper: ['label', 'messages', 'inputContainer'],
      template: require('./formly/types/datePicker/datePicker.html'),
      controller: require('./formly/types/datePicker/datePicker.js'),
      defaultOptions: function (options) {
        const dateTimeFormats =
          DateTimeFormatServiceProvider.$get().getDateTimeFormatsForField()
        return {
          formatters: [
            function (value) {
              if (!value) return null
              const date = new Date(value)
              if (date && date instanceof Date) {
                return flatpickr.formatDate(
                  date,
                  `${dateTimeFormats.date} ${dateTimeFormats.time}`
                )
              }
              return flatpickr.formatDate(
                value,
                `${dateTimeFormats.date} ${dateTimeFormats.time}}`
              )
            }
          ],
          parsers: [
            function (value) {
              if (!value) return null
              return flatpickr.parseDate(
                value,
                `${dateTimeFormats.date} ${dateTimeFormats.time}}`
              )
            }
          ]
        }
      }
    })
    formlyConfigProvider.setType({
      name: 'datePicker',
      wrapper: ['label', 'messages', 'inputContainer'],
      template: require('./formly/types/datePicker/datePicker.html'),
      controller: require('./formly/types/datePicker/datePicker.js'),
      defaultOptions: function (options) {
        const dateTimeFormats =
          DateTimeFormatServiceProvider.$get().getDateTimeFormatsForField()
        return {
          formatters: [
            function (value) {
              if (!value) return null
              const date = new Date(value)
              if (date && date instanceof Date) {
                return flatpickr.formatDate(date, dateTimeFormats.date)
              }
              return flatpickr.formatDate(value, dateTimeFormats.date)
            }
          ],
          parsers: [
            function (value) {
              if (!value) return null
              return flatpickr.parseDate(value, dateTimeFormats.date)
            }
          ]
        }
      }
    })
    formlyConfigProvider.setType({
      name: 'select_assembly',
      template: require('./formly/types/selectAssembly/selectAssembly.html'),
      controller: require('./formly/types/selectAssembly/selectAssembly.js')
    })
    formlyConfigProvider.setType({
      name: 'selectMultiCheckBox',
      wrapper: ['label', 'messages', 'inputContainer'],
      template: require('./formly/types/selectMultiCheckBox/selectMultiCheckBox.html')
    })
    formlyConfigProvider.setType({
      name: 'gpsInput',
      wrapper: ['label', 'messages', 'inputContainer'],
      template: require('./formly/types/gpsInput/gpsInput.html'),
      controller: require('./formly/types/gpsInput/gpsInput.js')
    })
    formlyConfigProvider.setType({
      name: 'inlineButton',
      wrapper: ['messages', 'inputContainer'],
      template: require('./formly/types/inlineButton/inlineButton.html'),
      controller: require('./formly/types/inlineButton/inlineButton.js')
    })
    formlyConfigProvider.setType({
      name: 'radioMaterial',
      template: require('./formly/types/radioMaterial/radioMaterial.html'),
      controller: require('./formly/types/radioMaterial/radioMaterial.js')
    })
    formlyConfigProvider.setType({
      name: 'subForm',
      template: require('./formly/types/subForm/subForm.html'),
      controller: require('./formly/types/subForm/subForm.js')
    })
    formlyConfigProvider.setType({
      name: 'imageBasedForm',
      template: require('./formly/types/imageBasedForm/imageBasedForm.html'),
      controller: require('./formly/types/imageBasedForm/imageBasedForm.js')
    })
    formlyConfigProvider.setType({
      name: 'header',
      template: `
            <label style="{{to.style}}">{{to.label}}</label>`,
      /* @ngInject */
      controller: function ($scope, $log) {}
    })
    formlyConfigProvider.setType({
      name: 'inputTextAngular',
      wrapper: ['label', 'messages'],
      template: require('./formly/types/inputTextAngular/inputTextAngular.html'),
      controller: require('./formly/types/inputTextAngular/inputTextAngular.js')
    })
    formlyConfigProvider.setType({
      name: 'tinymce',
      template: require('./formly/types/tinymce/tinymce.html'),
      controller: require('./formly/types/tinymce/tinymce.js')
    })
    formlyConfigProvider.setType({
      name: 'phoneInput',
      template: require('./formly/types/phoneInput/phoneInput.html'),
      controller: require('./formly/types/phoneInput/phoneInput.js')
    })
    formlyConfigProvider.setType({
      name: 'inputWithPrefix',
      wrapper: ['label', 'messages', 'inputContainer'],
      template: require('./formly/types/inputWithPrefix/inputWithPrefix.html')
    })
    formlyConfigProvider.setType({
      name: 'customChips',
      wrapper: ['label', 'messages', 'inputContainer'],
      template: require('./formly/types/customChips/customChips.html'),
      controller: require('./formly/types/customChips/customChips.js'),
      defaultOptions: function (options) {
        return {
          defaultValue: []
        }
      }
    })
    formlyConfigProvider.setType({
      name: 'customInput',
      wrapper: ['label', 'messages', 'inputContainer'],
      template: require('./formly/types/customInput/customInput.html'),
      controller: require('./formly/types/customInput/customInput.js')
    })
    formlyConfigProvider.setType({
      name: 'customTextarea',
      wrapper: ['label', 'messages', 'inputContainer'],
      template: require('./formly/types/customTextarea/customTextarea.html'),
      controller: require('./formly/types/customTextarea/customTextarea.js')
    })
    formlyConfigProvider.setType({
      name: 'cssEditor',
      template: require('./formly/types/cssEditor/cssEditor.html')
    })
    formlyConfigProvider.setType({
      name: 'jsEditor',
      template: require('./formly/types/jsEditor/jsEditor.html')
    })
    formlyConfigProvider.setType({
      name: 'jsonEditor',
      template: require('./formly/types/jsonEditor/jsonEditor.html')
    })
    formlyConfigProvider.setType({
      name: 'formulaEditor',
      template: require('./formly/types/formulaEditor/formulaEditor.html')
    })
    formlyConfigProvider.setType({
      name: 'colorPicker',
      template: require('./formly/types/colorPicker/colorPicker.html')
    })
    formlyConfigProvider.setType({
      name: 'colorPalette',
      template: require('./formly/types/colorPalette/colorPalette.html'),
      controller: require('./formly/types/colorPalette/colorPalette.js')
    })
    formlyConfigProvider.setType({
      name: 'selectIcon',
      wrapper: ['label', 'messages', 'inputContainer'],
      template: require('./formly/types/selectIcon/selectIcon.html'),
      controller: require('./formly/types/selectIcon/selectIcon.js')
    })
    formlyConfigProvider.setType({
      name: 'selectUser',
      wrapper: ['label', 'messages', 'inputContainer'],
      template: require('./formly/types/selectUser/selectUser.html'),
      controller: require('./formly/types/selectUser/selectUser.js')
    })
    formlyConfigProvider.setType({
      name: 'selectTree',
      wrapper: 'div',
      template: require('./formly/types/selectTree/selectTree.html'),
      controller: require('./formly/types/selectTree/selectTree.js')
    })
    formlyConfigProvider.setType({
      name: 'repeatingOptions',
      template: require('./formly/types/repeatingOptions/repeatingOptions.html'),
      controller: require('./formly/types/repeatingOptions/repeatingOptions.js')
    })
    formlyConfigProvider.setType({
      name: 'segmentedButtons',
      template: require('./formly/types/segmentedButtons/segmentedButtons.html'),
      controller: require('./formly/types/segmentedButtons/segmentedButtons.js')
    })
    formlyConfigProvider.setType({
      name: 'repeatingTemplate',
      template: require('./formly/types/repeatingTemplate/repeatingTemplate.html'),
      controller: require('./formly/types/repeatingTemplate/repeatingTemplate.js')
    })
    formlyConfigProvider.setType({
      name: 'selectWithSearch',
      wrapper: ['label', 'messages', 'inputContainer'],
      template: require('./formly/types/selectWithSearch/selectWithSearch.html'),
      controller: require('./formly/types/selectWithSearch/selectWithSearch.js')
    })
    formlyConfigProvider.setType({
      name: 'selectRealwear',
      wrapper: ['label', 'messages', 'inputContainer'],
      template: require('./formly/types/selectRealware/selectRealwear.html'),
      controller: require('./formly/types/selectRealware/selectRealwear.js')
    })
    formlyConfigProvider.setType({
      name: 'selectFieldWithSearch',
      wrapper: ['label', 'messages', 'inputContainer'],
      template: require('./formly/types/selectFieldWithSearch/selectFieldWithSearch.html'),
      controller: require('./formly/types/selectFieldWithSearch/selectFieldWithSearch.js')
    })
    formlyConfigProvider.setType({
      name: 'signsSettings',
      template: require('./formly/types/signsSettings/signsSettings.html'),
      controller: require('./formly/types/signsSettings/signsSettings.js')
    })
    formlyConfigProvider.setType({
      name: 'labeledSwitch',
      template: require('./formly/types/labeledSwitch/labeledSwitch.html'),
      controller: require('./formly/types/labeledSwitch/labeledSwitch.js')
    })
    formlyConfigProvider.setType({
      name: 'timeline',
      template: require('./formly/types/timeline/timeline.html'),
      controller: require('./formly/types/timeline/timeline.js')
    })
    formlyConfigProvider.setType({
      name: 'timelineItem',
      template: require('./formly/types/timeline-item/timeline-item.html'),
      controller: require('./formly/types/timeline-item/timeline-item.js')
    })
    formlyConfigProvider.setType({
      name: 'editableCard',
      template: require('./formly/types/editableCard/editableCard.html'),
      controller: require('./formly/types/editableCard/editableCard.js')
    })
    formlyConfigProvider.setType({
      name: 'lookupSelect',
      wrapper: ['label', 'messages', 'inputContainer'],
      template: require('./formly/types/lookupSelect/lookupSelect.html'),
      controller: require('./formly/types/lookupSelect/lookupSelect.js')
    })
    formlyConfigProvider.setType({
      name: 'modelSelect',
      wrapper: ['label', 'messages', 'inputContainer'],
      template: require('./formly/types/modelSelect/modelSelect.html'),
      controller: require('./formly/types/modelSelect/modelSelect.js')
    })
    formlyConfigProvider.setType({
      name: 'autocomplete',
      template: require('./formly/types/autocomplete/autocomplete.html'),
      controller: require('./formly/types/autocomplete/autocomplete.js')
    })
  })

  .directive('myMessages', function () {
    return {
      template: `<div style="padding-top: 3px;" md-input-messages-animation" ng-messages="errors"
                            ng-if="options.validation.errorExistsAndShouldBeVisible">
                          <div class="error-message" style="margin-top: 0px;line-height: 1.5;font-size: 12px;"
                              ng-repeat="message in options.validation.messages track by $index">
                            <span ng-show="message.field === options.key">{{message.value}}</span>
                          </div>
                        </div>`,
      scope: { options: '=myMessages' }
    }
  })
  .directive('mdLightbox', require('./directives/lightbox.js'))
  .directive('textSizeSlider', require('./directives/textSizeSlider.js'))
  .directive('rawValue', function () {
    return {
      require: 'ngModel',
      link: function (scope, element, attrs, ngModel) {
        ngModel.$parsers = []
        ngModel.$formatters = []
      }
    }
  })
  .directive('whenscrollends', function () {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        const container = element.find('md-content')
        container.css('height', '300px')
        const visibleHeight = 300
        const threshold = 100

        container.on('scroll', function (e) {
          const scrollableHeight = container.prop('scrollHeight')
          const hiddenContentHeight = scrollableHeight - visibleHeight
          if (hiddenContentHeight - container[0].scrollTop <= threshold) {
            scope.$apply(attrs.whenscrollends)
          }
        })
      }
    }
  })
  .run(runBlock)
  .run(function (
    formlyConfig,
    $rootScope,
    formlyValidationMessages,
    $translate
  ) {
    formlyConfig.extras.ngModelAttrsManipulatorPreferBound = true
    formlyValidationMessages.messages.required = (
      viewValue,
      modelValue,
      scope
    ) => {
      return $translate.instant('Field.VALIDATION.REQUIRED')
    }

    formlyValidationMessages.messages.max = (viewValue, modelValue, scope) => {
      return $translate.instant('Field.VALIDATION.MAX', {
        value: scope.to.max
      })
    }
    formlyValidationMessages.messages.min = (viewValue, modelValue, scope) => {
      return $translate.instant('Field.VALIDATION.MIN', {
        value: scope.to.min
      })
    }
    formlyValidationMessages.messages.pattern = viewValue => {
      return `${viewValue} ${$translate.instant('Field.VALIDATION.IS_INVALID')}`
    }
  })

export default MODULE_NAME
