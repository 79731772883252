import { ImageViewer, FullScreenViewer } from 'iv-viewer'
import 'iv-viewer/dist/iv-viewer.css'

/** @ngInject */
function inlineIvViewer () {
  require('./inline-iv-viewer.scss')
  return {
    template: require('./inline-iv-viewer.html'),
    bindings: {
      imageUrl: '<',
      options: '<?',
      showControls: '<?'
    },
    controller:
      /**
       * @ngInject
       */
      function inlineIvViewer ($element, $timeout, $scope) {
        const that = this
        let viewer = null

        this.$onInit = function () {
          that.showControls = that.showControls !== false // default to true
          that.options = {
            zoomValue: 100,
            maxZoom: 500,
            snapView: false,
            refreshOnResize: true,
            ...that.options
          }

          if (that.imageUrl) {
            $timeout(() => {
              initViewer()
            }, 100) // Small delay to ensure DOM is ready
          }
        }

        this.$onChanges = function (changes) {
          if (changes.imageUrl && !changes.imageUrl.isFirstChange()) {
            $timeout(() => {
              if (viewer) {
                viewer.destroy()
                viewer = null
              }
              initViewer()
            }, 100)
          }
        }

        this.$onDestroy = function () {
          if (viewer) {
            viewer.destroy()
            viewer = null
          }
        }

        this.zoomIn = function () {
          if (viewer) {
            const currentZoom = viewer._state.zoomValue
            viewer.zoom(currentZoom + 25)
          }
        }

        this.zoomOut = function () {
          if (viewer) {
            const currentZoom = viewer._state.zoomValue
            viewer.zoom(Math.max(25, currentZoom - 25))
          }
        }

        this.resetZoom = function () {
          if (viewer) {
            viewer.resetZoom()
          }
        }

        this.toggleFullscreen = function () {
          const fullscreenViewer = new FullScreenViewer()
          fullscreenViewer.show(that.imageUrl)
        }

        function initViewer () {
          const container = $element[0].querySelector('.iv-viewer-container')
          if (!container || !that.imageUrl) return

          // Initialize viewer
          viewer = new ImageViewer($element[0])

          // Load the image
          viewer.load(that.imageUrl, that.imageUrl)
        }
      }
  }
}

module.exports = inlineIvViewer
