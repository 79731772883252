/* global $ _ angular L Image */
/** @ngInject */
function PictureViewController (getUrlFromObj, $scope, locals, $mdDialog) {
  $scope.obj = locals.obj
  $scope.getUrlFromObj = getUrlFromObj

  $scope.cancel = function () {
    $mdDialog.cancel()
  }

  $scope.closeDialog = function () {
    $mdDialog.hide('')
  }
}
/** @ngInject */
function CatalogShowController (
  $location,
  Page,
  $window,
  getUrlFromObj,
  CatalogUtils,
  PartAssembly,
  $mdDialog,
  $scope,
  $rootScope,
  $translate,
  $timeout,
  htmlWork,
  $state,
  $stateParams,
  ResolvedCatalog,
  PermissionUtils,
  PartLeafleat,
  serviceAnLoader,
  EventService
) {
  const partStyle = {
    default: {
      fillColor: '#ffff00',
      color: 'black',
      opacity: 0,
      fillOpacity: 0,
      weight: 1
    },
    selected: {
      fillColor: '#ffff00',
      color: 'white',
      opacity: 0.7,
      fillOpacity: 0.5,
      weight: 1
    },
    mouseOver: {
      fillColor: '#ffff00',
      color: '#000',
      opacity: 0.5,
      fillOpacity: 0.1,
      weight: 1
    }
  }

  const assemblyStyle = _.merge(_.cloneDeep(partStyle), {
    default: { fillColor: '#2E62B1' },
    mouseOver: { fillColor: '#2E62B1' },
    selected: { fillColor: '#2E62B1' }
  })

  EventService.saveEvent({
    type: 'view',
    model: 'Catalog',
    modelId: ResolvedCatalog.catalog.id
  })

  /**
   * Get shape style according to 'is_assembly' property and 'type'
   * @param {string} number
   * @param {string} type
   * @returns
   */
  const getStyle = function getStyle (number, type) {
    const subpart = $scope.selectedPart.nodes.find(
      node => node.number === number
    )
    if ($state.current.name === 'app.catalog.print') {
      return subpart && subpart.is_assembly
        ? assemblyStyle.mouseOver
        : partStyle.mouseOver
    }
    return subpart && subpart.is_assembly
      ? assemblyStyle[type]
      : partStyle[type]
  }

  /**
   * Set catalog header name according to selected part
   */
  const setNewHeader = function setNewHeader () {
    if ($scope.selectedPart) {
      $('.header-title').text(
        $scope.selectedPart.name + ' ' + `(${$scope.selectedPart.number})`
      )
    } else {
      $('.header-title').text($translate.instant('CATALOG.CATALOG'))
    }
  }

  /**
   * Show / hide map object according to part image
   */
  const controlMapContainer = function controlMapContainer () {
    if (!$scope.selectedPart.image_object) {
      $('.ui-layout-center-map').css('max-height', '64px')
      $('.ui-layout-south').css('top', '71px')
      $('.ui-layout-south').css('height', '100%')
      $('.ui-layout-resizer-south').css('top', '65px')
      $('.table-wrapper-parts').css('height', 'calc(100% - 115px)')
      $('.ui-layout-resizer-south').css('display', 'none!important')
    } else {
      $('.ui-layout-center-map').css('max-height', '')
      $timeout(() => {
        const top = parseInt($('.ui-layout-center-map').height())
        $('.ui-layout-south').css('top', top + 80 + 'px')
        $('.ui-layout-south').css('height', 'unset')
        $('.table-wrapper-parts').css('height', 'calc(100% - 48px)')
        $('.ui-layout-resizer-south').css('top', top + 75 + 'px')
      }, 500)
    }
  }

  /**
   * Set all layers to default style
   */
  const setToDefault = function setToDefault () {
    const list = document.getElementsByClassName('minimap-marker')
    for (const item of list) {
      $(item).removeClass('selected')
      $(item).removeClass('highlight')
    }
    if ($scope.map) {
      $scope.map.eachLayer(function (layer) {
        if (layer.layerType !== 'marker' && layer.setStyle) {
          layer.setStyle(getStyle('', 'default'))
        }
      })
    }
  }

  /**
   * Change style to selected sub-part shapes
   * @param {string} number
   * @returns none
   */
  const setSubpartSelected = function setSubpartSelected (number) {
    if (!number) return
    if ($scope.map) {
      $scope.map.eachLayer(function (layer) {
        if (layer.number === number) {
          if (layer.layerType === 'marker') {
            $("[number='" + number + "']").addClass('selected')
          } else {
            if (layer.setStyle) {
              layer.setStyle(getStyle(number, 'selected'))
            }
          }
        }
      })
    }
  }

  /**
   * click event on shape
   * @param {object} ev
   * @param {object} elm
   */
  const shapeOnClick = function shapeOnClick (ev, elm) {
    L.DomEvent.stopPropagation(ev)
    const subpart = _.find($scope.selectedPart.nodes, {
      number: ev.target.number
    })
    $scope.selectSubpart(subpart, ev.originalEvent)
    const tbody = $('#subparts-table tbody')
    const tableRow = $(`#table-row-${subpart.number}`)
    tbody.scrollTop(
      tbody.scrollTop() -
        tbody.offset().top +
        tableRow.offset().top -
        tableRow.prev().outerHeight(true)
    )
    $scope.$apply()
  }

  /**
   * double click event on shape
   * @param {object} ev
   * @param {object} elm
   */
  const shapeOnDblClick = function shapeOnDblClick (ev, elm) {
    L.DomEvent.stopPropagation(ev)
    const subpart = _.find($scope.selectedPart.nodes, {
      number: ev.target.number
    })
    $scope.loadPartByRootId(subpart.rootId)
  }

  /**
   * shape mouse over event
   * @param {object} ev
   */
  const shapeOnMouseOver = function shapeOnMouseOver (ev) {
    const number = ev.target.number
    if (!number) return
    if ($scope.map) {
      $scope.map.eachLayer(function (layer) {
        if (layer.number === number) {
          if (
            $scope.selectedSubpart &&
            number === $scope.selectedSubpart.number
          ) {
            return
          }
          if (layer.layerType === 'marker') {
            $("[number='" + number + "']").addClass('highlight')
          } else {
            if (layer.setStyle) {
              layer.setStyle(getStyle(number, 'mouseOver'))
            }
          }
        }
      })
    }
  }

  /**
   * shape mouse out event
   * @param {object} ev
   */
  const shapeOnMouseOut = function shapeOnMouseOut (ev) {
    // const number = ev.target.number
    setToDefault()
    setSubpartSelected(
      $scope.selectedSubpart && $scope.selectedSubpart.number
        ? $scope.selectedSubpart.number
        : null
    )
  }

  /**
   * Get marker label
   * @param {object} subPart
   * @returns string
   */
  const getNameOfMarker = function getNameOfMarker (subPart) {
    const node = $scope.selectedPart.nodes.find(
      node => node.number === subPart.number
    )
    return node && node.label ? htmlWork.htmlEncode(node.label) : '-'
  }

  /**
   * Change collapse state
   * @param {object} elm
   * @param {object} ev
   */
  $scope.changeCollapsedState = function changeCollapsedState (elm, ev) {
    ev.stopPropagation()
    ev.preventDefault()
    elm.collapsed = !elm.collapsed
  }

  /**
   * Change collapse state to node and his children.
   * @param {object} node
   * @param {boolean} bool
   * @returns
   */
  const setCollapseStatus = function setCollapseStatus (node, bool) {
    node.collapsed = bool
    if (node.nodes && node.nodes.length !== 0) {
      node.nodes = node.nodes.map(n => setCollapseStatus(n, bool))
    }
    return node
  }

  /**
   * Collapse all nodes in catalog menu
   */
  $scope.collapseAll = function collapseAll () {
    $scope.assemblyArray = $scope.assemblyArray.map(node =>
      setCollapseStatus(node, true)
    )
  }

  /**
   * Expand all nodes in catalog menu
   */
  $scope.expandAll = function expandAll () {
    $scope.assemblyArray = $scope.assemblyArray.map(node =>
      setCollapseStatus(node, false)
    )
  }

  /**
   * Clear search
   */
  $scope.clearSearch = function clearSearch () {
    $scope.search.query = ''
  }

  /**
   * Check if root id include in current path
   * @param {string} roodId
   * @returns
   */
  $scope.rootIdInPath = function rootIdInPath (roodId) {
    if ($scope.selectedPart.rootId === roodId) return false
    return $scope.selectedPart.rootId.includes(roodId)
  }

  /**
   * Check if menu item should be visible
   * @param {string} item
   * @returns
   */
  $scope.visible = function visible (item) {
    if (!$scope.search.query || $scope.search.query.length === 0) {
      return true
    } else if (
      item.search.toLowerCase().indexOf($scope.search.query.toLowerCase()) ===
      -1
    ) {
      return false
    } else {
      item.collapsed = false
      return true
    }
  }

  /**
   * Remove all layers
   */
  const clearAll = function clearAll () {
    if ($scope.map) {
      $scope.map.eachLayer(function (layer) {
        $scope.map.removeLayer(layer)
      })
    }
  }

  $scope.checkThisNode = function checkThisNode (node) {
    if (
      $scope.rootIdInPath(node.rootId) ||
      $scope.selectedPart.rootId === node.rootId
    ) {
      return false
    } else if (node.collapsed) {
      return true
    } else return false
  }

  $scope.nodeClick = function nodeClick (node, event) {
    event.stopPropagation()
    if (node._id && node._id === $scope.selectedPart._id) {
      return
    }
    if (!node) {
      $scope.loadPartByRootId($scope.assembly.number)
    }
    $scope.loadPartByRootId(node.rootId)
  }

  $scope.openViewPictureDialog = function openViewPictureDialog (obj) {
    $mdDialog
      .show({
        controller: PictureViewController,
        template: require('./picture_view.html'),
        parent: angular.element(document.body),
        targetEvent: '',
        locals: {
          obj
        },
        clickOutsideToClose: true
      })
      .then(
        function () {},
        function () {}
      )
  }

  $scope.createDocx = function createDocx (node, event) {
    event.preventDefault()
    event.stopPropagation()
    event.cancelBubble = true
    $window.open(
      encodeURI(
        `/api/Catalogs/createPartDocx?number=${encodeURIComponent(node.number)}`
      )
    )
  }

  $scope.createXLSX = function createXLSX (node, event) {
    event.preventDefault()
    event.stopPropagation()
    event.cancelBubble = true
    $window.open(
      encodeURI(
        `/api/Catalogs/createPartXLSX?number=${encodeURIComponent(node.number)}`
      )
    )
  }

  $scope.fixMarkerSize = function fixMarkerSize () {
    const widthProp = $('.leaflet-image-layer').css('width')
    if (widthProp) {
      const currentImageWidthInLeafleat = $('.leaflet-image-layer')
        .css('width')
        .replace('px', '')
        .trim()
      const markerZoom =
        currentImageWidthInLeafleat / $scope.lastBuild.img.width

      $('.ui-layout-center .minimap-marker').each((i, el) => {
        const parent = $(el).parent()
        const tr = $(parent).css('transform')
        $(parent).css({ transform: `${tr} scale(${markerZoom})` })
        $(parent).css({ border: '2px solid black' })
      })
    }
  }

  $scope.setMarkerOptions = function setMarkerOptions () {
    if (!$scope.selectedPart.options) $scope.selectedPart.options = {}
    if (!$scope.catalog.options) $scope.catalog.options = {}

    const newLabelHeight =
      $scope.selectedPart.options.hotspot_height ||
      $scope.catalog.options.hotspot_height ||
      'auto'
    const newLabelWidth =
      $scope.selectedPart.options.hotspot_wight ||
      $scope.catalog.options.hotspot_wight ||
      'auto'
    const newLabelFontSize =
      $scope.selectedPart.options.hotspot_font_size ||
      $scope.catalog.options.hotspot_font_size ||
      14
    const newLabelOpacity =
      $scope.selectedPart.options.hotspot_opacity ||
      $scope.catalog.options.hotspot_opacity ||
      1

    $('.ui-layout-center .minimap-marker').css('height', newLabelHeight)
    $('.ui-layout-center .minimap-marker').css('width', newLabelWidth)
    $('.ui-layout-center .minimap-marker').css('fontSize', newLabelFontSize)
    $('.ui-layout-center .minimap-marker')
      .parent()
      .css('opacity', newLabelOpacity)
    $scope.fixMarkerSize()
  }

  $scope.mapWhenReady = function mapWhenReady () {
    console.log('when ready in controller')
    $scope.map.on('overlayadd', e => {
      $scope.setMarkerOptions()
    })
    $scope.map.on('overlayremove', e => {
      $scope.setMarkerOptions()
    })
    $scope.map.on('click', ev => {
      if ($scope.selectedSubpart) {
        $scope.selectSubpart($scope.selectedSubpart, ev)
      }
    })
    $scope.map.on('zoomend', function (e) {
      $scope.setMarkerOptions()
    })
  }

  $scope.createDisplayLayers = function createDisplayLayers () {
    $scope.overlayMaps = {}
    const subpartLayerNumbers = _.uniq(
      _.map($scope.selectedPart.nodes, 'number')
    )
    subpartLayerNumbers.forEach((subpartLayerNumber, i) => {
      const layer = new L.featureGroup() //eslint-disable-line
      const subPart = _.find($scope.selectedPart.nodes, {
        number: subpartLayerNumber
      })
      $scope.overlayMaps[
        $translate.instant('CATALOG.LAYER') + (i + 1) + ': ' + subPart.name
      ] = layer
      const shapesInLayer = $scope.selectedPart.shapes.filter(
        shape => subpartLayerNumber === shape.number
      )
      shapesInLayer.forEach(sh => {
        if (sh.type === 'circle') {
          PartLeafleat.createCircle(
            sh.number,
            sh.latlngs,
            sh.radius,
            layer,
            getStyle(sh.number, 'default'),
            [
              { name: 'click', fn: shapeOnClick },
              { name: 'mouseover', fn: shapeOnMouseOver },
              { name: 'mouseout', fn: shapeOnMouseOut }
            ]
          )
        }
        if (sh.type === 'marker') {
          const id = (
            sh.number +
            '_' +
            sh.latlngs[0] +
            '_' +
            sh.latlngs[1]
          ).toString()
          let markerClass = 'catalog-marker '
          markerClass += subPart.is_assembly ? 'blue' : 'yellow'

          const markerData = {
            id,
            icon: markerClass,
            name: getNameOfMarker(subPart)
          }

          PartLeafleat.createMarker(
            sh.number,
            sh.latlngs,
            markerData,
            layer,
            getStyle(sh.number, 'default'),
            [
              { name: 'click', fn: shapeOnClick },
              { name: 'dblclick', fn: shapeOnDblClick },
              { name: 'mouseover', fn: shapeOnMouseOver },
              { name: 'mouseout', fn: shapeOnMouseOut }
            ]
          )
        }
        if (sh.type === 'rectangle') {
          PartLeafleat.createReactangle(
            sh.number,
            sh.latlngs,
            layer,
            getStyle(sh.number, 'default'),
            [
              { name: 'click', fn: shapeOnClick },
              { name: 'mouseover', fn: shapeOnMouseOver },
              { name: 'mouseout', fn: shapeOnMouseOut }
            ]
          )
        }
        if (sh.type === 'polygon') {
          PartLeafleat.createPolygon(
            sh.number,
            sh.latlngs,
            layer,
            getStyle(sh.number, 'default'),
            [
              { name: 'click', fn: shapeOnClick },
              { name: 'mouseover', fn: shapeOnMouseOver },
              { name: 'mouseout', fn: shapeOnMouseOut }
            ]
          )
        }
      })
    })
    _.values($scope.overlayMaps).map(l => $scope.map.addLayer(l))
    return { baseMaps: $scope.baseMaps, overlayMaps: $scope.overlayMaps }
  }

  $scope.selectSubpart = function selectSubpart (s) {
    if (!s) return
    if ($scope.selectedSubpart && $scope.selectedSubpart.number === s.number) {
      $scope.selectedSubpart = null
      $location.search('selectedSubPartNumber', null)
      setToDefault()
      return
    }

    if (
      ($scope.selectedSubpart && $scope.selectedSubpart.number !== s.number) ||
      !$scope.selectedSubpart
    ) {
      $scope.selectedSubpart = s
      $location.search('selectedSubPartNumber', $scope.selectedSubpart.number)
      setToDefault()
      setSubpartSelected($scope.selectedSubpart.number)
    }
  }

  $scope.selectPart = function selectPart (s) {
    return new Promise(resolve => {
      const oldImage = !!$scope.selectedPart.image_url
      $scope.selectedPart = s

      $scope.selectedPart.nodes.map(node => {
        if (node.label === '') {
          node.label = undefined
        } else if (!isNaN(node.label)) {
          node.label = parseInt(node.label)
        }
        return node
      })
      $scope.selectedPart.nodes = _.sortBy($scope.selectedPart.nodes, ['label'])
      // console.log($scope.selectedPart)
      setNewHeader()
      $location.search('selectedPartNumber', $scope.selectedPart.rootId)
      $('#map').css('opacity', 0)
      serviceAnLoader.start()
      $scope.selectedSubpart = null
      $scope.selectedPart.image_url = getUrlFromObj(
        $scope.selectedPart.image_object
      )
      controlMapContainer()
      const newImage = !!$scope.selectedPart.image_url
      if (oldImage !== newImage) {
        resizeFunction()
      }
      if ($scope.selectedPart.image_url) {
        const img = new Image()
        img.src = $scope.selectedPart.image_url
        img.onerror = () => {
          clearAll()
          serviceAnLoader.stop()
          $('#map').css('opacity', 1)
          resolve()
        }
        img.onload = () => {
          $scope.lastBuild = { img }
          const opt = {
            img,
            minimap: true,
            mapContainer: 'map',
            miniMapOptions: {
              zoomLevelFixed: 0,
              class: 'minimapclass',
              type: 'minimap',
              position: 'bottomright'
            },
            miniMapContainer: 'mini-map-container'
          }

          PartLeafleat.buildMap(opt)
            .then(({ map, overlay }) => {
              $scope.map = map
              $scope.overlay = overlay
            })
            .then(() => $scope.createDisplayLayers())
            .then(() =>
              PartLeafleat.createLayerControl(
                $scope.baseMaps,
                $scope.overlayMaps
              )
            )
            .then(control => ($scope.layerControl = control))
            .then(() => $scope.map.whenReady(() => $scope.mapWhenReady()))
            .then(() => $scope.setMarkerOptions())
            .then(() => {
              $timeout(() => {
                serviceAnLoader.stop()
                $('#map').css('opacity', 1)
                resolve()
              }, 500)
            })
        }
      } else {
        clearAll()
        serviceAnLoader.stop()
        $('#map').css('opacity', 1)
        resolve()
      }
    })
  }

  const proccedLoad = async function proccedLoad (rootId, subpartId, obj) {
    if (obj.is_assembly && subpartId) {
      // if need load part and subpart
      await $scope.selectPart(obj)
      const tmp = obj.nodes.find(node => node.number === subpartId)
      if (tmp) {
        $scope.selectSubpart(tmp)
      }
    } else if (obj.is_assembly && !subpartId) {
      // if only part
      $scope.selectPart(obj)
    }
    $('#subpartsTableBody').css('height', 'calc(100% - 101px)')
  }

  $scope.getDeepObj = function getDeepObj (obj, pathArr) {
    if (pathArr.length === 0) return obj
    const curId = pathArr.shift()
    const el = _.find(obj.nodes, { number: curId })
    el.collapsed = false

    if (pathArr.length === 0) {
      return el
    } else {
      return $scope.getDeepObj(el, pathArr)
    }
  }

  $scope.checkOrUpdateNode = function checkOrUpdateNode (rootId, rawPart) {
    let pathArr = rootId.split(CatalogUtils.ROOT_ID_SEPERATOR)
    pathArr = pathArr.slice(1, pathArr.length)
    let obj = $scope.getDeepObj($scope.assemblyArray[0], pathArr)
    const tmpRootId = obj.rootId
    const tmpHasAssembly = obj.hasAssembly
    // const objNodes = obj.nodes
    // const rawPartNodes = rawPart.nodes

    obj = _.mergeWith(
      obj,
      rawPart,
      (objValue, srcValue, key, object, source, stack) => {
        if (key === 'nodes') {
          return rawPart.nodes.map(node => {
            let n = _.find(object.nodes, { number: node.number })
            if (!n) {
              n = {}
            }
            return _.mergeWith(
              node,
              n,
              (objValue1, srcValue1, key1, object1, source1, stack1) => {
                if (key1 === 'nodes') {
                  return source1.nodes.map(node1 => {
                    let n1 = _.find(object1.nodes, {
                      number: node1.number
                    })
                    if (!n1) {
                      n1 = {}
                    }
                    return _.merge(n1, node1)
                  })
                } else {
                  return srcValue1 || objValue1
                }
              }
            )
          })
        } else {
          return srcValue || objValue
        }
      }
    )
    obj.rootId = tmpRootId
    obj.hasAssembly = tmpHasAssembly
    return obj
  }

  $scope.loadPartByRootId = function loadPartByRootId (rootId, subpartId) {
    if (rootId) {
      const lastNumber = _.takeRight(
        rootId.split(CatalogUtils.ROOT_ID_SEPERATOR)
      )[0]
      PartAssembly.getWithSubParts({
        number: lastNumber,
        deep: 1,
        withResources: true,
        flatten: false
      }).$promise.then(rawPart => {
        // need put rawPart to $scope.assemblyArray related to path rootId
        // with out override (hotspots and quantity)
        proccedLoad(
          rootId,
          subpartId,
          $scope.checkOrUpdateNode(rootId, rawPart.result)
        )
      })
    }
  }

  const updateFixedHeaderTableBodyHeight =
    function updateFixedHeaderTableBodyHeight () {
      try {
        const fixedHeaderTableWrapper = $('.fixed-header-table').parent()
        const fixedHeaderTableHeader = $('.fixed-header-table thead')
        const fixedHeaderTableBody = $('.fixed-header-table tbody')
        fixedHeaderTableBody.css(
          'height',
          fixedHeaderTableWrapper.height() - fixedHeaderTableHeader.height()
        )
        $('#subpartsTableBody').css('height', 'calc(100% - 101px)')
      } catch (err) {
        console.log(err)
      }
    }

  const resizeMapContainer = _.debounce(() => {
    const w = $('#map-container').width()
    const h = $('#map-container').height()
    PartLeafleat.resizeLeafLeat(w, h)
    serviceAnLoader.calc('map')
    controlMapContainer()
    updateFixedHeaderTableBodyHeight()
  }, 500)

  // angular.element($window).bind('popstate', backClick)
  angular.element($window).bind('resize', resizeMapContainer)

  $scope.$on('$destroy', function () {
    angular.element($window).unbind('resize', resizeMapContainer)
    // angular.element($window).unbind('popstate', backClick)
  })

  $rootScope.$on('msNavigation::toggle', () => {
    $(window).trigger('resize')
  })

  const panesOptions = function panesOptions () {
    const panes = $window.localStorage.getItem('catalog-panes')
    if (panes) return JSON.parse(panes)
    return [
      { size: '20%', collapsible: true },
      { min: '30%' },
      { min: '15%', size: '15%', collapsible: true }
    ]
  }

  const innerPanesOptions = function innerPanesOptions () {
    const hasTable =
      $scope.selectedPart.nodes && $scope.selectedPart.nodes.length > 0
    if (hasTable) {
      return [
        { size: '80%' },
        {
          min: '10%',
          size: '20%',
          max: '50%',
          collapsible: true,
          collapsed: false
        }
      ]
    }
    return [
      { size: '90%' },
      { size: '10%', collapsible: true, collapsed: true }
    ]
  }

  const initSplitter = function initSplitter () {
    const panes = panesOptions()
    if ($rootScope.dir === 'rtl') panes.reverse() // reverse the array
    $('#splitter').kendoSplitter({
      panes,
      layoutChange: function (e) {
        const {
          sender: {
            options: { panes }
          }
        } = e
        if (panes) {
          $window.localStorage.removeItem('catalog-panes')
          $window.localStorage.setItem('catalog-panes', JSON.stringify(panes))
        }
        resizeMapContainer()
      }
    })
  }

  const initInnerSplitter = function initInnerSplitter () {
    const innerPanes = innerPanesOptions()
    $('#middle').kendoSplitter({
      panes: innerPanes,
      orientation: 'vertical',
      layoutChange: function (e) {
        resizeMapContainer()
      }
    })
  }

  const resizeFunction = function resizeFunction () {
    setTimeout(() => {
      const splitter = $('#splitter').data('kendoSplitter')
      const innerSplitter = $('#middle').data('kendoSplitter')
      if (splitter) {
        splitter.destroy()
        if ($('.k-splitbar')) $('.k-splitbar').remove()
      }
      if (innerSplitter && innerSplitter.element) {
        innerSplitter.destroy()
        if ($('.k-splitbar')) $('.k-splitbar').remove()
      }
      if ($rootScope.dir === 'rtl') {
        $('#left-side').before($('#middle')) // swap panes
        $('#middle').before($('#right-side'))
      }
      initSplitter()
      initInnerSplitter()
    }, 400)
  }

  $(document).ready(() => {
    if ($rootScope.dir === 'rtl') {
      $('#left-side').before($('#middle')) // swap panes
      $('#middle').before($('#right-side'))
    }
    resizeFunction()
    $(window).on(
      'resize.doResize',
      _.debounce(function () {
        resizeFunction()
      }, 500)
    )
  })

  const initScreen = async function initScreen () {
    Page.setTitleText(ResolvedCatalog.catalog.name)
    $scope.PermissionUtils = PermissionUtils
    const query = $location.search()
    $scope.search = { query: '' }
    $scope.selectedSubpart = null
    $scope.catalog = ResolvedCatalog.catalog
    $scope.assembly = CatalogUtils.makeIdTree(ResolvedCatalog.assembly)

    $scope.assemblyArray = CatalogUtils.formatNodes([
      CatalogUtils.makeIdTree(ResolvedCatalog.assembly)
    ])
    $scope.assemblyArray[0].collapsed = false
    $scope.selectedPart = $scope.assembly
    const staticFieldsToDisplay = [
      {
        key: 'name',
        translation: $translate.instant('CATALOG.SHOW_PART_NAME')
      },
      {
        key: 'currentRevision',
        translation: $translate.instant('CATALOG.SHOW_PART_REVISION')
      },
      {
        key: 'number',
        translation: $translate.instant('CATALOG.SHOW_PART_NUMBER')
      }
    ]
    $scope.customfieldsToDisplay = []
    _.mapKeys(
      $rootScope.appSettings.modelsFields.PartAssembly.properties,
      (value, key) => {
        if (value.custom) {
          $scope.customfieldsToDisplay.push({
            key: value.key,
            translation: $translate.instant('PartAssembly.' + value.key)
          })
        }
      }
    )
    $scope.fieldsToDisplay = staticFieldsToDisplay.concat(
      $scope.customfieldsToDisplay
    )
    if ($state.params.selectedPartNumber) {
      if ($state.params.selectedSubPartNumber) {
        $location.search('selectedPartNumber', $state.params.selectedPartNumber)
        $location.search(
          'selectedSubPartNumber',
          $state.params.selectedSubPartNumber
        )
      } else {
        $location.search('selectedPartNumber', $state.params.selectedPartNumber)
      }
      $state.params.selectedPartNumber = ''
      $state.params.selectedSubPartNumber = ''
    }
    if (query.selectedPartNumber) {
      $scope.loadPartByRootId(
        $location.search().selectedPartNumber,
        $location.search().selectedSubPartNumber
      )
    } else {
      $location.search('selectedPartNumber', $scope.assembly.rootId)
      $scope.loadPartByRootId($location.search().selectedPartNumber)
    }
    updateFixedHeaderTableBodyHeight()
  }

  initScreen()

  $scope.headerOptions = {
    icon: 'icon-arrange-send-backward',
    template: require('app/templates/headers/simple.html'),
    title: $translate.instant('CATALOG.CATALOG'),
    fabButton: {}
  }
}

module.exports = {
  CatalogShowController,
  PictureViewController
}
