/* global $ _ angular L moment Image */
import UUID from 'uuid'
import { isNullOrUndefined } from 'app/helper'
/** @ngInject */
function partEdit () {
  require('./part-edit.scss')
  return {
    template: require('./part-edit.html'),
    bindings: {
      part: '<',
      functionHelper: '<',
      mode: '@'
    },
    controller:
      /**
       * @ngInject
       */
      function PartEditController (
        $scope,
        $rootScope,
        $translate,
        $compile,
        $mdPanel,
        $mdToast,
        $mdDialog,
        $interval,
        $timeout,
        $window,
        $state,
        FormlyHelper,
        PartLeafleat,
        Unit,
        PermissionUtils,
        PartAssembly,
        MultiTranslateService,
        ErrorHandlerService,
        Workflow,
        UserModel,
        Resource,
        Revision
      ) {
        const partDefaults = {
          name: '',
          number: '',
          revisions: [],
          managedByRevision: false,
          isSerial: false,
          isCombinated: false,
          timeTracking: false,
          hasExpirationDate: false,
          freeStock: false,
          bulkProduction: 'BY_UNIT',
          serialNumberRequiredAtStart: false,
          unitId: null,
          autoCreate: 'NOT_ACTIVE',
          options: {
            hotspot_height: 0,
            hotspot_wight: 0,
            hotspot_font_size: 0,
            hotspot_opacity: ''
          },
          convertible: [],
          nodes: [],
          alternatives: [],
          shapes: [],
          methods: [],
          combinationParts: [],
          _documents: [],
          generateSerials: false,
          numerator: 0,
          paddingZeros: 0,
          pattern: ''
        }

        const availablePatterVariables = [
          '{{YYYY}}',
          '{{YY}}',
          '{{MM}}',
          '{{DD}}',
          '{{AUTO_NUMBER}}'
        ]
        const autoCreateOptions = [
          {
            value: 'ACTIVE',
            name: $translate.instant('PartAssembly.AUTO_CREATE.ACTIVE')
          },
          {
            value: 'NOT_ACTIVE',
            name: $translate.instant('PartAssembly.AUTO_CREATE.NOT_ACTIVE')
          },
          {
            value: 'REQUIRE_APPROVAL',
            name: $translate.instant(
              'PartAssembly.AUTO_CREATE.REQUIRE_APPROVAL'
            )
          }
        ]
        const bulkProductionOptions = [
          {
            value: 'BY_UNIT',
            name: $translate.instant(
              'PartAssembly.BULK_PRODUCTION_OPTIONS.BY_UNIT_OF_MEASUREMENT'
            )
          },
          {
            value: 'YES',
            name: $translate.instant('PartAssembly.BULK_PRODUCTION_OPTIONS.YES')
          },
          {
            value: 'NO',
            name: $translate.instant('PartAssembly.BULK_PRODUCTION_OPTIONS.NO')
          }
        ]
        const documentTypes = {
          LINK: 'LINK',
          FILE: 'FILE'
        }
        const documentOptions = [
          {
            name: $translate.instant('PartAssembly.DOCUMENTS.LINK'),
            value: documentTypes.LINK
          },
          {
            name: $translate.instant('PartAssembly.DOCUMENTS.FILE'),
            value: documentTypes.FILE
          }
        ]

        const getMarkerZoom = function getMarkerZoom () {
          const widthProp = $('.leaflet-image-layer').css('width')
          if (widthProp) {
            const currentImageWidthInMap = $('.leaflet-image-layer')
              .css('width')
              .replace('px', '')
              .trim()
            return currentImageWidthInMap / $scope.lastBuild?.img?.width
          }
          return 1
        }

        const fixMarkerSize = function fixMarkerSize () {
          const widthProp = $('.leaflet-image-layer').css('width')
          if (widthProp) {
            const markerZoom = getMarkerZoom()
            $('#map-panel .minimap-marker').css({ zoom: markerZoom })
          }
        }

        const resizeMapContainer = _.debounce(() => {
          const w = $('#map-panel').width() - 4
          const h = $('#map-panel').height() - 4
          PartLeafleat.resizeLeafLeat(w, h)
        }, 500)

        const initWatchers = function initWatchers () {
          $scope.fixMarkerSizeInterval = $interval(() => fixMarkerSize(), 500)
          $scope.isAssemblyWatcher = $scope.$watch(
            'part.nodes.length',
            nodesLength => {
              $timeout(() => {
                if (nodesLength > 0 && $scope.part.image_url) {
                  resizeMapContainer()
                }
              }, 300)
            }
          )
          angular.element($window).bind('resize', resizeMapContainer)
        }

        const variableInitialization = function variableInitialization () {
          $scope.editLayer = null
          $scope.lastBuild = {}
          $scope.markers = {}
          $scope.usedInModels = {}
          $scope.layerControl = null
          $scope.editMode = false
          $scope.selectedSubpart = null
          $scope._mdPanel = $mdPanel
          $scope.miniMapOptions = {
            zoomLevelFixed: 0,
            class: 'minimapclass',
            type: 'minimap',
            position: 'bottomright'
          }
          $scope.drawnItems = null
          $scope.drawControl = null
          $scope.customControl = null

          $scope.partStyle = {
            default: {
              fillColor: '#ffff00',
              color: 'black',
              opacity: 0.7,
              fillOpacity: 0.5,
              weight: 1
            },
            selected: {
              fillColor: '#ffff00',
              color: 'white',
              opacity: 1,
              fillOpacity: 1,
              weight: 1
            }
          }

          $scope.assemblyStyle = _.merge(_.cloneDeep($scope.partStyle), {
            default: { fillColor: '#2E62B1' },
            selected: { fillColor: '#2E62B1' }
          })
        }

        const renameLabel = _.debounce(subpart => {
          $("[number='" + subpart.number + "']").text(subpart.label || '-')
        }, 500)

        const panesOptions = function panesOptions () {
          const panes = $window.localStorage.getItem('part-panes')
          if (panes) return JSON.parse(panes)
          return [
            { size: '70%', min: '40%' },
            { size: '30%', min: '10%', collapsible: true }
          ]
        }

        const innerPanesOptions = function innerPanesOptions () {
          const hasTable = $scope.part.nodes && $scope.part.nodes.length > 0
          if (hasTable) {
            return [
              { size: '80%' },
              {
                min: '10%',
                size: '20%',
                max: '45%',
                collapsible: true,
                collapsed: false
              }
            ]
          }
          return [
            { size: '90%' },
            { size: '10%', collapsible: true, collapsed: true }
          ]
        }

        const initSplitter = function initSplitter () {
          const initPanes = panesOptions()
          if ($rootScope.dir === 'rtl') {
            const index = initPanes.findIndex(pane => pane.collapsible)
            if (index === 1) {
              initPanes.reverse()
            }
          }
          $('#splitter').kendoSplitter({
            panes: initPanes,
            layoutChange: function (e) {
              const {
                sender: {
                  options: { panes }
                }
              } = e
              if (panes) {
                if ($rootScope.dir === 'rtl') {
                  const index = panes.findIndex(pane => pane.collapsible)
                  if (index !== 1) {
                    panes.reverse()
                  }
                }
                $window.localStorage.removeItem('part-panes')
                $window.localStorage.setItem(
                  'part-panes',
                  JSON.stringify(panes)
                )
              }
              resizeMapContainer()
            }
          })
        }

        const initInnerSplitter = function initInnerSplitter () {
          const innerPanes = innerPanesOptions()
          $('#table-panel').kendoSplitter({
            panes: innerPanes,
            orientation: 'vertical',
            layoutChange: function (e) {}
          })
        }

        const combinatedChanged = function combinatedChanged () {
          if (!$scope.part.isCombinated) {
            $scope.part.combinationParts = []
          }
        }

        const openCombinationPartsSelection =
          function openCombinationPartsSelection () {
            $mdDialog
              .show({
                controller: require('./combination-parts-table.dialog.controller.js'),
                template: require('./combination-parts-table.dialog.html'),
                parent: angular.element(document.body),
                resolve: {
                  PartsIdsToBeDisabled: (PartAssembly, $stateParams) => {
                    if ($stateParams.number) {
                      return PartAssembly.getParentsList({
                        number: $stateParams.number
                      }).$promise
                    }
                    return Promise.resolve({ result: [] })
                  }
                },
                locals: {
                  selectedParts: $scope.part.combinationParts.map(
                    part => part.number
                  ),
                  number: $scope.part.number
                },
                multiple: true,
                fullscreen: true,
                escapeToClose: true,
                clickOutsideToClose: true
              })
              .then(
                function (combinationParts) {
                  $scope.part.combinationParts = combinationParts || []
                },
                function () {}
              )
          }

        const checkValidation = async function checkValidation () {
          const errorToast = $mdToast.nextplus({
            position: $rootScope.toastLocation,
            parent: 'body.body',
            theme: 'error-toast',
            hideDelay: 3000
          })
          if (!$scope.part) return false
          if (!$scope.part.name || $scope.part.name === '') {
            $mdToast.show(errorToast)
            $mdToast.updateTextContent(
              $translate.instant('PartAssembly.ERRORS.NAME_IS_REQUIRED')
            )
            return false
          }
          if (!$scope.part.number || $scope.part.number === '') {
            $mdToast.show(errorToast)
            $mdToast.updateTextContent(
              $translate.instant('PartAssembly.ERRORS.NUMBER_IS_REQUIRED')
            )
            return false
          }
          if (!$scope.part.unitId) {
            $mdToast.show(errorToast)
            $mdToast.updateTextContent(
              $translate.instant('PartAssembly.ERRORS.UNIT_IS_REQUIRED')
            )
            return false
          }
          if (
            $scope.part.isCombinated &&
            (!$scope.part.combinationParts ||
              $scope.part.combinationParts.length === 0)
          ) {
            $mdToast.show(errorToast)
            $mdToast.updateTextContent(
              $translate.instant(
                'PartAssembly.ERRORS.COMBINATION_PARTS_ARE_REQUIRED'
              )
            )
            return false
          }
          if (
            $scope.part.timeTracking &&
            (!$scope.part.methods ||
              $scope.part.methods.length === 0 ||
              $scope.part.methods.every(method => !_.isNil(method.deletedAt)))
          ) {
            $mdToast.show(errorToast)
            $mdToast.updateTextContent(
              $translate.instant('PartAssembly.ERRORS.METHODS_ARE_REQUIRED')
            )
            return false
          }
          if (
            Array.isArray($scope.part.nodes) &&
            $scope.part.nodes.length > 0
          ) {
            if ($scope.part.nodes.find(n => !n.quantity)) {
              $mdToast.show(errorToast)
              $mdToast.updateTextContent(
                $translate.instant(
                  'PartAssembly.ERRORS.SUB_PARTS_QUANTITY_ARE_REQUIRED'
                )
              )
              return false
            }
            if (
              $scope.part.nodes.find(n => n.isSerial && n.quantity % 1 !== 0)
            ) {
              $mdToast.show(errorToast)
              $mdToast.updateTextContent(
                $translate.instant(
                  'PartAssembly.ERRORS.SERIAL_SUB_PARTS_QUANTITY_ARE_FRACTION'
                )
              )
              return false
            }
          }
          if ($scope.part.managedByRevision) {
            if (!$scope.part.revisions || $scope.part.revisions.length === 0) {
              $mdToast.show(errorToast)
              $mdToast.updateTextContent(
                $translate.instant('PartAssembly.ERRORS.REVISIONS_ARE_REQUIRED')
              )
              return false
            } else {
              // check that there is only one revision with valid = true
              const validRevisions = $scope.part.revisions.filter(
                revision => revision.valid
              )
              if (validRevisions.length !== 1) {
                $mdToast.show(errorToast)
                $mdToast.updateTextContent(
                  $translate.instant(
                    'PartAssembly.ERRORS.ONLY_ONE_VALID_REVISION'
                  )
                )
                return false
              } else if (validRevisions[0].active) {
                $mdToast.show(errorToast)
                $mdToast.updateTextContent(
                  $translate.instant(
                    'PartAssembly.ERRORS.VALID_REVISION_MUST_BE_ACTIVE'
                  )
                )
                return false
              }

              // check that there are no duplicated revisions
              const duplicates = findDuplicatesRevisions($scope.part.revisions)
              if (duplicates.length > 0) {
                $mdToast.show(errorToast)
                $mdToast.updateTextContent(
                  $translate.instant(
                    'PartAssembly.ERRORS.DUPLICATED_REVISIONS',
                    { revisions: duplicates.join(', ') }
                  )
                )
                return false
              }

              // check that there is at least one active revision (!active because its the opposite)
              const activeRevisions = $scope.part.revisions.filter(
                revision => !revision.active
              )
              if (activeRevisions.length === 0) {
                $mdToast.show(errorToast)
                $mdToast.updateTextContent(
                  $translate.instant(
                    'PartAssembly.ERRORS.AT_LEAST_ONE_ACTIVE_REVISION'
                  )
                )
                return false
              }
            }
          }
          if ($scope.part.isSerial) {
            const unit = await Unit.findOne({
              filter: { where: { id: $scope.part.unitId } }
            }).$promise
            if (unit && !unit.each) {
              $mdToast.show(errorToast)
              $mdToast.updateTextContent(
                $translate.instant(
                  'PartAssembly.ERRORS.SERIAL_PART_UNIT_MUST_BE_EACH'
                )
              )
              return false
            }
          }
          return true
        }

        function findDuplicatesRevisions (arr) {
          const seen = new Set()
          const duplicates = []

          for (const obj of arr) {
            const value = obj.revision
            if (seen.has(value)) {
              duplicates.push(value)
            } else {
              seen.add(value)
            }
          }
          return duplicates
        }

        const preparePart = function preparePart () {
          let part = angular.copy($scope.part)
          const optionsDefault = {
            options: {
              hotspot_height: 0,
              hotspot_wight: 0,
              hotspot_font_size: 0,
              hotspot_opacity: ''
            }
          }
          part = _.defaultsDeep(part, optionsDefault)
          part.numerator = part.numerator - 1
          const validKeys = [
            'number',
            'name',
            'image',
            'picture',
            'recordId',
            'options',
            'isSerial',
            'isCombinated',
            'timeTracking',
            'hasExpirationDate',
            'is_avaliable',
            'freeStock',
            'serialNumberRequiredAtStart',
            'unitId',
            'autoCreate',
            'description',
            '_documents',
            'isPhantom',
            'bulkProduction',
            'revisions',
            'managedByRevision',
            'generateSerials',
            'numerator',
            'paddingZeros',
            'pattern'
          ]
          FormlyHelper.getCustomFields(PartAssembly).forEach(customField =>
            validKeys.push(customField.key)
          )

          const validPart = _.pick(part, validKeys)

          validPart.shapes = part.shapes.map(shape => ({
            number: shape.number,
            type: shape.type,
            latlngs: shape.latlngs,
            radius: shape.radius || null
          }))

          validPart.nodes = part.nodes.map(node => ({
            recordId: node.recordId,
            quantity: node.quantity,
            optional: node.optional || false,
            number: node.number,
            mrpDefault: isNullOrUndefined(node.mrpDefault)
              ? true
              : node.mrpDefault,
            label: node.label,
            infoOnly: node.infoOnly || false
          }))

          validPart.alternatives = part.alternatives.map(
            alternative => alternative.number
          )

          validPart.convertible = part.convertible.map(
            convertible => convertible.number
          )

          validPart.combinationParts = part.combinationParts.map(
            combinationPart => combinationPart.number
          )

          validPart.methods = part.methods.map(method => {
            if (_.isNil(method.id)) {
              method.id = UUID()
            }
            return method
          })
          if (validPart.revisions?.length > 0) {
            validPart.revisions = part.revisions.map(revision => {
              revision.active = !revision.active
              return revision
            })
            if (validPart.managedByRevision) {
              const currentRevision = validPart.revisions.find(
                revision => revision.valid
              )
              if (currentRevision) {
                validPart.currentRevision = currentRevision.revision
              }
            } else {
              validPart.currentRevision = null
            }
          } else {
            validPart.revisions = []
            validPart.currentRevision = null
          }
          return { ...validPart }
        }

        $scope.updateLabels = function updateLabels (subpart) {
          renameLabel(subpart)
        }

        $scope.createCustomControl = function createCustomControl () {
          $scope.customControlFn = L.Control.extend({
            options: {
              position: 'topleft'
            },
            onAdd: function (map) {
              const container = L.DomUtil.create(
                'div',
                'leaflet-bar leaflet-control leaflet-control-custom'
              )
              container.style.backgroundColor = 'white'
              container.innerHTML = `
      <div ng-click="disableEditMode()" style="display: inline-block; border-right: 1px solid gainsboro" class="icon-save-btn"><i style="font-size: 18px;" class="icon icon-content-save"></i></div>
      <div ng-click="disableEditMode('cancel')" style="display: inline-block;" class="icon-save-btn"><i style="font-size: 18px;" class="icon icon-cancel"></i></div>
      `
              container.style.backgroundSize = '30px 30px'
              container.style.width = '68px'
              container.style.height = '30px'
              container.style.position = 'absolute'
              container.style.left = '50px'
              container.style.top = '0px'
              const h = $compile(container)($scope)
              return h[0]
            }
          })
          $scope.customControl = new $scope.customControlFn() //eslint-disable-line
          $scope.map.addControl($scope.customControl)
        }

        $scope.removeCustomControl = function removeCustomControl () {
          $scope.map.removeControl($scope.customControl)
        }

        $scope.createDrawControl = function createDrawControl (subPart) {
          let markerClass = 'catalog-marker '
          markerClass += subPart.nodes.length > 0 ? 'blue' : 'yellow'
          const inlineStyle = $scope.getInlineStyle()

          const options = {
            position: 'topright',
            draw: {
              polyline: false,
              polygon: {
                shapeOptions: $scope.getStyle(subPart.number, 'default')
              },
              circle: {
                shapeOptions: $scope.getStyle(subPart.number, 'default')
              },
              rectangle: {
                shapeOptions: $scope.getStyle(subPart.number, 'default')
              },
              circlemarker: false,
              marker: {
                icon: L.divIcon({
                  iconSize: ['fit-content', 'fit-content'],
                  type: 'dom',
                  html: `<div number="${
                    subPart.number
                  }" style='${inlineStyle}' class="minimap-marker ${markerClass}">${$scope.getNameOfMarker(
                    subPart
                  )}</div>`
                })
              }
            },
            edit: {
              featureGroup: $scope.drawnItems
            }
          }
          $scope.drawControl = new L.Control.Draw(options)
          $scope.map.addControl($scope.drawControl)
        }

        $scope.removeDrawControl = function removeDrawControl () {
          $scope.map.removeControl($scope.drawControl)
        }

        $scope.creteDrawLayer = function creteDrawLayer (subPart) {
          if ($scope.editLayer) {
            $scope.map.removeLayer($scope.editLayer)
          }
          $scope.editLayer = new L.featureGroup() //eslint-disable-line

          $scope.part.shapes.forEach(sh => {
            if (sh.number === subPart.number) {
              if (sh.type === 'circle') {
                PartLeafleat.createCircle(
                  sh.number,
                  sh.latlngs,
                  sh.radius,
                  $scope.editLayer,
                  $scope.getStyle(sh.number, 'default'),
                  []
                )
              }
              if (sh.type === 'marker') {
                const id = (
                  sh.number +
                  '_' +
                  sh.latlngs[0] +
                  '_' +
                  sh.latlngs[1]
                ).toString()
                let markerClass = 'catalog-marker '
                markerClass += subPart.nodes.length > 0 ? 'blue' : 'yellow'
                const markerData = {
                  id,
                  icon: markerClass,
                  name: $scope.getNameOfMarker(subPart)
                }
                const inlineStyle = $scope.getInlineStyle()
                PartLeafleat.createMarker(
                  sh.number,
                  sh.latlngs,
                  markerData,
                  $scope.editLayer,
                  $scope.getStyle(sh.number, 'default'),
                  [
                    { name: 'click', fn: $scope.shapeOnClick },
                    { name: 'contextmenu', fn: $scope.shapeOnContextMenu }
                  ],
                  inlineStyle
                )
              }
              if (sh.type === 'rectangle') {
                PartLeafleat.createReactangle(
                  sh.number,
                  sh.latlngs,
                  $scope.editLayer,
                  $scope.getStyle(sh.number, 'default'),
                  [
                    { name: 'click', fn: $scope.shapeOnClick },
                    { name: 'contextmenu', fn: $scope.shapeOnContextMenu }
                  ]
                )
              }
              if (sh.type === 'polygon') {
                PartLeafleat.createPolygon(
                  sh.number,
                  sh.latlngs,
                  $scope.editLayer,
                  $scope.getStyle(sh.number, 'default'),
                  [
                    { name: 'click', fn: $scope.shapeOnClick },
                    { name: 'contextmenu', fn: $scope.shapeOnContextMenu }
                  ]
                )
              }
            }
          })
          $scope.drawnItems = $scope.editLayer
          $scope.map.addLayer($scope.drawnItems)
        }

        $scope.createDisplayLayers = function createDisplayLayers () {
          $scope.overlayMaps = {}
          const subpartLayerIds = _.uniq(_.map($scope.part.nodes, 'number'))
          subpartLayerIds.forEach((subpartLayerId, i) => {
            const layer = new L.featureGroup() //eslint-disable-line
            layer.number = subpartLayerId
            const subPart = _.find($scope.part.nodes, {
              number: subpartLayerId
            })
            $scope.overlayMaps[
              $translate.instant('PartAssembly.LAYER') +
                (i + 1) +
                ': ' +
                subPart.name
            ] = layer
            const shapesInLayer = $scope.part.shapes.filter(
              shape => subpartLayerId === shape.number
            )
            shapesInLayer.forEach(sh => {
              if (sh.type === 'circle') {
                PartLeafleat.createCircle(
                  sh.number,
                  sh.latlngs,
                  sh.radius,
                  layer,
                  $scope.getStyle(sh.number, 'default'),
                  [
                    { name: 'click', fn: $scope.shapeOnClick },
                    { name: 'contextmenu', fn: $scope.shapeOnContextMenu }
                  ]
                )
              }
              if (sh.type === 'marker') {
                const id = (
                  sh.number +
                  '_' +
                  sh.latlngs[0] +
                  '_' +
                  sh.latlngs[1]
                ).toString()
                let markerClass = 'catalog-marker '
                markerClass += subPart.nodes.length > 0 ? 'blue' : 'yellow'

                const markerData = {
                  id,
                  icon: markerClass,
                  name: $scope.getNameOfMarker(subPart)
                }
                const inlineStyle = $scope.getInlineStyle()
                PartLeafleat.createMarker(
                  sh.number,
                  sh.latlngs,
                  markerData,
                  layer,
                  $scope.getStyle(sh.number, 'default'),
                  [
                    { name: 'click', fn: $scope.shapeOnClick },
                    { name: 'contextmenu', fn: $scope.shapeOnContextMenu }
                  ],
                  inlineStyle
                )
              }
              if (sh.type === 'rectangle') {
                PartLeafleat.createReactangle(
                  sh.number,
                  sh.latlngs,
                  layer,
                  $scope.getStyle(sh.number, 'default'),
                  [
                    { name: 'click', fn: $scope.shapeOnClick },
                    { name: 'contextmenu', fn: $scope.shapeOnContextMenu }
                  ]
                )
              }
              if (sh.type === 'polygon') {
                PartLeafleat.createPolygon(
                  sh.number,
                  sh.latlngs,
                  layer,
                  $scope.getStyle(sh.number, 'default'),
                  [
                    { name: 'click', fn: $scope.shapeOnClick },
                    { name: 'contextmenu', fn: $scope.shapeOnContextMenu }
                  ]
                )
              }
            })
          })
          _.values($scope.overlayMaps).map(l => $scope.map.addLayer(l))
        }

        $scope.removeAllLayers = function removeAllLayers () {
          $scope.map.eachLayer(l => {
            if (l._leaflet_id !== $scope.overlay._leaflet_id) {
              $scope.map.removeLayer(l)
            }
          })
        }

        $scope.addOtherLayers = function addOtherLayers (newOverlay) {
          const layers = _.values(newOverlay)
          layers.map(layer => $scope.map.addLayer(layer))
        }

        $scope.enableEditMode = async function enableEditMode (
          number,
          type,
          event
        ) {
          if ($scope.editMode) {
            return
          }
          if (
            $scope.selectedSubpart &&
            $scope.selectedSubpart.number === number &&
            type !== 'fromTable'
          ) {
            return
          }
          $scope.unselectSubPart()
          const subPart = _.find($scope.part.nodes, { number })
          $scope.removeAllLayers()
          $scope.selectSubPart(subPart, event)
          $scope.editMode = true

          $scope.map.removeControl($scope.layerControl)

          const newOverlay = {}
          _.mapKeys($scope.overlayMaps, (value, key) => {
            if (value.number !== number) {
              newOverlay[key] = value
            }
          })

          $scope.layerControl = await PartLeafleat.createLayerControl(
            $scope.baseMaps,
            newOverlay
          )

          $scope.creteDrawLayer(subPart)
          $scope.addOtherLayers(newOverlay)
          $scope.createDrawControl(subPart)
          $scope.createCustomControl()
          if (type === 'fromMap') {
            document.querySelector('.leaflet-draw-edit-edit').click()
          }
        }

        $scope.disableEditMode = async function disableEditMode (isCancel) {
          $scope.removeAllLayers()
          if (isCancel) {
            _.remove($scope.part.shapes, { tmp: true })
          } else {
            _.remove($scope.part.shapes, {
              number: $scope.selectedSubpart.number
            })
            $scope.drawnItems.eachLayer(l => {
              if (l.layerType === 'marker') {
                const { lat, lng } = PartLeafleat.convertToImageCoords({
                  blat: l._latlng.lat,
                  blng: l._latlng.lng
                })
                $scope.part.shapes.push({
                  type: l.layerType,
                  number: l.number,
                  latlngs: [lat, lng]
                })
              }
              if (l.layerType === 'rectangle') {
                const latlngs = l._latlngs.map(lll => {
                  return lll.map(ll => {
                    const { lat, lng } = PartLeafleat.convertToImageCoords({
                      blat: ll.lat,
                      blng: ll.lng
                    })
                    return { lat, lng }
                  })
                })
                $scope.part.shapes.push({
                  type: l.layerType,
                  number: l.number,
                  latlngs
                })
              }
              if (l.layerType === 'polygon') {
                const latlngs = l._latlngs.map(lll => {
                  return lll.map(ll => {
                    const { lat, lng } = PartLeafleat.convertToImageCoords({
                      blat: ll.lat,
                      blng: ll.lng
                    })
                    return { lat, lng }
                  })
                })
                $scope.part.shapes.push({
                  type: l.layerType,
                  number: l.number,
                  latlngs
                })
              }
              if (l.layerType === 'circle') {
                const { lat, lng } = PartLeafleat.convertToImageCoords({
                  blat: l._latlng.lat,
                  blng: l._latlng.lng
                })
                $scope.part.shapes.push({
                  type: l.layerType,
                  number: l.number,
                  latlngs: [lat, lng],
                  radius: l._mRadius
                })
              }
            })
          }

          $scope.part.shapes = $scope.part.shapes.map(shape =>
            _.omit(shape, 'tmp')
          )
          $scope.editMode = false
          $scope.unselectSubPart()
          $scope.removeAllLayers()
          $scope.createDisplayLayers()

          $scope.map.removeControl($scope.layerControl)

          $scope.layerControl = await PartLeafleat.createLayerControl(
            $scope.baseMaps,
            $scope.overlayMaps
          )

          $scope.removeDrawControl()
          $scope.removeCustomControl()
          // PartLeafleat.updateMiniMap()
          $scope.setMarkerOptions()
        }

        $scope.shapeOnClick = function shapeOnClick (ev) {
          const subpart = $scope.part.nodes.find(
            node => node.number === ev?.target?.number
          )
          $scope.selectSubPart(subpart, ev.originalEvent)
        }

        $scope.shapeOnContextMenu = function shapeOnContextMenu (ev) {
          const position = $scope._mdPanel
            .newPanelPosition()
            .absolute()
            .top(ev.originalEvent.clientY + 'px')
            .left(ev.originalEvent.clientX + 'px')
          const config = {
            attachTo: angular.element(document.body),
            controller: /** @ngInject */ (
              mdPanelRef,
              subpart,
              $scope,
              number,
              selectedSubpart,
              partSelected,
              unselectSubPart,
              selectSubPart,
              enableEditMode,
              ev
            ) => {
              $scope._mdPanelRef = mdPanelRef
              $scope.partSelected = partSelected
              $scope.number = number
              $scope.selectedSubpart = selectedSubpart

              $scope.unselect = () => {
                unselectSubPart(ev.originalEvent)
                $scope.partSelected = false
              }
              $scope.select = () => {
                selectSubPart(subpart, ev.originalEvent)
                $scope.partSelected = true
              }
              $scope.edit = () => {
                $scope.partSelected = true
                unselectSubPart(ev.originalEvent)
                enableEditMode(number, 'fromMap', ev.originalEvent)
              }
              $scope.close = () => {
                if ($scope._mdPanelRef) {
                  $scope._mdPanelRef.close()
                }
              }
            },
            template: require('./content-menu.html'),
            panelClass: 'demo-menu-example',
            position,
            locals: {
              number: ev.target.number,
              ev,
              subpart: _.find($scope.part.nodes, {
                number: ev.target.number
              }),
              selectSubPart: $scope.selectSubPart,
              unselectSubPart: $scope.unselectSubPart,
              enableEditMode: $scope.enableEditMode,
              selectedSubpart: $scope.selectedSubpart,
              partSelected: $scope.selectedSubpart !== null
            },
            multiple: true,
            openFrom: ev,
            clickOutsideToClose: true,
            escapeToClose: true,
            focusOnOpen: false,
            zIndex: 100
          }
          if (!$scope.editMode) {
            $scope._mdPanel.open(config)
          }
        }

        $scope.unselectSubPart = function unselectSubPart () {
          $scope.selectedSubpart = null
          if ($scope.editMode === false && $scope.map) {
            $scope.map.eachLayer(l => {
              if (l._leaflet_id !== $scope.overlay._leaflet_id && l.layerType) {
                if (l.layerType === 'marker') {
                  if (l.number) {
                    $(`[number="${l.number}"]`).removeClass('selected')
                  }
                } else {
                  l.setStyle($scope.getStyle(l.number, 'default'))
                }
              }
            })
          }
        }

        $scope.mapWhenReady = function mapWhenReady () {
          console.log('when ready in controller')
          $scope.map.on('overlayadd', e => {
            $scope.setMarkerOptions()
          })
          $scope.map.on('overlayremove', e => {
            $scope.setMarkerOptions()
          })
          $scope.map.on('zoomend', function (e) {
            $scope.setMarkerOptions()
          })
          $scope.map.on(L.Draw.Event.CREATED, function (e) {
            const type = e.layerType
            const layer = e.layer
            const number = $scope.selectedSubpart.number

            layer.number = number
            layer.tmp = true
            layer.layerType = e.layerType
            if (type === 'marker') {
              const { lat, lng } = PartLeafleat.convertToImageCoords({
                blat: layer._latlng.lat,
                blng: layer._latlng.lng
              })
              $scope.part.shapes.push({
                type,
                tmp: true,
                number,
                latlngs: [lat, lng]
              })
            }
            if (type === 'rectangle') {
              const latlngs = layer._latlngs.map(lll => {
                return lll.map(ll => {
                  const { lat, lng } = PartLeafleat.convertToImageCoords({
                    blat: ll.lat,
                    blng: ll.lng
                  })
                  return { lat, lng }
                })
              })
              $scope.part.shapes.push({
                type,
                tmp: true,
                number,
                latlngs
              })
            }
            if (type === 'polygon') {
              const latlngs = layer._latlngs.map(lll => {
                return lll.map(ll => {
                  const { lat, lng } = PartLeafleat.convertToImageCoords({
                    blat: ll.lat,
                    blng: ll.lng
                  })
                  return { lat, lng }
                })
              })
              $scope.part.shapes.push({
                type,
                tmp: true,
                number,
                latlngs
              })
            }
            if (type === 'circle') {
              const { lat, lng } = PartLeafleat.convertToImageCoords({
                blat: layer._latlng.lat,
                blng: layer.lng
              })
              $scope.part.shapes.push({
                type,
                tmp: true,
                number,
                latlngs: [lat, lng],
                radius: layer._mRadius
              })
            }

            $scope.drawnItems.addLayer(layer)
          })
          $scope.map.on(L.Draw.Event.EDITSTART, function (e) {
            console.log('EDITSTART')
            $scope.removeCustomControl()
          })
          $scope.map.on(L.Draw.Event.EDITSTOP, function (e) {
            console.log('EDITSTOP')
            $scope.createCustomControl()
          })
          $scope.map.on(L.Draw.Event.DELETESTART, function (e) {
            $scope.removeCustomControl()
          })
          $scope.map.on(L.Draw.Event.DELETESTOP, function (e) {
            $scope.createCustomControl()
          })
        }

        $scope.getStyle = function getStyle (number, type) {
          const subpart = _.find($scope.part.nodes, { number })
          return subpart && subpart.nodes.length > 0
            ? $scope.assemblyStyle[type]
            : $scope.partStyle[type]
        }

        $scope.getNameOfMarker = function getNameOfMarker (subPart) {
          const node = _.find($scope.part.nodes, { number: subPart.number })
          return node.label || '-'
        }

        $scope.setMarkerOptions = function setMarkerOptions () {
          if (!$scope.part.options) $scope.part.options = {}

          const newLabelHeight = $scope.part.options.hotspot_height
            ? $scope.part.options.hotspot_height + 'px'
            : 'auto'
          const newLabelWidth = $scope.part.options.hotspot_wight
            ? $scope.part.options.hotspot_wight + 'px'
            : 'auto'
          const newLabelFontSize =
            $scope.part.options.hotspot_font_size || '14px'
          const newLabelOpacity =
            $scope.part.options.hotspot_opacity === 0
              ? 1
              : $scope.part.options.hotspot_opacity || 1

          $('#map-panel .minimap-marker').css('height', newLabelHeight)
          $('#map-panel .minimap-marker').css('width', newLabelWidth)
          $('#map-panel .minimap-marker').css('fontSize', newLabelFontSize)
          $('#map-panel .minimap-marker')
            .parent()
            .css('opacity', newLabelOpacity)
          fixMarkerSize()
        }

        $scope.getInlineStyle = function getInlineStyle () {
          let result = ''
          if (!$scope.part.options) $scope.part.options = {}

          const newLabelHeight = $scope.part.options.hotspot_height
            ? $scope.part.options.hotspot_height + 'px'
            : 'auto'
          const newLabelWidth = $scope.part.options.hotspot_wight
            ? $scope.part.options.hotspot_wight + 'px'
            : 'auto'
          const newLabelFontSize =
            $scope.part.options.hotspot_font_size || '14px'
          const newLabelZoom = getMarkerZoom()

          result = result + `height:${newLabelHeight};`
          result = result + `width:${newLabelWidth};`
          result = result + `font-size:${newLabelFontSize}px;`
          result = result + `zoom:${newLabelZoom};`

          return result
        }

        $scope.selectMarkers = function selectMarkers (number) {
          const list = document.getElementsByClassName('minimap-marker')
          for (const item of list) {
            $(item).removeClass('selected')
          }

          $("[number='" + number + "']").addClass('selected')

          if (!$scope.$$phase) {
            $scope.$digest()
          }
        }

        const buildMap = async function buildMap () {
          const img = new Image()
          img.src = $scope.part.image_url
          img.onload = () => {
            console.log('image loaded on file select start build map')
            $scope.lastBuild = { img }
            const opt = {
              img,
              minimap: true,
              mapContainer: 'map',
              miniMapOptions: $scope.miniMapOptions,
              miniMapContainer: 'mini-map-container'
            }

            PartLeafleat.buildMap(opt)
              .then(({ map, overlay }) => {
                $scope.map = map
                $scope.overlay = overlay
              })
              .then(() => $scope.createDisplayLayers())
              .then(() =>
                PartLeafleat.createLayerControl(
                  $scope.baseMaps,
                  $scope.overlayMaps
                )
              )
              .then(control => ($scope.layerControl = control))
              .then(() => $scope.map.whenReady(() => $scope.mapWhenReady()))
              .then(() => $scope.setMarkerOptions())
              .finally(() => {
                $scope.mapLoading = false
                if (!$scope.$$phase) {
                  $scope.$apply()
                }
              })
          }
          img.onerror = () => {
            console.error('Failed to load image')
            $scope.mapLoading = false
            if (!$scope.$$phase) {
              $scope.$apply()
            }
          }
        }

        $scope.fileSelected = function fileSelected (data, type) {
          if (data && data[0]) {
            $scope.part[type] = data[0].id
            $scope.part[type + '_url'] =
              '/api/containers/' +
              data[0].container +
              '/download/' +
              data[0].name
            $scope.part[type + '_object'] = data[0]
          }
        }

        $scope.showSelectPartsDialog = function showSelectPartsDialog (TYPE) {
          const TYPES = {
            ALTERNATIVE: 'alternative',
            SUBPART: 'subpart',
            CONVERTIBLE: 'convertible'
          }
          const TYPE_PROPERTY = {
            ALTERNATIVE: 'alternatives',
            SUBPART: 'nodes',
            CONVERTIBLE: 'convertible'
          }
          $mdDialog
            .show({
              controller: require('./part-add.dialog.controller.js'),
              controllerAs: 'vm',
              template: require('./part-add.dialog.html'),
              parent: angular.element(document.body),
              resolve: {
                PartsIdsToBeDisabled: (PartAssembly, $stateParams) => {
                  if (TYPES[TYPE] === TYPES.SUBPART && $stateParams.number) {
                    return PartAssembly.getParentsList({
                      number: $stateParams.number
                    }).$promise
                  }
                  return Promise.resolve({ result: [] })
                }
              },
              locals: {
                alternative: TYPES[TYPE] === TYPES.ALTERNATIVE,
                type: TYPES[TYPE],
                number: $scope.part.number,
                selectedSubPartNumbers: $scope.part[TYPE_PROPERTY[TYPE]].map(
                  o => o.number
                )
              },
              multiple: true,
              fullscreen: true,
              escapeToClose: true,
              clickOutsideToClose: true
            })
            .then(
              async function (partNumbers) {
                if (Array.isArray(partNumbers) && partNumbers.length > 0) {
                  const newPartNumbers = partNumbers.filter(
                    partNumber =>
                      !$scope.part[TYPE_PROPERTY[TYPE]].find(
                        o => o.number === partNumber
                      )
                  )
                  const removePartNumbers = $scope.part[TYPE_PROPERTY[TYPE]]
                    .map(n => n.number)
                    .filter(partNumber => !partNumbers.includes(partNumber))

                  if (newPartNumbers.length > 0) {
                    $rootScope.loadingProgress = true
                    try {
                      const parts = await PartAssembly.find({
                        filter: {
                          where: {
                            number: { inq: newPartNumbers }
                          },
                          fields: [
                            'id',
                            'name',
                            'number',
                            'recordId',
                            'picture',
                            'isSerial'
                          ]
                        }
                      }).$promise
                      let newParts = parts
                      if (TYPES[TYPE] === TYPES.SUBPART) {
                        newParts = parts.map(part => ({
                          ...part,
                          mrpDefault: true
                        }))
                      }
                      $scope.part[TYPE_PROPERTY[TYPE]].push(...newParts)
                    } catch (err) {
                      console.error(err)
                      $rootScope.showErrorToast('NP-8200')
                    } finally {
                      $rootScope.loadingProgress = false
                    }
                  }
                  if (removePartNumbers.length > 0) {
                    for (let i = 0; i < removePartNumbers.length; i++) {
                      const partNumber = removePartNumbers[i]
                      const index = $scope.part[TYPE_PROPERTY[TYPE]].findIndex(
                        o => o.number === partNumber
                      )
                      if (index > -1) {
                        $scope.part[TYPE_PROPERTY[TYPE]].splice(index, 1)
                      }
                    }
                  }
                }
              },
              function () {}
            )
        }

        $scope.getWhereUsed = async function getWhereUsed () {
          if (Object.keys($scope.usedInModels).length === 0) {
            $scope.whereUsedLoading = true
            try {
              const res = await PartAssembly.getPartWhereUsed({
                number: $state.params.number
              }).$promise
              if ($rootScope.appSettings.contentTranslations) {
                res.workflows.map(workflow =>
                  MultiTranslateService.getForView(
                    Workflow,
                    $rootScope.currentLang,
                    workflow
                  )
                )
              }
              $scope.usedInModels.PARENTS = res.parentParts
              $scope.usedInModels.WORKORDER_TEMPLATES = res.workorderTemplates
              $scope.usedInModels.WORKFLOWS = []

              res.workflowTypes.forEach(workflowType => {
                const { id, name } = workflowType
                const workflows = res.workflows.filter(w =>
                  w.workflowTypeIds.includes(id)
                )
                if (workflows.length > 0) {
                  $scope.usedInModels.WORKFLOWS.push({ name, workflows })
                }
              })
              $scope.whereUsedLoading = false
            } catch (err) {
              console.log(err)
              $scope.whereUsedLoading = false
              $rootScope.showErrorToast('NP-8400')
            }
          }
        }

        $scope.goToPart = function goToPart (number, event) {
          event.stopPropagation()
          event.preventDefault()
          event.cancelBubble = true
          const url = $state.href('app.parts.edit', {
            number
          })
          window.open(url, '_blank')
        }

        $scope.selectSubPart = function selectSubPart (subpart, event) {
          if ($scope.editMode) {
            return
          }

          if (
            $scope.selectedSubpart &&
            $scope.selectedSubpart.number === subpart.number
          ) {
            $scope.unselectSubPart()
            return
          }
          $scope.unselectSubPart()
          event.stopPropagation()
          event.preventDefault()
          event.cancelBubble = true
          $scope.selectedSubpart = subpart

          $scope.map.eachLayer(l => {
            if (l.number === subpart.number) {
              if (l.layerType === 'marker') {
                $(`[number="${l.number}"]`).addClass('selected')
              } else {
                l.setStyle($scope.getStyle(subpart.number, 'selected'))
              }
            }
          })
        }

        $scope.deleteSubPart = async function deleteSubPart (p) {
          const index = $scope.part.nodes.findIndex(n => n.number === p.number)
          $scope.part.nodes.splice(index, 1)
          $scope.$applyAsync()
          $scope.part.shapes = $scope.part.shapes.filter(
            sh => sh.number !== p.number
          )
          if ($scope.map) {
            $scope.unselectSubPart()
            $scope.map.removeControl($scope.layerControl)
            $scope.removeAllLayers()
            $scope.createDisplayLayers()
            $scope.layerControl = await PartLeafleat.createLayerControl(
              $scope.baseMaps,
              $scope.overlayMaps
            )
          }
        }

        $scope.deleteConvertible = function deleteConvertible (part) {
          const index = $scope.part.convertible.findIndex(
            p => p.number === part.number
          )
          if (index > -1) {
            $scope.part.convertible.splice(index, 1)
          }
        }

        $scope.deleteAlternative = function deleteAlternative (part) {
          const index = $scope.part.alternatives.findIndex(
            p => p.number === part.number
          )
          if (index > -1) {
            $scope.part.alternatives.splice(index, 1)
          }
        }

        $scope.drawTabClick = function drawTabClick () {
          $scope.mapLoading = true
          $timeout(() => {
            $(window).trigger('resize')
            resizeMapContainer()
            if ($scope.part.image_url) {
              buildMap()
            } else {
              $scope.mapLoading = false
            }
          }, 200)
        }

        const createNewPart = async function createNewPart (partObject) {
          return PartAssembly.addPartAssembly({
            partAssembly: partObject
          }).$promise
        }

        const updatePart = async function updatePart (partObject) {
          const saveObject = {
            partAssembly: _.omit(partObject, ['id'])
          }
          return PartAssembly.savePartAssembly(saveObject).$promise
        }

        const upsertPart = async function upsertPart (partObject) {
          try {
            $rootScope.loadingProgress = true
            let part = {}
            if ($scope.isNewPart) {
              part = await createNewPart(partObject)
            } else {
              part = await updatePart(partObject)
            }
            if (typeof $scope.functionHelper.afterSaveHandler === 'function') {
              $scope.functionHelper.afterSaveHandler(part)
            }
            $rootScope.loadingProgress = false
          } catch (err) {
            $rootScope.loadingProgress = false
            ErrorHandlerService.formlyErrorHandler(
              err,
              $scope.partFields,
              $scope.functionHelper.partAssemblyForm
            )
          }
        }

        const savePart = async function savePart () {
          const validated = await checkValidation()
          if (!validated) return
          const part = preparePart()
          await upsertPart(part)
        }

        const initModel = function initModel (part) {
          $scope.part = _.defaultsDeep(part || {}, partDefaults)
          $scope.part.numerator = $scope.part.numerator + 1
          $scope.isNewPart = !part
          if (!$scope.isNewPart) {
            let img = $scope.part.image_object
            $scope.part.image_url = !img
              ? ''
              : '/api/containers/' + img.container + '/download/' + img.name
            img = $scope.part.picture_object
            $scope.part.picture_url = !img
              ? ''
              : '/api/containers/' + img.container + '/download/' + img.name
            $scope.part.revisions = $scope.part.revisions.map(revision => {
              revision.active = !revision.active
              return revision
            })
          }
        }

        this.$onDestroy = function () {
          if ($scope.fixMarkerSizeInterval) {
            $interval.cancel($scope.fixMarkerSizeInterval)
          }
          if ($scope.isAssemblyWatcher) {
            $scope.isAssemblyWatcher()
          }
          angular.element($window).unbind('resize', resizeMapContainer)
        }

        const initChangeLog = async function initChangeLog () {
          $scope.changeLog = []
          if (
            !$scope.originalPart.changeLog ||
            $scope.originalPart.changeLog.length === 0
          )
            return
          const ignoreTypes = [
            'methods',
            'combinationParts',
            'convertible',
            'alternatives',
            'nodes',
            'picture',
            'image',
            '_documents',
            'revisions'
          ]
          const userIds = new Set()
          const unitIds = new Set()
          $scope.originalPart.changeLog.forEach(log => {
            userIds.add(log.userId)
            if (log.type === 'unitId') {
              if (log.oldValue) unitIds.add(log.oldValue)
              if (log.newValue) unitIds.add(log.newValue)
            }
          })
          const users = await UserModel.find({
            filter: {
              where: {
                id: { inq: [...userIds] }
              },
              fields: {
                id: true,
                displayName: true
              }
            }
          }).$promise
          const usersDisplayNameById = {}
          users.forEach(
            user => (usersDisplayNameById[user.id] = user.displayName)
          )
          $scope.users = usersDisplayNameById
          const units = await Unit.find({
            filter: {
              where: {
                id: { inq: [...unitIds] }
              },
              fields: {
                id: true,
                name: true
              }
            }
          }).$promise
          $scope.units =
            units?.length > 0
              ? units.reduce((acc, unit) => {
                  acc[unit.id] = unit.name
                  return acc
                }, {})
              : {}
          $scope.autoCreateOptions = autoCreateOptions.reduce((acc, option) => {
            acc[option.value] = option.name
            return acc
          }, {})
          $scope.bulkProductionOptions = bulkProductionOptions.reduce(
            (acc, option) => {
              acc[option.value] = option.name
              return acc
            },
            {}
          )
          const changeLog = _.cloneDeep($scope.originalPart.changeLog)
            .filter(change => !ignoreTypes.includes(change.type))
            .sort((b, a) => moment(a.date) - moment(b.date))
          $scope.changeLog = changeLog
        }

        const getDocumentsField = function getDocumentsField (type) {
          return {
            key: '_documents',
            type: 'repeatingTemplate',
            className: 'documents-list',
            templateOptions: {
              layout: 'row',
              title: $translate.instant('PartAssembly.DOCUMENTS.TITLE'),
              btnText: $translate.instant(
                'PartAssembly.DOCUMENTS.ADD_NEW_DOCUMENT'
              ),
              hideMovementButtons: true,
              addParameters: () => ({
                id: UUID(),
                title: '',
                type: documentTypes.LINK,
                resourceId: '',
                url: ''
              }),
              handleRemove: (index, data, model) => {
                if (type === 'sku') {
                  $scope.part._documents.splice(index, 1)
                } else {
                  $scope.part.revisions
                    .find(rev => rev.revision === model.revision)
                    ._documents.splice(index, 1)
                }
              },
              onChange: function () {},
              fields: [
                {
                  key: 'title',
                  type: 'input',
                  className: 'flex field',
                  templateOptions: {
                    required: true,
                    type: 'text',
                    label: $translate.instant('PartAssembly.DOCUMENTS.NAME')
                  },
                  expressionProperties: {
                    'templateOptions.disabled': 'formState.disabled'
                  }
                },
                {
                  key: 'type',
                  type: 'select',
                  className: 'flex field',
                  templateOptions: {
                    required: true,
                    options: documentOptions,
                    label: $translate.instant('PartAssembly.DOCUMENTS.TYPE')
                  },
                  expressionProperties: {
                    'templateOptions.disabled': 'formState.disabled'
                  }
                },
                {
                  key: 'url',
                  type: 'input',
                  className: 'flex field',
                  templateOptions: {
                    required: true,
                    type: 'text',
                    label: $translate.instant('PartAssembly.DOCUMENTS.LINK')
                  },
                  expressionProperties: {
                    'templateOptions.disabled': 'formState.disabled'
                  },
                  hideExpression: function ($viewValue, $modelValue, scope) {
                    return scope.model.type !== 'LINK'
                  }
                },
                {
                  key: 'resourceId',
                  type: 'upload',
                  className: 'flex resource-field',
                  templateOptions: {
                    required: true,
                    mode: 'full',
                    options: [],
                    label: $translate.instant('PartAssembly.DOCUMENTS.FILE'),
                    onChange: function (value, options, scope) {
                      if (value) {
                        Resource.findOne({
                          filter: { where: { id: value } }
                        }).$promise.then(resource => {
                          scope.model.title = resource.originalFilename
                          scope.model.url = `./api/containers/${resource.container}/download/${resource.name}`
                        })
                      } else {
                        scope.model.title = ''
                      }
                    }
                  },
                  expressionProperties: {
                    'templateOptions.disabled': 'formState.disabled'
                  },
                  hideExpression: function ($viewValue, $modelValue, scope) {
                    return scope.model.type !== 'FILE'
                  }
                }
              ]
            },
            expressionProperties: {
              'templateOptions.disabled': 'formState.disabled'
            }
          }
        }
        const updatePatternPreview = function updatePatternPreview () {
          const now = new Date()
          const year = now.getFullYear()
          const month = now.getMonth() + 1
          const day = now.getDate()
          const autoNumber = $scope.part.numerator ? $scope.part.numerator : 1
          const autoNumberWithPaddingZeros = autoNumber
            .toString()
            .padStart($scope.part.paddingZeros, '0')
          $scope.part.patternPreview = $scope.part.pattern
            .replace(/{{YYYY}}/g, year)
            .replace(/{{YY}}/g, year.toString().slice(-2))
            .replace(/{{MM}}/g, month)
            .replace(/{{DD}}/g, day)
            .replace(/{{AUTO_NUMBER}}/g, autoNumberWithPaddingZeros)
        }
        this.$onInit = async function () {
          const originalPart = this.part ? this.part : null
          $scope.mode = this.mode
          variableInitialization()

          initWatchers()

          $scope.originalPart = originalPart
          if ($scope.originalPart) await initChangeLog()

          $scope.canCreatePart = PermissionUtils.isPermit(
            'PartAssembly',
            'create'
          )

          $scope.canDeletePart = PermissionUtils.isPermit(
            'PartAssembly',
            'deletePartAssembly'
          )

          this.functionHelper.savePart = savePart

          $scope.functionHelper = this.functionHelper

          const staticPartFields = [
            // NAME
            {
              key: 'name',
              type: 'input',
              templateOptions: {
                type: 'text',
                focus: true,
                required: true,
                label: $translate.instant('PartAssembly.PART_NAME'),
                placeholder: $translate.instant('PartAssembly.PART_NAME'),
                onChange: function (value, options) {
                  options.validation.errorExistsAndShouldBeVisible = null
                  options.formControl.$setValidity('serverError', true)
                }
              },
              validation: {
                messages: {
                  required: () =>
                    $translate.instant('PartAssembly.ERRORS.NAME_IS_REQUIRED')
                }
              },
              expressionProperties: {
                'templateOptions.disabled': 'formState.disabled'
              }
            },
            // NUMBER
            {
              key: 'number',
              type: 'input',
              templateOptions: {
                type: 'text',
                required: true,
                label: $translate.instant('PartAssembly.PART_NUMBER'),
                placeholder: $translate.instant('PartAssembly.PART_NUMBER'),
                onChange: function (value, options) {
                  options.validation.errorExistsAndShouldBeVisible = null
                  options.formControl.$setValidity('serverError', true)
                }
              },
              validation: {
                messages: {
                  required: () =>
                    $translate.instant('PartAssembly.ERRORS.NUMBER_IS_REQUIRED')
                }
              },
              expressionProperties: {
                'templateOptions.disabled': (
                  $viewValue,
                  $modelValue,
                  scope
                ) => {
                  return !$scope.isNewPart
                }
              }
            },
            // UNIT
            {
              key: 'unitId',
              type: 'modelSelect',
              className: 'layout-column',
              templateOptions: {
                required: true,
                label: $translate.instant('PartAssembly.UNIT'),
                findMethod: Unit.find,
                mapObject: { id: 'id', name: 'name' },
                baseFilterObject: {
                  where: {},
                  fields: {
                    id: true,
                    name: true
                  }
                }
              },
              validation: {
                messages: {
                  required: () =>
                    $translate.instant('PartAssembly.ERRORS.UNIT_IS_REQUIRED')
                }
              },
              expressionProperties: {
                'templateOptions.disabled': 'formState.disabled'
              }
            },
            // BULK_PRODUCTION
            {
              key: 'bulkProduction',
              type: 'select',
              templateOptions: {
                label: $translate.instant('PartAssembly.BULK_PRODUCTION'),
                options: bulkProductionOptions,
                required: true,
                onChange: function (value, options) {
                  options.validation.errorExistsAndShouldBeVisible = null
                  options.formControl.$setValidity('serverError', true)
                }
              },
              expressionProperties: {
                'templateOptions.disabled': 'formState.disabled'
              }
            },
            // AUTO_CREATION
            {
              key: 'autoCreate',
              type: 'select',
              className: 'layout-column',
              templateOptions: {
                required: true,
                options: autoCreateOptions,
                label: $translate.instant('PartAssembly.AUTO_CREATION')
              }
            },
            // MANAGED_BY_REVISION
            {
              key: 'managedByRevision',
              type: 'checkbox',
              templateOptions: {
                label: $translate.instant('PartAssembly.MANAGED_BY_REVISION'),
                onChange: function (value, options) {
                  options.validation.errorExistsAndShouldBeVisible = null
                  options.formControl.$setValidity('serverError', true)
                }
              }
            },
            // IS_SERIAL
            {
              key: 'isSerial',
              type: 'checkbox',
              templateOptions: {
                label: $translate.instant('PartAssembly.IS_SERIAL'),
                onChange: function (value, options) {
                  if (value) {
                    $scope.part.freeStock = false
                    $scope.part.serialNumberRequiredAtStart = true
                  } else {
                    $scope.part.serialNumberRequiredAtStart = false
                    $scope.part.generateSerials = false
                  }
                  options.validation.errorExistsAndShouldBeVisible = null
                  options?.formControl?.$setValidity('serverError', true)
                }
              },
              expressionProperties: {
                'templateOptions.disabled': 'formState.disabled'
              }
            },
            // SERIAL_NUMBER_REQUIRED_AT_START
            {
              key: 'serialNumberRequiredAtStart',
              type: 'checkbox',
              templateOptions: {
                label: $translate.instant(
                  'PartAssembly.SERIAL_NUMBER_REQUIRED_AT_START'
                ),
                onChange: function (value, options) {
                  options.validation.errorExistsAndShouldBeVisible = null
                  options?.formControl?.$setValidity('serverError', true)
                }
              },
              expressionProperties: {
                'templateOptions.disabled':
                  'formState.disabled || !model.isSerial'
              }
            },
            // FREE_STOCK
            {
              key: 'freeStock',
              type: 'checkbox',
              templateOptions: {
                label: $translate.instant('PartAssembly.FREE_STOCK'),
                onChange: function (value, options) {
                  options.validation.errorExistsAndShouldBeVisible = null
                  options.formControl.$setValidity('serverError', true)
                }
              },
              expressionProperties: {
                'templateOptions.disabled':
                  'formState.disabled || model.isSerial'
              }
            },
            // HAS_EXPIRATION_DATE
            {
              key: 'hasExpirationDate',
              type: 'checkbox',
              templateOptions: {
                label: $translate.instant('PartAssembly.HAS_EXPIRATION_DATE'),
                onChange: function (value, options) {
                  options.validation.errorExistsAndShouldBeVisible = null
                  options.formControl.$setValidity('serverError', true)
                }
              },
              expressionProperties: {
                'templateOptions.disabled': 'formState.disabled'
              }
            },
            // IS_PHANTOM
            {
              key: 'isPhantom',
              type: 'checkbox',
              templateOptions: {
                label: $translate.instant('PartAssembly.IS_PHANTOM'),
                onChange: function (value, options) {
                  options.validation.errorExistsAndShouldBeVisible = null
                  options.formControl.$setValidity('serverError', true)
                }
              },
              expressionProperties: {
                'templateOptions.disabled': (
                  $viewValue,
                  $modelValue,
                  scope
                ) => {
                  return scope.formState.disabled || $scope.mode === 'easy'
                }
              }
            },
            // GENERATE_SERIALS
            {
              key: 'generateSerials',
              type: 'checkbox',
              templateOptions: {
                label: $translate.instant('PartAssembly.GENERATE_SERIALS')
              },
              hideExpression: function ($viewValue, $modelValue, scope) {
                return !scope.model.isSerial
              }
            },
            // NUMERATOR
            {
              key: 'numerator',
              type: 'input',
              templateOptions: {
                type: 'number',
                label: $translate.instant('PartAssembly.NUMERATOR'),
                min: 1,
                onChange: function (value, options) {
                  options.validation.errorExistsAndShouldBeVisible = null
                  options.formControl.$setValidity('serverError', true)
                  updatePatternPreview()
                }
              },
              hideExpression: function ($viewValue, $modelValue, scope) {
                return !scope.model.isSerial || !scope.model.generateSerials
              }
            },
            // PADDING_ZEROS
            {
              key: 'paddingZeros',
              type: 'input',
              templateOptions: {
                type: 'number',
                label: $translate.instant('PartAssembly.PADDING_ZEROS'),
                onChange: function (value, options) {
                  options.validation.errorExistsAndShouldBeVisible = null
                  options.formControl.$setValidity('serverError', true)
                  updatePatternPreview()
                }
              },
              hideExpression: function ($viewValue, $modelValue, scope) {
                return !scope.model.isSerial || !scope.model.generateSerials
              }
            },
            // PATTERN
            {
              key: 'pattern',
              type: 'input',
              templateOptions: {
                type: 'text',
                label: $translate.instant('PartAssembly.PATTERN'),
                hint: $translate.instant('PartAssembly.PATTERN_HINT', {
                  availablePatterVariables: availablePatterVariables.join(', ')
                }),
                onChange: function (value, options) {
                  options.validation.errorExistsAndShouldBeVisible = null
                  options.formControl.$setValidity('serverError', true)
                  updatePatternPreview()
                }
              },
              hideExpression: function ($viewValue, $modelValue, scope) {
                return !scope.model.isSerial || !scope.model.generateSerials
              }
            },
            // PATTERN_PREVIEW
            {
              key: 'patternPreview',
              type: 'input',
              templateOptions: {
                type: 'text',
                label: $translate.instant('PartAssembly.PATTERN_PREVIEW'),
                disabled: true
              },
              hideExpression: function ($viewValue, $modelValue, scope) {
                return (
                  !scope.model.isSerial ||
                  !scope.model.generateSerials ||
                  !scope.model.pattern
                )
              }
            },
            // TIME_TRACKING
            {
              key: 'timeTracking',
              type: 'checkbox',
              templateOptions: {
                label: $translate.instant('PartAssembly.ENABLE_TIME_TRACKING'),
                onChange: function (value, options) {
                  options.validation.errorExistsAndShouldBeVisible = null
                  options.formControl.$setValidity('serverError', true)
                }
              },
              expressionProperties: {
                'templateOptions.disabled': 'formState.disabled'
              }
            },
            // METHODS
            {
              key: 'methods',
              type: 'repeatingTemplate',
              templateOptions: {
                layout: 'row',
                title: $translate.instant('PartAssembly.TIME_TRACKING_METHODS'),
                btnText: $translate.instant('PartAssembly.ADD_NEW_METHOD'),
                hideMovementButtons: true,
                hideProperty: 'deletedAt',
                addParameters: () => ({
                  methodName: '',
                  standardApplyTime: '',
                  standardCureTime: '',
                  deletedAt: null
                }),
                handleRemove: (index, data) => {
                  if ($scope.part.methods[index].id) {
                    $scope.part.methods[index].deletedAt = new Date()
                  } else {
                    $scope.part.methods.splice(index, 1)
                  }
                },
                onChange: function () {},
                fields: [
                  {
                    key: 'methodName',
                    type: 'input',
                    className: 'flex',
                    templateOptions: {
                      required: true,
                      type: 'text',
                      label: $translate.instant('PartAssembly.METHOD_NAME')
                    },
                    expressionProperties: {
                      'templateOptions.disabled': 'formState.disabled'
                    }
                  },
                  {
                    key: 'standardApplyTime',
                    type: 'input',
                    className: 'flex',
                    templateOptions: {
                      required: true,
                      type: 'number',
                      min: 0,
                      label: $translate.instant(
                        'PartAssembly.STANDARD_APPLY_TIME'
                      )
                    },
                    expressionProperties: {
                      'templateOptions.disabled': 'formState.disabled'
                    }
                  },
                  {
                    key: 'standardCureTime',
                    type: 'input',
                    className: 'flex',
                    templateOptions: {
                      required: true,
                      type: 'number',
                      min: 0,
                      label: $translate.instant(
                        'PartAssembly.STANDARD_CURE_TIME'
                      )
                    },
                    expressionProperties: {
                      'templateOptions.disabled': 'formState.disabled'
                    }
                  }
                ]
              },
              hideExpression: function ($viewValue, $modelValue, scope) {
                return !scope.model.timeTracking
              },
              expressionProperties: {
                'templateOptions.disabled': 'formState.disabled'
              }
            },
            // IS_COMBINATED
            {
              key: 'isCombinated',
              type: 'checkbox',
              templateOptions: {
                label: $translate.instant('PartAssembly.IS_COMBINATED'),
                onChange: function (value, options) {
                  combinatedChanged()
                  options.validation.errorExistsAndShouldBeVisible = null
                  options.formControl.$setValidity('serverError', true)
                }
              },
              expressionProperties: {
                'templateOptions.disabled': 'formState.disabled'
              }
            },
            // COMBINED_PARTS
            {
              key: 'combinationParts',
              type: 'repeatedList',
              templateOptions: {
                btnText: $translate.instant(
                  'PartAssembly.ADD_NEW_COMBINATION_PART'
                ),
                label: 'number',
                title: $translate.instant('PartAssembly.COMBINATIONS_PARTS'),
                hideEdit: true,
                hideMovement: true,
                onAdd: function (addObject, index) {
                  openCombinationPartsSelection()
                },
                onRemove: function (removedValue, index) {},
                onEdit: function (editedValue, index) {},
                onMove: function (index, isMoveUp) {
                  // call back with index
                }
              },
              hideExpression: function ($viewValue, $modelValue, scope) {
                return scope.model && !scope.model.isCombinated
              },
              expressionProperties: {
                'templateOptions.disabled': 'formState.disabled'
              }
            },
            // DOCUMENTS
            getDocumentsField('sku'),
            // IMAGES
            {
              className: 'display-flex layout-row',
              fieldGroup: [
                {
                  key: 'picture',
                  type: 'upload',
                  className: 'flex-6 margin-5',
                  templateOptions: {
                    label: $translate.instant('PartAssembly.IMAGE'),
                    filetype: 'image',
                    caption: $translate.instant('PartAssembly.IMAGE'),
                    mode: 'full',
                    keyIs: 'id',
                    object: 'picture_object',
                    onClick: function (value, options) {
                      options.validation.errorExistsAndShouldBeVisible = null
                      options.formControl.$setValidity('serverError', true)
                    },
                    onFileSelect: function (result) {
                      $scope.fileSelected(result, 'picture')
                    }
                  },
                  expressionProperties: {
                    'templateOptions.disabled': 'formState.disabled'
                  }
                },
                {
                  key: 'image',
                  type: 'upload',
                  className: 'flex-6 margin-5',
                  templateOptions: {
                    label: $translate.instant('PartAssembly.DRAWING'),
                    filetype: 'image',
                    caption: $translate.instant('PartAssembly.DRAWING'),
                    mode: 'full',
                    keyIs: 'id',
                    object: 'image_object',
                    onClick: function (value, options) {
                      options.validation.errorExistsAndShouldBeVisible = null
                      options.formControl.$setValidity('serverError', true)
                    },
                    onFileSelect: function (result) {
                      $scope.fileSelected(result, 'image')
                    }
                  },
                  hideExpression: function ($viewValue, $modelValue, scope) {
                    return scope.model.nodes.length === 0
                  },
                  expressionProperties: {
                    'templateOptions.disabled': 'formState.disabled'
                  }
                }
              ]
            }
          ]

          $scope.partFields = FormlyHelper.buildFields(
            staticPartFields,
            PartAssembly
          )

          initModel(originalPart)
          updatePatternPreview()

          const revisionsRepeatingTemplate = [
            // REVISIONS
            {
              key: 'revisions',
              type: 'repeatingTemplate',
              templateOptions: {
                layout: 'row',
                title: $translate.instant('PartAssembly.REVISIONS'),
                btnText: $translate.instant('PartAssembly.ADD_NEW_REVISION'),
                hideMovementButtons: true,
                addParameters: () => ({
                  id: UUID(),
                  revision: '',
                  valid: false,
                  active: false
                }),
                handleRemove: (index, data) => {
                  $scope.part.revisions.splice(index, 1)
                },
                fields: [
                  {
                    key: 'revision',
                    type: 'input',
                    className: 'flex-xs-100 flex-20 m-5 layout-row',
                    templateOptions: {
                      required: true,
                      type: 'text',
                      label: $translate.instant('PartAssembly.REVISION_NAME')
                    },
                    expressionProperties: {
                      'templateOptions.disabled': 'formState.disabled'
                    }
                  },
                  {
                    key: 'valid',
                    type: 'checkbox',
                    className: 'flex-xs-100 flex-20 m-5 layout-row',
                    templateOptions: {
                      label: $translate.instant('PartAssembly.IS_VALID')
                    },
                    expressionProperties: {
                      'templateOptions.disabled': 'formState.disabled'
                    }
                  },
                  {
                    key: 'active',
                    type: 'checkbox',
                    className: 'flex-xs-100 flex-20 m-5 layout-row',
                    templateOptions: {
                      label: $translate.instant('PartAssembly.NOT_ACTIVE')
                    },
                    expressionProperties: {
                      'templateOptions.disabled': 'formState.disabled'
                    }
                  },
                  ...FormlyHelper.buildFields([], Revision),
                  getDocumentsField('rev')
                ]
              },
              hideExpression: function ($viewValue, $modelValue, scope) {
                return !scope.model.managedByRevision
              }
            }
          ]

          $scope.partRevisionsFields = revisionsRepeatingTemplate

          $(document).ready(() => {
            if ($rootScope.dir === 'rtl') {
              $('#map-panel').before($('#table-panel')) // swap panes
            }
            initSplitter()
            initInnerSplitter()
            resizeMapContainer()
            $(window).on(
              'resize.doResize',
              _.debounce(function () {
                const splitter = $('#splitter').data('kendoSplitter')
                if (splitter) {
                  splitter.destroy()
                  $('.k-splitbar').remove()
                }
                initSplitter()
                initInnerSplitter()
              }, 500)
            )
          })
        }
      }
  }
}

module.exports = partEdit
