/* global getComputedStyle */
require('./loading-text.scss')

/** @ngInject */
function LoadingTextComponent () {
  return {
    template: require('./loading-text.html'),
    controller: LoadingTextController,
    transclude: true,
    bindings: {
      stop: '<',
      backgroundColor: '<'
    }
  }
}

/** @ngInject */
function LoadingTextController ($element) {
  const vm = this

  // Find the first parent that have a backgroundColor color set, recursively, limit to 30 levels
  const findParentbackgroundColor = function (elm, level = 0) {
    if (level > 30) return
    const parent = elm.parent()
    if (parent.length) {
      // Get calculated backgroundColor color
      const backgroundColor = getComputedStyle(parent[0]).backgroundColor
      // Skip transparent backgrounds (rgba(0,0,0,0) or transparent)
      if (
        backgroundColor &&
        backgroundColor !== 'rgba(0, 0, 0, 0)' &&
        backgroundColor !== 'transparent'
      ) {
        return backgroundColor
      } else {
        return findParentbackgroundColor(parent, level + 1)
      }
    }
  }
  let counter = 0
  const setbackgroundColor = function () {
    vm.backgroundColor = findParentbackgroundColor($element)
    if (!vm.backgroundColor && counter < 10) {
      counter++
      setTimeout(setbackgroundColor, 100)
    }
  }
  vm.$onInit = function () {
    if (!vm.backgroundColor) {
      setbackgroundColor()
    }
    vm.stop = vm.stop || false
  }
}

module.exports = LoadingTextComponent
