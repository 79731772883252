/* global HTMLVideoElement */

export class BrowserStream {
  constructor () {
    this.stream = null
    this.videoElement = null
  }

  /**
   * If navigator is present.
   */
  get hasNavigator () {
    return typeof navigator !== 'undefined'
  }

  /**
   * If mediaDevices under navigator is supported.
   */
  get isMediaDevicesSupported () {
    return this.hasNavigator && !!navigator.mediaDevices
  }

  /**
   * If enumerateDevices under navigator is supported.
   */
  get canEnumerateDevices () {
    return !!(
      this.isMediaDevicesSupported && navigator.mediaDevices.enumerateDevices
    )
  }

  async canStreamVideo () {
    if (!this.canEnumerateDevices) return false
    const devices = await navigator.mediaDevices.enumerateDevices()
    return devices.some(device => device.kind === 'videoinput')
  }

  async getVideoDevices () {
    const devices = await navigator.mediaDevices.enumerateDevices()
    return devices.filter(device => device.kind === 'videoinput')
  }

  async startStreamProcess (deviceId, videoSource) {
    this.reset()
    let videoConstraints
    if (!deviceId) {
      videoConstraints = {
        facingMode: 'environment',
        width: { ideal: 4096 },
        height: { ideal: 2160 },
        aspectRatio: { ideal: 16 / 9 }
      }
    } else {
      videoConstraints = {
        deviceId: { exact: deviceId },
        width: { ideal: 4096 },
        height: { ideal: 2160 },
        aspectRatio: { ideal: 16 / 9 }
      }
    }

    const constraints = {
      video: videoConstraints
    }

    return this.startStreamWithConstraints(constraints, videoSource)
  }

  async startStreamWithConstraints (constraints, videoSource) {
    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints)
      return this.startStream(stream, videoSource)
    } catch (err) {
      if (
        err.name === 'OverconstrainedError' ||
        err.name === 'ConstraintNotSatisfiedError'
      ) {
        const fallbackConstraints = {
          video: {
            facingMode: constraints.video.facingMode,
            deviceId: constraints.video.deviceId,
            width: { ideal: 1920 },
            height: { ideal: 1080 }
          }
        }
        const stream = await navigator.mediaDevices.getUserMedia(
          fallbackConstraints
        )
        return this.startStream(stream, videoSource)
      }
      throw err
    }
  }

  async startStream (stream, videoSource) {
    this.reset()
    const video = await this.attachStreamToVideo(stream, videoSource)
    return video
  }

  async attachStreamToVideo (stream, videoSource) {
    const videoElement = this.prepareVideoElement(videoSource)

    this.addVideoSource(videoElement, stream)

    this.videoElement = videoElement
    this.stream = stream

    await this.playVideoOnLoadAsync(videoElement)

    return videoElement
  }

  /**
   *
   * @param videoElement
   */
  playVideoOnLoadAsync (videoElement) {
    return new Promise((resolve, reject) =>
      this.playVideoOnLoad(videoElement, () => resolve())
    )
  }

  playVideoOnLoad (element, callbackFn) {
    this.videoEndedListener = () => this.stopStreams()
    this.videoCanPlayListener = () => this.tryPlayVideo(element)

    element.addEventListener('ended', this.videoEndedListener)
    element.addEventListener('canplay', this.videoCanPlayListener)
    element.addEventListener('playing', callbackFn)

    this.tryPlayVideo(element)
  }

  isVideoPlaying (video) {
    return (
      video.currentTime > 0 &&
      !video.paused &&
      !video.ended &&
      video.readyState > 2
    )
  }

  async tryPlayVideo (videoElement) {
    if (this.isVideoPlaying(videoElement)) {
      return
    }

    try {
      await videoElement.play()
    } catch (err) {
      console.warn('It was not possible to play the video:', err.message)
    }
  }

  getMediaElement (mediaElementId, type) {
    const mediaElement = document.getElementById(mediaElementId)
    if (!mediaElement) {
      console.error(`element with id '${mediaElementId}' not found`)
    }
    if (mediaElement.nodeName.toLowerCase() !== type.toLowerCase()) {
      console.error(
        `element with id '${mediaElementId}' must be an ${type} element`
      )
    }
    return mediaElement
  }

  prepareVideoElement (videoSource) {
    let videoElement

    if (!videoSource && typeof document !== 'undefined') {
      videoElement = document.createElement('video')
      videoElement.width = 200
      videoElement.height = 200
    }

    if (typeof videoSource === 'string') {
      videoElement = this.getMediaElement(videoSource, 'video')
    }

    if (videoSource instanceof HTMLVideoElement) {
      videoElement = videoSource
    }

    // Needed for iOS 11
    videoElement.setAttribute('autoplay', 'true')
    videoElement.setAttribute('muted', 'true')
    videoElement.setAttribute('playsinline', 'true')

    return videoElement
  }

  stopStreams () {
    if (this.stream) {
      this.stream.getVideoTracks().forEach(t => t.stop())
      this.stream = undefined
    }
  }

  reset () {
    // stops the camera, preview and scan 🔴
    this.stopStreams()

    // clean and forget about HTML elements
    this._destroyVideoElement()
  }

  _destroyVideoElement () {
    if (!this.videoElement) {
      return
    }
    // first gives freedom to the element 🕊
    if (typeof this.videoEndedListener !== 'undefined') {
      this.videoElement.removeEventListener('ended', this.videoEndedListener)
    }

    if (typeof this.videoPlayingEventListener !== 'undefined') {
      this.videoElement.removeEventListener(
        'playing',
        this.videoPlayingEventListener
      )
    }
    if (typeof this.videoCanPlayListener !== 'undefined') {
      this.videoElement.removeEventListener(
        'loadedmetadata',
        this.videoCanPlayListener
      )
    }

    // then forgets about that element 😢

    this.cleanVideoSource(this.videoElement)

    this.videoElement = undefined
  }

  addVideoSource (videoElement, stream) {
    // Older browsers may not have `srcObject`
    try {
      // @note Throws Exception if interrupted by a new loaded request
      videoElement.srcObject = stream
    } catch (err) {
      // @note Avoid using this in new browsers, as it is going away.
      videoElement.src = URL.createObjectURL(stream)
    }
  }

  cleanVideoSource (videoElement) {
    try {
      videoElement.srcObject = null
    } catch (err) {
      videoElement.src = ''
    }
    this.videoElement.removeAttribute('src')
  }
}
