require('./ai-prompt.scss')

/** @ngInject */
function aiPromptComponent () {
  return {
    template: require('./ai-prompt.html'),
    bindings: {
      ngModel: '=',
      onUpdate: '&'
    },
    controller: AIPromptController,
    controllerAs: 'vm',
    require: 'ngModel'
  }
}

/** @ngInject */
function AIPromptController ($scope) {
  const vm = this

  vm.$onInit = function () {
    updateParsedPrompt()

    // Watch for model changes
    $scope.$watch(
      () => vm.ngModel,
      (newVal, oldVal) => {
        if (newVal !== oldVal) {
          updateParsedPrompt()
          $scope.$applyAsync()
        }
      }
    )
  }

  function updateParsedPrompt () {
    if (!vm.ngModel) return
    vm.parsedPrompt = parsePrompt(vm.ngModel)
    $scope.$applyAsync()
  }

  vm.prepareForDisplay = function (text) {
    return text.replace(/<br>/g, '\n')
  }

  vm.updateContent = function (part, $event) {
    part.text = $event.target.innerText.replace(/\n/g, '<br>')
    vm.parsedPrompt = vm.parsedPrompt.map(p => (p === part ? { ...p } : p))

    // Reconstruct the prompt and notify parent
    const reconstructedPrompt = reconstructPrompt(vm.parsedPrompt)
    vm.ngModel = reconstructedPrompt
    vm.onUpdate({ prompt: reconstructedPrompt.replace(/<br>/g, '\n') })
  }

  // Function to parse prompt and separate changeable parts
  function parsePrompt (prompt) {
    if (!prompt) return []
    const parts = prompt.split(/(<changeable>|<\/changeable>)/)
    return parts
      .filter(part => part !== '<changeable>' && part !== '</changeable>')
      .map((part, index) => ({
        text: part,
        editable: index % 2 === 1
      }))
  }

  // Function to reconstruct full prompt
  function reconstructPrompt (parsedPrompt) {
    return parsedPrompt
      .map(part => {
        let content = part.text
        if (part.editable) {
          content = `<changeable>${content}</changeable>`
        }
        return content
      })
      .join('')
  }
}

module.exports = aiPromptComponent
