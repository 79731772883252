/* global _ angular */
import CustomFormClass from './custom-form.class'
import { splashScreenGone } from 'app/helper.js'
import FormValidationDialogController from './dialog/form-validation-dialog.controller.js'
import FormReviewController from '../../modules/main/form/review/form-review.controller.js'
const {
  FAILURE_FORM_DETAILS
} = require('../../../../../common/constants/form-constants.json')
/** @ngInject */
function customForm () {
  return {
    restrict: 'E',
    template: require('./custom-form.html'),
    replace: true,
    scope: {
      model: '=',
      sessionId: '=',
      unitId: '=',
      form: '=',
      fields: '=',
      formDataId: '=',
      formExtras: '=',
      isDisabled: '=',
      displayMode: '=',
      sessionData: '=?',
      users: '=?',
      workflows: '=?',
      showApproval: '=',
      onLoad: '&?',
      allForms: '=?',
      parentLinkedForm: '=?'
    },
    /** @ngInject */
    controller: (
      $rootScope,
      $scope,
      $translate,
      $mdToast,
      $timeout,
      FormData,
      htmlWork,
      FormlyHelper,
      PermissionUtils,
      FormUtils,
      PanelHelper,
      IntercomService,
      PartAssembly,
      Stock,
      $mdDialog
    ) => {
      const errorMdToast = $mdToast.nextplus({
        position: $rootScope.toastLocation,
        parent: 'document.body',
        theme: 'error-toast',
        hideDelay: 3500
      })
      const { certificateIds, roles } = $rootScope.currentUser
      $scope.forms = {}
      const extraKeys = []
      const nodeModel = $rootScope.appSettings.modelsFields.Node
      const nodeProperties = nodeModel.properties
      _.mapKeys(nodeProperties, (value, key) => {
        if (value.custom) {
          extraKeys.push('node_' + value.key)
        }
      })
      $scope.part = null
      $scope.runPreSaveTriggers = true
      $scope.displayStockDetails = false
      $scope.disableSerialField = false
      const formInstance = new CustomFormClass(
        $scope.form,
        $scope.fields,
        certificateIds,
        roles,
        $scope.sessionId,
        $scope.unitId,
        $scope.formDataId,
        $scope.model,
        $scope.sessionData,
        $scope.parentLinkedForm,
        extraKeys
      )
      let workflowResolvedOptions
      let sessionDataKeys
      let statuses
      let formIsValid
      const formFields = formInstance.getFormFields()

      $scope.canCreate = formInstance.canCreate()
      $scope.addFormLink = false
      $scope.statusTemplateByFormId = {}
      $scope.formsById = _.keyBy($scope.allForms, 'id')

      if (!$scope.form.type || $scope.form.type !== 'WorkorderStatus') {
        workflowResolvedOptions = ($scope.workflows || []).map(workflow => {
          let workflowOptionName = $translate.instant('WF.VERSION_NUMBER', {
            number: workflow.normalizedVersion
          })
          if (workflow.parts && workflow.parts.length) {
            workflowOptionName = `${workflow.version} - `
            const parts = []
            workflow.parts.forEach(partObject => {
              if (partObject.sku && partObject.sku !== '') {
                parts.push(
                  `${partObject.sku}${
                    partObject.rev ? ` (${partObject.rev})` : ''
                  }`
                )
              }
            })
            workflowOptionName += parts.join(', ')
          }
          return {
            id: workflow.normalizedVersion,
            name: workflowOptionName
          }
        })

        $scope.canClose = FormUtils.isPermit('close', $scope.form)
        $scope.edit = formInstance.isEditMode()
        $scope.isDisabled = $scope.isDisabled || false
        $scope.isDisabledByStatus = false
        $scope.statusDisabled = $scope.isDisabled || false
        $scope.displayMode = $scope.displayMode || false
        $scope.hasStatus = formInstance.hasStatus()
        $scope.context = formInstance.getContext()
        $scope.viewers = formInstance.getViewers()
        sessionDataKeys = formInstance.getSessionDataKeys()
        statuses = formInstance.getStatuses()
        $scope.hasApprovalWorkflows = formInstance.hasApprovalWorkflows()
        $scope.workorderId = formInstance.getWorkorderId()
        $scope.workorderNumber = formInstance.getWorkorderName()
        $scope.orderNumber = formInstance.getWorkorderOrderNumber()
        $scope.accountName = formInstance.getWorkorderCustomerAccountName()
        $scope.approvalModels = {}
        $scope.detailsFields = []
        $scope.stockDetailsFields = []
        $scope.formDataSearchFields = []
        $scope.formDataSearchModel = {}
        $scope.localObject = {
          tabIndex: 0
        }
        formIsValid = false

        if (!$scope.form.type || $scope.form.type !== 'WorkorderStatus') {
          $scope.detailsFormOptions = {
            formState: {
              disabled:
                !$scope.canCreate ||
                $scope.isDisabled ||
                $scope.isDisabledByStatus
            }
          }
          $scope.stockDetailsFormOptions = {
            formState: {
              disabled:
                !$scope.canCreate ||
                $scope.isDisabled ||
                $scope.isDisabledByStatus
            }
          }
          $scope.canRestart = PermissionUtils.isPermit(
            'FormData',
            'restartApprovalProcess'
          )
        }
      }
      $scope.formOptions = {
        formState: {
          disabled:
            !$scope.canCreate || $scope.isDisabled || $scope.isDisabledByStatus
        }
      }

      $scope.detailsFormOptions = {
        formState: {
          disabled:
            !$scope.canCreate || $scope.isDisabled || $scope.isDisabledByStatus
        }
      }
      $scope.stockDetailsFormOptions = {
        formState: {
          disabled:
            !$scope.canCreate || $scope.isDisabled || $scope.isDisabledByStatus
        }
      }

      const fieldShouldBeDisabled = function fieldShouldBeDisabled (field) {
        if ($scope.form.id === FAILURE_FORM_DETAILS.FAILURE_REPORT_ID) {
          if ($scope.formDataId) {
            return true
          }
          switch (field.key) {
            case FAILURE_FORM_DETAILS.FAILURE_QUANTITY_FIELD_ID:
              return $scope.sessionData.isSerial
            case FAILURE_FORM_DETAILS.FAILURE_REMOVE_STOCK_ITEMS_FIELD_ID:
              return !$scope.sessionData.createStock
            case FAILURE_FORM_DETAILS.FAILURE_CHANGE_SERIAL_NUMBER_FIELD_ID:
              return !$scope.sessionData.isSerial
          }
        }
      }

      const addOptionsForFields = function addOptionsForFields (fields) {
        fields.forEach(field => {
          if (!field.templateOptions) {
            field.templateOptions = {}
          }
          field.templateOptions.onFileSelect = function (value) {
            $scope.formExtras[$scope.unitId].formReady = true
          }
          if (field.type === 'upload' || field.type === 'subForm') {
            field.templateOptions.formDataId = formInstance.getFormDataId()
          }

          field.templateOptions.onChange = function (value, options) {
            $timeout(() => {
              formIsValid = $scope.forms.mainForm.$valid
              $scope.formExtras[$scope.unitId].formReady =
                ($scope.forms.mainForm && !$scope.forms.mainForm.$pristine) ||
                value !== null
            }, 0)
          }
          if (fieldShouldBeDisabled(field)) {
            field.templateOptions.disabled = true
          }
          if (
            field.key ===
            FAILURE_FORM_DETAILS.FAILURE_NEW_SERIAL_NUMBER_FIELD_ID
          ) {
            field.expressionProperties = field.expressionProperties || {}
            field.hideExpression = ($viewValue, $modelValue, scope) => {
              return !scope.model[
                FAILURE_FORM_DETAILS.FAILURE_CHANGE_SERIAL_NUMBER_FIELD_ID
              ]
            }
          }
          if (field.key === FAILURE_FORM_DETAILS.FAILURE_QUANTITY_FIELD_ID) {
            field.hideExpression = ($viewValue, $modelValue, scope) => {
              return (
                $scope.sessionData.isSerial &&
                $scope.sessionData.currentQuantity <= 1
              )
            }
            if ($scope.sessionData) {
              field.defaultValue = $scope.sessionData.currentQuantity
            }
          }
          if (
            [
              FAILURE_FORM_DETAILS.FAILURE_REMOVE_STOCK_ITEMS_FIELD_ID,
              FAILURE_FORM_DETAILS.FAILURE_CHANGE_SERIAL_NUMBER_FIELD_ID
            ].includes(field.key)
          ) {
            field.hideExpression = ($viewValue, $modelValue, scope) => {
              return !$scope.sessionData.createStock
            }
          }
          field.expressionProperties = field.expressionProperties || {}
          field.expressionProperties['templateOptions.disabled'] = (
            $viewValue,
            $modelValue,
            scope
          ) => {
            return (
              !$scope.canCreate ||
              $scope.isDisabled ||
              $scope.isDisabledByStatus ||
              !formInstance.canEditField(scope.options.key) ||
              scope.options.templateOptions.disabled
            )
          }
        })

        // if ($scope.formDataId) {
        //   fields.push({
        //     wrapper: 'collapsibleGroup',
        //     templateOptions: {
        //       class: 'multiple-open'
        //     },
        //     fieldGroup: [
        //       {
        //         wrapper: 'collapsibleItem',
        //         templateOptions: {
        //           class: 'form-history-collapsiable-item',
        //           label: $translate.instant('FORM.CHANGE_LOGS.TITLE')
        //         },
        //         fieldGroup: [],
        //         hideExpression: function ($viewValue, $modelValue, scope) {
        //           return !scope.model.hasLogs
        //         }
        //       }
        //     ]
        //   })
        // }
        return fields
      }

      const createFormlyFields = async function createFormlyFields () {
        if (!$scope.form.type || $scope.form.type !== 'WorkorderStatus') {
          $scope.detailsFields = [
            // CREATED AT
            {
              key: 'created',
              type: 'dateTimePicker',
              className: 'form-created',
              templateOptions: {
                label: $translate.instant('FORM.CREATED_AT'),
                disabled: true,
                onChange: function (value, options) {
                  formInstance.createDisplayFieldsObject()
                }
              },
              hideExpression: function ($viewValue, $modelValue, scope) {
                return _.isNil(scope.model.created)
              }
            },
            // OWNER
            {
              key: 'ownerId',
              type: 'selectUser',
              className: 'form-owner-id',
              templateOptions: {
                label: $translate.instant('FORM.REPORTER'),
                showConstants: false,
                showAssignee: false,
                disabled:
                  $scope.edit ||
                  !$scope.canCreate ||
                  $scope.isDisabled ||
                  $scope.isDisabledByStatus,
                multiple: false,
                onChange: function (value, options) {
                  $scope.formExtras[$scope.unitId].formReady = true
                  formInstance.createDisplayFieldsObject()
                }
              },
              expressionProperties: {
                'templateOptions.disabled': (
                  $viewValue,
                  $modelValue,
                  scope
                ) => {
                  return (
                    !$scope.canCreate ||
                    $scope.isDisabled ||
                    $scope.isDisabledByStatus
                  )
                }
              }
            },
            // CREATED BY
            {
              template: `<div layout="row" layout-align="start center" ng-if="model.createdUser" style="font-size: 12px; color: #b4b4b4;margin-bottom:1rem;">
                              <span translate="CREATED_BY"></span>:&nbsp;<span>{{::model.createdUser.displayName}}</span>
                            </div>`
            },
            // ASSIGNEE
            {
              key: 'assignee',
              type: 'selectUser',
              className: 'layout-column selectUser form-assignee',
              templateOptions: {
                label: $translate.instant('FORM.ASSIGNEE'),
                multiple: true,
                showConstants: false,
                showAssignee: false,
                showAssignToMe: true,
                onChange: function (value, options) {
                  $scope.formExtras[$scope.unitId].formReady = true
                  formInstance.createDisplayFieldsObject()
                }
              },
              hideExpression: function () {
                return formInstance.shouldRemoveAssignee()
              },
              expressionProperties: {
                'templateOptions.disabled': (
                  $viewValue,
                  $modelValue,
                  scope
                ) => {
                  return (
                    !$scope.canCreate ||
                    $scope.isDisabled ||
                    $scope.isDisabledByStatus
                  )
                }
              }
            },
            // MODIFIED BY
            {
              key: 'UserId',
              type: 'selectUser',
              className: 'form-UserId',
              templateOptions: {
                label: $translate.instant('MODIFIED_BY'),
                disabled: true,
                showConstants: false,
                showAssignee: false,
                multiple: false
              }
            },
            // CLOSED BY
            {
              key: 'closedBy',
              type: 'selectUser',
              className: 'form-closedBy',
              templateOptions: {
                label: $translate.instant('FORM.CLOSED_BY'),
                disabled: true,
                showConstants: false,
                showAssignee: false,
                multiple: false
              },
              hideExpression: function ($viewValue, $modelValue, scope) {
                return !$scope.hasStatus
              }
            },
            // CLOSED AT
            {
              key: 'closedAt',
              type: 'dateTimePicker',
              className: 'form-closedAt',
              templateOptions: {
                label: $translate.instant('FORM.CLOSED_AT'),
                disabled: true
              },
              hideExpression: function ($viewValue, $modelValue, scope) {
                return !$scope.hasStatus || _.isNil(scope.model.closedAt)
              }
            },
            // STATUS
            {
              key: 'status',
              type: 'selectWithSearch',
              className: 'layout-column form-status-field',
              templateOptions: {
                label: $translate.instant('FORM.STATUS.NAME'),
                multiple: false,
                disabled: !$scope.canCreate || $scope.statusDisabled,
                options: statuses.map(status => {
                  const statusName = $translate.instant(status.name)
                  return {
                    id: statusName,
                    name: statusName,
                    color: status.color,
                    icon: 'icon-checkbox-blank-circle',
                    notAnOption: status.status === 'close' && !$scope.canClose
                  }
                }),
                onChange: function (value, options) {
                  $scope.formExtras[$scope.unitId].formReady = true
                  formInstance.createDisplayFieldsObject()
                }
              },
              expressionProperties: {
                'templateOptions.disabled': (
                  $viewValue,
                  $modelValue,
                  scope
                ) => {
                  return !$scope.canCreate || $scope.statusDisabled
                }
              },
              hideExpression: function ($viewValue, $modelValue, scope) {
                return !$scope.hasStatus
              }
            },
            // RESOLVED WORKFLOW VERSION
            {
              key: 'resolvedWorkflowVersion',
              type: 'selectWithSearch',
              className: 'layout-column form-resolved-workflow-version',
              templateOptions: {
                label: $translate.instant('FORM.RESOLVED_WORKFLOW'),
                multiple: false,
                options: workflowResolvedOptions,
                onChange: function (value, options) {
                  $scope.formExtras[$scope.unitId].formReady = true
                  formInstance.createDisplayFieldsObject()
                }
              },
              expressionProperties: {
                'templateOptions.disabled': (
                  $viewValue,
                  $modelValue,
                  scope
                ) => {
                  return (
                    !$scope.canCreate ||
                    $scope.isDisabled ||
                    $scope.isDisabledByStatus
                    // || formInstance.isAlreadyResolved()
                  )
                }
              },
              hideExpression: function ($viewValue, $modelValue, scope) {
                return (
                  $scope.context === 'session' ||
                  $scope.model.showInWorkflow !== true
                )
              }
            }
          ]

          if ($scope.context === 'session') {
            $scope.detailsFields.push(
              // WORKORDER ACCOUNT NAME
              {
                template: `<div layout="row" layout-align="start center" style="margin: 0 0 3rem 0;">
                              <span translate="WO.CUSTOMER.ACCOUNT_NAME" class="workorder-title"></span>
                              <div>${htmlWork.htmlEncode(
                                $scope.accountName
                              )}</div>
                            </div>`,
                hideExpression: function ($viewValue, $modelValue, scope) {
                  return (
                    !$scope.sessionId ||
                    $scope.sessionId === 'constant' ||
                    !$scope.accountName ||
                    $scope.accountName === ''
                  )
                }
              }
            )

            $scope.detailsFields.push(
              // WORKORDER ORDER NUMBER
              {
                template: `<div layout="row" layout-align="start center" style="margin: 0 0 3rem 0;">
                              <span translate="WO.SALE_ORDER" class="workorder-title"></span>
                              <div>${htmlWork.htmlEncode(
                                $scope.orderNumber
                              )}</div>
                            </div>`,
                hideExpression: function ($viewValue, $modelValue, scope) {
                  return (
                    !$scope.sessionId ||
                    $scope.sessionId === 'constant' ||
                    !$scope.orderNumber ||
                    $scope.orderNumber === ''
                  )
                }
              }
            )
          }

          if (
            $scope.model?.requireDeviceLink ||
            $scope.form.requireDeviceLink
          ) {
            $scope.displayStockDetails = true
            $scope.stockDetailsFields = [
              // STOCK SKU
              {
                key: 'stockSku',
                type: 'autocomplete',
                className: 'form-stock-location',
                defaultValue: $scope.sessionData?.partSku
                  ? $scope.sessionData.partSku
                  : null,
                templateOptions: {
                  searchMinLength: 0,
                  label: $translate.instant('FORM.STOCK_SKU'),
                  disabled:
                    !$scope.canCreate ||
                    $scope.isDisabled ||
                    $scope.isDisabledByStatus,
                  showVision: true,
                  queryMethod: PartAssembly.find,
                  mapObject: {
                    id: 'number',
                    name: 'number'
                  },
                  baseFilterObject: {
                    where: {},
                    fields: { number: true }
                  },
                  onChange: function (value, options) {
                    $scope.formExtras[$scope.unitId].formReady = true
                    $scope.disableSerialField = true
                    formInstance.createDisplayFieldsObject()
                  },
                  onInit: async function (model, options) {
                    if ($scope.context !== 'session') {
                      $scope.model.stockSerialOrLot = null
                    }
                    await updateStockFieldsDetails(model.stockSku)
                  },
                  onBlur: async function (model, options) {
                    const oldSku = $scope.part?.number || null
                    await updateStockFieldsDetails(model.stockSku, oldSku)
                  },
                  onScan: async function (model, options) {
                    const oldSku = $scope.part?.number || null
                    await updateStockFieldsDetails(model.stockSku, oldSku)
                  }
                },
                expressionProperties: {
                  'templateOptions.disabled': (
                    $viewValue,
                    $modelValue,
                    scope
                  ) => {
                    return (
                      !$scope.canCreate ||
                      $scope.isDisabled ||
                      $scope.isDisabledByStatus
                    )
                  }
                }
              },
              // STOCK DESC
              {
                key: 'stockDesc',
                type: 'input',
                className: 'form-stock-location',
                templateOptions: {
                  label: $translate.instant('FORM.STOCK_DESC'),
                  disabled: true
                }
              },
              // STOCK SERIAL
              {
                key: 'stockSerialOrLot',
                type: 'autocomplete',
                className: 'form-stock-location',
                defaultValue: $scope.sessionData?.isSerial
                  ? $scope.sessionData?.serial
                    ? $scope.sessionData?.serial
                    : null
                  : $scope.sessionData?.workorderNumber
                  ? $scope.sessionData?.workorderNumber
                  : null,
                templateOptions: {
                  searchMinLength: 0,
                  label:
                    $scope.isPartSerial || false
                      ? $translate.instant('FORM.STOCK_SERIAL')
                      : $translate.instant('FORM.STOCK_LOT_NUMBER'),
                  showVision: true,
                  uniqueValues: true,
                  noCache: true,
                  queryMethod: Stock.find,
                  mapObject: $scope.sessionData?.isSerial
                    ? {
                        id: 'serial',
                        name: 'serial'
                      }
                    : {
                        id: 'workorderNumber',
                        name: 'workorderNumber'
                      },
                  baseFilterObject: $scope.sessionData?.isSerial
                    ? {
                        where: {
                          and: [
                            {
                              sku: $scope.model?.stockSku
                                ? $scope.model.stockSku
                                : null
                            },
                            { isSerial: true },
                            { serial: { neq: null } },
                            { serial: { neq: '' } }
                          ]
                        },
                        fields: { serial: true }
                      }
                    : {
                        where: {
                          and: [
                            {
                              sku: $scope.model?.stockSku
                                ? $scope.model.stockSku
                                : null
                            },
                            { isSerial: false },
                            { workorderNumber: { neq: null } },
                            { workorderNumber: { neq: '' } }
                          ]
                        },
                        fields: { workorderNumber: true }
                      },
                  onChange: function (value, options) {
                    $scope.formExtras[$scope.unitId].formReady = true
                    formInstance.createDisplayFieldsObject()
                  }
                },
                expressionProperties: {
                  'templateOptions.disabled': function (
                    $viewValue,
                    $modelValue,
                    scope
                  ) {
                    return (
                      !$scope.canCreate ||
                      $scope.isDisabled ||
                      $scope.isDisabledByStatus ||
                      $scope.disableSerialField
                    )
                  }
                },
                hideExpression: function ($viewValue, $modelValue, scope) {
                  return $scope.context !== 'session'
                }
              }
            ]
            // nova specific code
            let partCFields = {}
            if ($scope.form.id === '0bb78110-e6a2-11ee-86dc-13022a646eca') {
              partCFields = FormlyHelper.getCustomFields(PartAssembly).reduce(
                (acc, field) => {
                  acc[field.key] = true
                  return acc
                },
                {}
              )
            }
            const updateStockFieldsDetails =
              async function updateStockFieldsDetails (sku, oldSku = null) {
                try {
                  if (sku) {
                    $scope.part = await PartAssembly.findOne({
                      filter: {
                        where: { number: sku },
                        fields: {
                          number: true,
                          isSerial: true,
                          name: true,
                          ...partCFields
                        }
                      }
                    }).$promise
                  } else {
                    $scope.part = null
                  }
                } catch (err) {
                  $scope.part = null
                  $scope.disableSerialField = false
                  FormlyHelper.runExpression({
                    fields: $scope.stockDetailsFields
                  })
                  console.error('updateStockFieldsDetails error', err)
                }

                if ($scope.part) {
                  if ($scope.part.name) {
                    $scope.model.stockDesc = $scope.part.name
                  } else {
                    $scope.model.stockDesc = null
                  }
                  // nova specific code
                  if (
                    $scope.form.id === '0bb78110-e6a2-11ee-86dc-13022a646eca'
                  ) {
                    $scope.model['1253e1fa-5a34-47c9-8995-2b41114d0adc'] =
                      $scope.part.C_supplier || null
                    $scope.model['862de608-0c64-41b2-acbe-55ad658162ac'] =
                      $scope.part.C_vendors_buyer || null
                  }
                } else {
                  $scope.model.stockDesc = null
                  // nova specific code
                  if (
                    $scope.form.id === '0bb78110-e6a2-11ee-86dc-13022a646eca'
                  ) {
                    $scope.model['1253e1fa-5a34-47c9-8995-2b41114d0adc'] = null
                    $scope.model['862de608-0c64-41b2-acbe-55ad658162ac'] = null
                  }
                }

                if ($scope.context === 'session') {
                  if ($scope.formDataId) {
                    if (!oldSku) {
                      if ($scope.model.stockSerial || $scope.model.stockLot) {
                        $scope.isPartSerial = !!$scope.model.stockSerial
                      } else {
                        $scope.isPartSerial = $scope.part?.isSerial || false
                      }
                    }
                  } else {
                    $scope.isPartSerial = $scope.part?.isSerial || false
                  }

                  $scope.model.isPartSerial = $scope.isPartSerial
                  if ($scope.isPartSerial) {
                    $scope.stockDetailsFields[2].templateOptions.label =
                      $translate.instant('FORM.STOCK_SERIAL')
                    $scope.stockDetailsFields[2].templateOptions.mapObject = {
                      id: 'serial',
                      name: 'serial'
                    }
                    $scope.stockDetailsFields[2].templateOptions.baseFilterObject =
                      {
                        where: {
                          and: [
                            { sku },
                            { isSerial: true },
                            { serial: { neq: null } },
                            { serial: { neq: '' } }
                          ]
                        },
                        fields: { serial: true }
                      }
                  } else {
                    $scope.stockDetailsFields[2].templateOptions.label =
                      $translate.instant('FORM.STOCK_LOT_NUMBER')
                    $scope.stockDetailsFields[2].templateOptions.mapObject = {
                      id: 'workorderNumber',
                      name: 'workorderNumber'
                    }
                    $scope.stockDetailsFields[2].templateOptions.baseFilterObject =
                      {
                        where: {
                          and: [
                            { sku },
                            { isSerial: false },
                            { workorderNumber: { neq: null } },
                            { workorderNumber: { neq: '' } }
                          ]
                        },
                        fields: { workorderNumber: true }
                      }
                  }
                  $scope.disableSerialField = false
                  FormlyHelper.runExpression({
                    fields: $scope.stockDetailsFields
                  })
                }
              }
          }
          updateFormSearchField()
        }

        const formlyFields = await FormUtils.convertFieldsFromDBToFormly(
          formFields,
          $scope.fields,
          formInstance,
          $scope.formDataId === null
        )
        // formlyFields.map(field => {
        //   if (field.type === 'upload' && field.templateOptions?.runFileParser) {
        //     fileParserFields.push(field)
        //   }
        //   return field
        // })
        $scope.formFields = addOptionsForFields(formlyFields)
      }
      const updateFormSearchField = function updateFormSearchField () {
        const ignoreForms = []
        if ($scope.model.linkedToForms) {
          ignoreForms.push(...$scope.model.linkedToForms.map(form => form.id))
        }
        if ($scope.model.linkedFromForms) {
          ignoreForms.push(...$scope.model.linkedFromForms.map(form => form.id))
        }

        if ($scope.formDataId) {
          ignoreForms.push($scope.formDataId)
        }
        $scope.formDataSearchModel = {}
        $scope.formDataSearchFields = [
          {
            key: 'formDataSearch',
            type: 'modelSelect',
            templateOptions: {
              deleted: false,
              label: $translate.instant('FORM.LINKED_FORMS.SEARCH_FORM_NUMBER'),
              findMethod: FormData.find,
              mapObject: {
                id: 'id',
                name: 'stringNumber'
              },
              baseFilterObject: {
                where: {
                  id: { nin: ignoreForms }
                }
              },
              templateOption: function (option) {
                if (option) {
                  const formData = option.originalModel
                    ? option.originalModel
                    : option
                  const form = formData?.formId
                    ? $scope.formsById[formData.formId]
                    : null
                  const icon = form?.icon ? form.icon : null
                  const formName = form?.name ? form.name : null
                  let html = `
                    <div layout="row">
                    </div>
                  `
                  if (icon) {
                    html = `
                      <md-icon
                      md-font-icon="${htmlWork.htmlEncode(icon)}"
                      class="s30 form-icon"
                      >
                          <md-tooltip
                          md-direction="top"
                          md-z-index="99"
                          md-autohide="true"
                          >
                            ${htmlWork.htmlEncode(formName)}
                        </md-tooltip>
                      </md-icon>
                    `
                  }
                  html += `<span>#${htmlWork.htmlEncode(
                    formData.stringNumber
                  )}</span>`
                  return html
                }
              },
              onChange: async function (value, options) {
                if (value) {
                  $scope.addLinkedForm(value)
                  $scope.addFormLink = false
                  $scope.formDataSearchModel = {}
                }
              }
            }
          }
        ]
      }

      const initForm = async function initForm () {
        $scope.sessionDetails = formInstance.getSessionDetails() || {}
        $scope.commentModel = FormData.comments
        if ($scope.formDataId) {
          $scope.comments = await FormData.comments({
            id: $scope.formDataId
          }).$promise
        } else {
          $scope.comments = []
        }
        await createFormlyFields()
        if (!$scope.form.type || $scope.form.type !== 'WorkorderStatus') {
          $scope.model = formInstance.manipulateModel($rootScope.currentUser)
          formInstance.createDisplayFieldsObject()
          await getLinkedFormsData()
          $scope.linkedFormsById = _.keyBy($scope.model.relatedForms, 'id')
          if (!$scope.sessionData) {
            $scope.sessionData = {}
          }
          if ($scope.formDataId) {
            formIsValid = true
            if ($scope.hasStatus) {
              const statusObject = statuses.find(
                stat => stat.name === $scope.model.status
              )
              $scope.model.oldStatus = statusObject ? statusObject.status : null
              if (
                statusObject &&
                statusObject.status === 'close' &&
                !$scope.canClose
              ) {
                $scope.isDisabled = true
              }
            }

            sessionDataKeys.forEach(key => {
              $scope.sessionData[key] = !_.isNil($scope.model[key])
                ? $scope.model[key]
                : null
            })

            // const historyIndex = $scope.formFields.length - 1
            if ($scope.model && $scope.model.hasLogs) {
              const changeLogIgnoreList = ['modified']
              $scope.changeLog = _.cloneDeep($scope.model.changeLog).filter(
                log =>
                  !changeLogIgnoreList.includes(log.type) &&
                  !changeLogIgnoreList.includes(log.fieldId)
              )
              $scope.changeLogFormDataFields = _.cloneDeep(formFields)

              // $scope.formFields[historyIndex].fieldGroup[0].fieldGroup = []
              // $scope.formFields[historyIndex].fieldGroup[0].fieldGroup.push({
              //   controller: /** @ngInject */ function ($scope) {
              //     $scope.changeLog = _.cloneDeep($scope.model.changeLog)
              //     $scope.formDataFields = _.cloneDeep(formFields)
              //     $scope.users = users
              //     $scope.fields = fields
              //   },
              //   template: `<form-history  change-log="changeLog"
              //                             users="users"
              //                             fields="fields"
              //                             form-fields="formDataFields"></form-history>`
              // })
            }
          }
          fillApprovalSection()
          updateFormReadOnly()
        }
        $scope.$apply()
        if (typeof $scope.onLoad === 'function') {
          $timeout(async () => {
            await splashScreenGone()
            $scope.onLoad()
          }, 0)
        }
        if (
          $scope.form.id === FAILURE_FORM_DETAILS.FAILURE_REPORT_ID &&
          $scope.formFields.length === 4
        ) {
          $scope.formExtras[$scope.unitId].formReady = true
        }
        if (formInstance.isNewInstance === false)
          IntercomService.trackEvent('form-view')
      }
      const updateFormReadOnly = function updateFormReadOnly () {
        $scope.isDisabledByStatus = formInstance.checkFormReadOnly()
        if ($scope.isDisabledByStatus) {
          // Show toast message telling the user that the form is read only
          $mdToast.show(
            $mdToast.nextplus({
              position: $rootScope.toastLocation,
              theme: 'info-toast',
              hideDelay: 1000 * 30
            })
          )
          $mdToast.updateTextContent(
            $translate.instant('FORM.ERROR.formReadOnly', {
              status: $scope.model.status
            })
          )
          FormlyHelper.runExpression({ fields: $scope.formFields })
        }
      }

      const fillApprovalSection = function fillApprovalSection () {
        $scope.approvalsFields = []
        if ($scope.formDataId && $scope.model.approvalWorkflow) {
          $scope.localObject.tabIndex = $scope.showApproval ? 1 : 0
          $scope.hasApprovalWorkflow = true
          $scope.formExtras[$scope.unitId].approvalWorkflow = true
          const approvalWorkflowDeclaration = formInstance.getApprovalWorkflow()
          const {
            approvalWorkflow: { status }
          } = $scope.model
          const { readonly, closeFormWhenDone } = approvalWorkflowDeclaration
          if (readonly) {
            $scope.statusDisabled = closeFormWhenDone
              ? true
              : status === 'PENDING'
            $scope.isDisabled = true
            FormlyHelper.runExpression({ fields: $scope.formFields })
          }
          const users = $scope.users
          const model = $scope.model
          const approvalWorkflow = $scope.model.approvalWorkflow
          $scope.approvalsFields = [
            {
              controller: /** @ngInject */ function ($scope) {
                $scope.users = users
                $scope.formData = model
                $scope.approvalWorkflow = approvalWorkflow
                $scope.approvalWorkflowDeclaration = approvalWorkflowDeclaration
              },
              template:
                '<form-approval form-data="formData" users="users" approval-workflow="approvalWorkflow" approval-workflow-declaration="approvalWorkflowDeclaration"></form-approval>'
            }
          ]
        } else {
          $scope.localObject.tabIndex = 0
          $scope.formExtras[$scope.unitId].approvalWorkflow = false
          $scope.hasApprovalWorkflow = false
          $scope.approvalsFields = []
        }
      }

      $scope.restartApprovalProcess = async function restartApprovalProcess () {
        const formData = await FormData.restartApprovalProcess({
          formDataId: $scope.formDataId
        }).$promise

        await $scope.formExtras[$scope.unitId].initDirective(formData)
        if ($scope.formExtras[$scope.unitId].initReviewPage) {
          $scope.formExtras[$scope.unitId].initReviewPage($scope.unitId)
        }
      }

      const performSave = function performSave (showToast = true) {
        return new Promise(async (resolve, reject) => {
          $rootScope.loadingProgress = true
          try {
            const formDataObject = formInstance.convertModelToFormDataObject(
              $scope.model
            )
            const isDraft = formDataObject.isDraft
            if (isDraft) {
              formDataObject.isDraft = false
            }
            const isNew = _.isNil($scope.formDataId) || isDraft
            if ($scope.runPreSaveTriggers) {
              try {
                const preSaveResults = await FormData.executeTriggers({
                  formData: {
                    ...formDataObject,
                    ...formInstance.getVariablesForCondition()
                  },
                  originalModel: formInstance.originalModel || {},
                  type: 'presave',
                  isNew: isNew || false,
                  submitForApproval:
                    $scope.formExtras.submitForApproval || false
                }).$promise
                if (preSaveResults.errors.length) {
                  FormUtils.errorHandler(preSaveResults.errors)
                }
                if (preSaveResults.actionsResults.length) {
                  await FormUtils.handlePreSaveActions(
                    preSaveResults.actionsResults
                  )
                }
              } catch (err) {
                // trigger validation error
                console.error('validation error', err)
                const error = new Error('validation error')
                error.code = 'FORM.CUSTOM_VALIDATION_ERROR'
                error.message = 'validation error'
                $rootScope.loadingProgress = false
                $scope.$applyAsync()
                return reject(error)
              }
            }
            if ($scope.formDataId) {
              // EDIT
              await FormData.prototype$patchAttributes(
                { id: $scope.formDataId },
                formDataObject
              ).$promise
            } else {
              // CREATE
              const formData = await FormData.create(formDataObject).$promise
              $scope.formDataId = formData.id
            }
            $rootScope.loadingProgress = false
            try {
              if (isNew) {
                for (const field of $scope.formFields) {
                  if (field.type === 'upload' || field.type === 'button') {
                    if (field.templateOptions.runFileParser === true) {
                      if (field.templateOptions.parserInstantRun === false) {
                        await field.formControl.$$scope.parseFile()
                      }
                    }
                  }
                }
              }
            } catch (err) {
              return reject(err)
            }
            try {
              const obj = {
                id: $scope.formDataId,
                ...formDataObject,
                ...formInstance.getVariablesForCondition()
              }
              if (
                obj.submittedForApproval ||
                $scope.formExtras.submitForApproval
              ) {
                obj.submittedForApproval = true
              }
              const postSaveResults = await FormData.executeTriggers({
                formData: obj,
                originalModel: formInstance.originalModel || {},
                type: 'postsave',
                isNew: isNew || false,
                submitForApproval: $scope.formExtras.submitForApproval || false
              }).$promise
              if (postSaveResults.errors.length) {
                FormUtils.errorHandler(postSaveResults.errors)
              }
              formInstance.afterPostSaveTriggers(formDataObject, isNew)
            } catch (err) {
              console.error('post trigger failed', err)
            }
            if (showToast) {
              $mdToast.show(
                $mdToast.nextplus({
                  position: $rootScope.toastLocation,
                  parent: 'document.body',
                  theme: 'success-toast',
                  hideDelay: 2000
                })
              )
              $mdToast.updateTextContent(
                $translate.instant('FORM.SUCCESS.FORM_SAVED')
              )
            }
            resolve($scope.formDataId)
          } catch (err) {
            if (err.data.error.code === 'partNotExists') {
              console.error('partNotExists', err)
              const { partNotExists } = err.data.error.details.codes
              $rootScope.loadingProgress = false
              reject(
                $translate.instant('FORM.ERROR.partNotExists', {
                  partNumber: partNotExists
                })
              )
            } else if (err.data.error.code === 'serialNotFound') {
              console.error('serialNotFound', err)
              const { serialNotFound, sku } = err.data.error.details.codes
              const res = await serialErrorDialog(serialNotFound, sku)
              if (!res.error) {
                $rootScope.loadingProgress = false
                resolve()
              } else {
                if (res.errorData) {
                  reject(res.errorData)
                } else {
                  const message = $translate.instant(
                    'FORM.ERROR.serialNotFound.ERROR',
                    {
                      sku,
                      serialNotFound
                    }
                  )
                  $rootScope.loadingProgress = false
                  reject(message)
                }
              }
            } else if (
              [
                'serialNumberRequired',
                'changeSerialToLot',
                'changeLotToSerial',
                'skuIsRequired'
              ].includes(err.data.error.code)
            ) {
              $rootScope.loadingProgress = false
              reject($translate.instant(`FORM.ERROR.${err.data.error.code}`))
            } else if (err.data.error.code === 'formReadOnly') {
              reject(
                $translate.instant(
                  `FORM.ERROR.${err.data.error.code}`,
                  err.data.error.details.codes
                )
              )
              $rootScope.loadingProgress = false
            } else {
              reject(err)
              $rootScope.loadingProgress = false
              $mdToast.show(errorMdToast)
              $mdToast.updateTextContent(err)
            }
          }
        })
      }
      const getLinkedFormsData = async function getLinkedFormsData () {
        if ($scope.parentLinkedForm?.id) {
          $scope.model.linkedFrom = [$scope.parentLinkedForm.id]
          const formData = await FormData.findOne({
            filter: {
              where: { id: $scope.parentLinkedForm.id }
            }
          }).$promise
          $scope.model.linkedFromForms = [formData]
        }
        if ($scope.model?.linkedToForms) {
          $scope.model.linkedToForms.forEach(linkedForm => {
            $scope.statusTemplateByFormId[linkedForm.id] =
              FormUtils.generateStatusTemplate(linkedForm, linkedForm.status)
          })
        }
        if ($scope.model?.linkedFromForms) {
          $scope.model.linkedFromForms.forEach(linkedForm => {
            $scope.statusTemplateByFormId[linkedForm.id] =
              FormUtils.generateStatusTemplate(linkedForm, linkedForm.status)
          })
        }
      }
      $scope.openLinkFormHandler = function openLinkFormHandler (isOpen) {
        $scope.addFormLink = isOpen
      }
      $scope.removeLinkedForm = async function removeLinkedForm (
        unlinkFromParent,
        linkedFormDataId
      ) {
        if ($scope.edit) {
          await FormData.unlinkFormData({
            formDataId: $scope.formDataId,
            linkedFormDataId,
            unlinkFromParent
          }).$promise
        }
        if (unlinkFromParent) {
          $scope.model.linkedTo = $scope.model.linkedTo.filter(
            formId => formId !== linkedFormDataId
          )
          $scope.model.linkedToForms = $scope.model.linkedToForms.filter(
            form => form.id !== linkedFormDataId
          )
        } else {
          $scope.model.linkedFrom = $scope.model.linkedFrom.filter(
            formId => formId !== linkedFormDataId
          )
          $scope.model.linkedFromForms = $scope.model.linkedFromForms.filter(
            form => form.id !== linkedFormDataId
          )
        }
        delete $scope.statusTemplateByFormId[linkedFormDataId]
        updateFormSearchField()
      }
      $scope.addLinkedForm = async function addLinkedForm (linkedFormId) {
        const formData = await FormData.findOne({
          filter: {
            where: { id: linkedFormId }
          }
        }).$promise

        if ($scope.edit) {
          await FormData.linkFormData({
            formDataId: $scope.formDataId,
            linkedFormDataId: linkedFormId
          }).$promise
        }
        $scope.model.linkedTo.push(linkedFormId)
        $scope.model.linkedToForms.push(formData)
        $scope.statusTemplateByFormId[linkedFormId] =
          FormUtils.generateStatusTemplate(formData, formData.status)
        updateFormSearchField()
        if (!$scope.edit) {
          $scope.formExtras[$scope.unitId].formReady = true
        }
      }
      $scope.createNewFormToLink = async function createNewFormToLink (form) {
        return new Promise(async resolve => {
          $mdDialog
            .show({
              multiple: true,
              resolve: {
                lazyLoad: $ocLazyLoad => {
                  return import(
                    /* webpackChunkName: "form.module" */ '../../modules/main/form/form.module.js'
                  )
                    .then(mod => {
                      return $ocLazyLoad.inject(mod.default)
                    })
                    .catch(err => {
                      throw new Error('Ooops, something went wrong, ' + err)
                    })
                },
                sessionData: () => null,
                isDisabled: () => false,
                ResolvedData: (Field, $rootScope, MultiTranslateService) => {
                  return new Promise(async resolve => {
                    const formObject = form ? _.cloneDeep(form) : {}
                    formObject.fieldSettings = formObject?.fieldSettings
                      ? JSON.parse(formObject.fieldSettings)
                      : {}
                    formObject.fields = formObject.fields.filter(
                      field => !field.unused
                    )
                    const fieldIds = formObject.fields.map(
                      field => field.fieldId
                    )
                    let fields = await Field.getFieldsWithInnerFields({
                      fieldIds
                    }).$promise
                    fields = fields.map(field =>
                      MultiTranslateService.getForView(
                        Field,
                        $rootScope.currentLang,
                        field
                      )
                    )
                    resolve({
                      formDatas: [],
                      users: [],
                      form: formObject,
                      fields,
                      workflows: []
                    })
                  })
                },
                ResolvedForms: Form =>
                  Form.find({
                    filter: {
                      where: {
                        isTemplate: false
                      }
                    }
                  }).$promise,
                parentLinkedForm: () => $scope.model || null
              },
              fullscreen: true,
              controller: FormReviewController,
              template: require('../../modules/main/workflow/show/dialogs/workflow.form-review.template.html'),
              parent: angular.element(document.body),
              targetEvent: '',
              clickOutsideToClose: false
            })
            .then(async formDatas => {
              await $scope.addLinkedForm(formDatas[0].id, true)
              $scope.addFormLink = false
            })
        })
      }
      const serialErrorDialog = async function serialErrorDialog (serial, sku) {
        return $mdDialog
          .show({
            controller: ($scope, locals, $mdDialog) => {
              $scope.serial = locals.serial
              $scope.sku = locals.sku
              $scope.header = $translate.instant(
                'FORM.ERROR.serialNotFound.DIALOG.TITLE'
              )
              $scope.message = $translate.instant(
                'FORM.ERROR.serialNotFound.DIALOG.MESSAGE',
                { serialNotFound: $scope.serial, sku: $scope.sku }
              )
              $scope.saveFunction = () => $mdDialog.hide(true)
              $scope.cancelFunction = () => $mdDialog.hide(false)
            },
            template: `<md-dialog style="height: 270px; width: 500px;">
                              <md-toolbar>
                                <div class="md-toolbar-tools">
                                  <h2>{{header}}</h2>
                                </div>
                              </md-toolbar>
                              <md-dialog-content style="height: 100%;">
                                <div>
                                  <h3>{{message}}</h3>
                                </div>
                              </md-dialog-content>
                              <md-dialog-actions>
                                <md-button translate="WF.YES" ng-click="saveFunction()" class="md-raised md-accent md-primary">
                                </md-button>
                                <md-button translate="WF.NO" ng-click="cancelFunction()" class="md-raised md-warn md-primary">
                                </md-button>
                              </md-dialog-actions>
                          </md-dialog>`,
            parent: angular.element(document.body),
            targetEvent: '',
            locals: {
              serial,
              sku
            },
            multiple: true,
            fullscreen: true,
            escapeToClose: false,
            clickOutsideToClose: false
          })
          .then(async res => {
            if (res) {
              return Stock.createApprovedStocks({
                stocksToCreate: [
                  {
                    serial,
                    sku,
                    isSerial: $scope.isPartSerial,
                    quantity: 1
                  }
                ]
              })
                .$promise.then(async () => {
                  $scope.runPreSaveTriggers = false
                  await $scope.formExtras[$scope.unitId].save(
                    $scope.formExtras.submitForApproval
                  ).$promise
                  console.log('serialErrorDialog success', res)
                  return res
                })
                .catch(err => {
                  console.error('serialErrorDialog error', err)
                  return {
                    error: true,
                    errorData: err
                  }
                })
            } else {
              return {
                error: true,
                errorData: null
              }
            }
          })
          .catch(async err => {
            console.error('setSessionSerial error', err)
            return {
              error: true,
              errorData: err
            }
          })
      }
      if (!$scope.form.type || $scope.form.type !== 'WorkorderStatus') {
        if (!$scope.formExtras[$scope.unitId]) {
          $scope.formExtras[$scope.unitId] = {
            formReady: false,
            lockedSessions: false
          }
        }

        $scope.formExtras[$scope.unitId].runDetailsFieldsExpression = () => {
          FormlyHelper.runExpression({ fields: $scope.detailsFields })
        }

        $scope.formExtras[$scope.unitId].hasApprovalWorkflows =
          formInstance.hasApprovalWorkflows()

        $scope.formExtras[$scope.unitId].getFormObject = () =>
          formInstance.convertModelToFormDataObject($scope.model)

        $scope.formExtras[$scope.unitId].initDirective = async (
          formData = null,
          users = null
        ) => {
          if (!formData) {
            const res = await FormData.findInstance({
              where: { id: $scope.formDataId }
            }).$promise
            formData = res.formData
            $scope.users = res.users
          }
          if (users) {
            $scope.users = users
          }
          formInstance.setModel(formData)
          $scope.model = formData
          await initForm()
          $scope.$applyAsync()
        }

        $scope.formExtras[$scope.unitId].currentFields = () =>
          $scope.currentFields

        $scope.formExtras[$scope.unitId].checkFormsValidation =
          function checkFormsValidation (extraParams = {}) {
            const { isValid, errorMessages } =
              formInstance.checkFormDataValidation($scope.model, formIsValid)
            if (!isValid) {
              const errorMessage = errorMessages
                .map(errMsg => {
                  const { message, params } = errMsg
                  return $translate.instant(message, {
                    ...extraParams,
                    ...params
                  })
                })
                .join(', ')
              $mdToast.show(errorMdToast)
              $mdToast.updateTextContent(errorMessage)
            }
            return isValid
          }

        $scope.formExtras[$scope.unitId].checkValidation =
          function checkValidation (extraParams = {}) {
            const forms = Object.values($scope.forms)
            const { isValid, errorMessages } = formInstance.checkValidation(
              $scope.model,
              forms
            )

            if (!isValid) {
              PanelHelper.openPanel({
                controller: FormValidationDialogController,
                template: require('./dialog/form-validation-dialog.template.html'),
                zIndex: 90,
                locals: {
                  errors: errorMessages.map(errMsg => {
                    const { message, params, errorType, fieldName } = errMsg
                    return {
                      message,
                      errorType,
                      fieldName,
                      params: { ...extraParams, ...params }
                    }
                  })
                },
                escapeToClose: true,
                clickOutsideToClose: true
              })
                .then(() => {})
                .catch(err => {}) //eslint-disable-line
            }
            return isValid
          }

        $scope.formExtras[$scope.unitId].save = performSave

        $scope.formExtras[$scope.unitId].isFormValid = () => formIsValid

        $scope.formExtras[$scope.unitId].setFormValid = boolean => {
          formIsValid = boolean
        }
      }

      if (
        $scope.formOptions &&
        $scope.detailsFormOptions &&
        $scope.stockDetailsFormOptions
      ) {
        $scope.$watch(
          'isDisabled',
          () => {
            $scope.formOptions.formState.disabled =
              !$scope.canCreate ||
              $scope.isDisabled ||
              $scope.isDisabledByStatus
            $scope.detailsFormOptions.formState.disabled =
              !$scope.canCreate ||
              $scope.isDisabled ||
              $scope.isDisabledByStatus
            $scope.stockDetailsFormOptions.formState.disabled =
              !$scope.canCreate ||
              $scope.isDisabled ||
              $scope.isDisabledByStatus
          },
          true
        )
      }

      initForm()
    }
  }
}

module.exports = customForm
