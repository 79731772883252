/* global _ angular */

const debug = require('debug')('nextplus:part-units')
require('./part-units.scss')
/** @ngInject */
function PartUnitsController (
  $scope,
  $translate,
  $rootScope,
  $mdDialog,
  Page,
  Unit,
  KendoGridHelper,
  ViewsService,
  PermissionUtils
) {
  $scope.addEditUnit = function addEditUnit (unitId) {
    $mdDialog
      .show({
        controller: require('./part-unit-show.controller'),
        template: require('./part-unit-show.template.html'),
        parent: angular.element(document.body),
        targetEvent: '',
        resolve: {
          ResolvedUnit: Unit => {
            if (unitId) {
              return Unit.findOne({ filter: { where: { id: unitId } } })
                .$promise
            }
            return null
          }
        },
        multiple: true,
        fullscreen: true,
        clickOutsideToClose: false,
        escapeToClose: false,
        focusOnOpen: true
      })
      .then(
        function () {
          $scope.kendoGrid.reloadData()
        },
        function () {}
      )
  }

  $scope.removeUnit = async function removeUnit (unitId) {
    $rootScope.loadingProgress = true
    $mdDialog
      .show(
        $mdDialog
          .confirm()
          .title($translate.instant('PartAssembly.UNITS.DELETE_MESSAGE'))
          .targetEvent()
          .clickOutsideToClose(true)
          .parent(angular.element(document.body))
          .ok($translate.instant('BUTTONS.DELETE'))
          .cancel($translate.instant('BUTTONS.CANCEL'))
      )
      .then(
        function () {
          Unit.destroyById({ id: unitId })
            .$promise.then(() => {
              $rootScope.loadingProgress = false
              debug(`Unit '${unitId}' deleted successfully`)
              $scope.kendoGrid.reloadData()
            })
            .catch(err => {
              console.error(err)
              $rootScope.loadingProgress = false
              $rootScope.showErrorToast('NP-8300')
              debug(
                `An error occurred while trying to delete a unit '${unitId}'`,
                err
              )
            })
        },
        function () {
          $rootScope.loadingProgress = false
        }
      )
  }

  const initScreen = async function initScreen () {
    $scope.title = $rootScope.title = $translate.instant(
      'PartAssembly.UNITS.TITLE'
    )
    Page.setTitleText($scope.title)
    $scope.PermissionUtils = PermissionUtils

    const hasDeletePermissions = PermissionUtils.isPermit('Unit', 'deleteById')
    const hasEditPermissions = PermissionUtils.isPermit(
      'Unit',
      'patchAttributes'
    )

    const tableColumns = [
      // NAME
      {
        uniqueId: 'c7d7c08b-b14e-482f-b4f4-bcb6f5e239f1',
        field: 'name',
        translateCode: 'PartAssembly.UNITS.NAME'
        // media: '(min-width: 768px)',
      },
      // EACH
      {
        uniqueId: '7cf85cae-c221-446a-aab0-5d5625cf7a6f',
        field: 'each',
        translateCode: 'PartAssembly.UNITS.EACH',
        type: 'boolean',
        filterable: true
        // media: '(min-width: 768px)',
      },
      // ACTIONS
      {
        uniqueId: '4d974d9e-d760-4770-a48e-95352e22e72b',
        field: 'id',
        translateCode: 'COMMON.ACTIONS',
        headerAttributes: {
          class: 'actions-column-header'
        },
        // media: '(min-width: 768px)',
        filterable: false,
        sortable: false,
        trustedTemplate: data => {
          let html = ''
          if (hasEditPermissions) {
            html += `<div layout="row" layout-align="start center">
            <md-button ng-class="($root.isTabletOrMobile) ? ['md-fab','md-mini','md-primary'] : ['md-icon-button']" ng-click="addEditUnit('${data.id}')" data-testid="edit-${data.id}" ng-disabled="$root.loadingProgress">
              <md-icon md-font-icon="icon-pencil" class="s16" ng-style="{'padding-top': $root.isTabletOrMobile ? 'auto' : '4px'}"></md-icon>
            </md-button>`
          }

          if (hasDeletePermissions) {
            html += `<md-button ng-class="($root.isTabletOrMobile) ? ['md-fab','md-mini'] : ['md-icon-button']" class="md-warn" ng-click="removeUnit('${data.id}')" data-testid="delete-${data.id}" ng-disabled="$root.loadingProgress">
                        <md-icon md-font-icon="icon-delete" class="s16"></md-icon>
                    </md-button>`
          }
          html += '</div>'
          return html
        }
      }
    ]

    const basicFields = {
      id: true,
      name: true,
      each: true,
      modified: true
    }

    const { defaultTableColumns, customFieldsDisplay } =
      ViewsService.generateCustomFieldColumns('Unit', tableColumns)

    const baseFilter = {
      order: 'modified DESC',
      fields: _.merge(basicFields, customFieldsDisplay)
    }

    const { columns, selectedViewId } =
      ViewsService.getTablesColumns(defaultTableColumns)

    const { newBaseFilter, filters } = ViewsService.getViewCustomFilters(
      selectedViewId,
      _.cloneDeep(baseFilter)
    )

    const defaultTableSetup = {
      // stateName
      // ignoreParams
      find: Unit.find,
      count: Unit.count,
      cleanBaseFilter: baseFilter,
      baseFilter: newBaseFilter,
      selectedViewId,
      columns: defaultTableColumns
    }

    const defaultTableToolbarSetup = {
      // stateName
      columns: defaultTableColumns,
      currentColumnIds: columns.map(c => c.uniqueId),
      filters,
      selectedViewId,
      title: $scope.title
    }

    $scope.kendoGrid = await KendoGridHelper.GridInstance(
      defaultTableSetup,
      $scope,
      columns
    )

    await $scope.kendoGrid.isBound()

    $scope.tableToolbar = await ViewsService.GridToolBarInstance(
      defaultTableToolbarSetup,
      $scope.kendoGrid,
      $scope
    )

    $scope.$applyAsync()
  }

  initScreen()

  $scope.headerOptions = {
    icon: 'icon-tape-measure',
    template: require('app/templates/headers/simple.html'),
    title: $scope.title,
    fabButton: {
      template: require('app/templates/headers/fab-button.html'),
      action: $scope.addEditUnit,
      icon: 'icon-plus',
      href: '',
      showExpression: () => $scope.PermissionUtils.isPermit('Unit', 'create')
    }
  }
}

module.exports = { PartUnitsController }
