/* eslint-disable */
/** @ngInject */
function PartLeafleat() {
  let options = {}
  let map
  let bounds
  // map and image size
  let getLeafleatSize = () => ({
    w: document.getElementById(options.mapContainer).clientWidth || 900,
    h: document.getElementById(options.mapContainer).clientHeight || 600
  })
  let getMinimapSize = () => ({
    w: document.getElementById(options.miniMapContainer).clientWidth,
    h: document.getElementById(options.miniMapContainer).clientHeight
  })

  let fixZoomLevels = () => {
    if (map) {
      map.options.minZoom = -9999999999999
      map.options.maxZoom = 9999999999999
      map.once('zoomend', function (e) {
        map.options.minZoom = map.getZoom() - 0.5
        map.options.maxZoom = map.options.minZoom + 5
        map.options.zoomSnap =
          (map.options.maxZoom - map.options.minZoom) / 5 - 1
      })
      map.fitBounds(bounds)
    }
  }

  let resizeLeafLeat = (w, h) => {
    $('#' + options.mapContainer).width(w)
    $('#' + options.mapContainer).height(h)
    if (map) map.invalidateSize({ debounceMoveend: false })
    fixZoomLevels()
  }

  let waitForElement = id => {
    return new Promise((resolve, reject) => {
      const startTime = Date.now()
      const timeout = 5000 // 5 seconds timeout

      const checkElement = () => {
        const element = document.getElementById(id)
        if (element) {
          resolve(element)
        } else if (Date.now() - startTime >= timeout) {
          reject(
            new Error(
              `Timeout: Element with id "${id}" not found after ${timeout}ms`
            )
          )
        } else {
          setTimeout(checkElement, 250)
        }
      }

      checkElement()
    })
  }

  let buildMap = opt =>
    new Promise(async (resolve, reject) => {
      const containerId = 'map'
      options.minimap = !!opt.minimap
      options.zoomControl =
        opt.zoomControl === undefined ? true : opt.zoomControl
      options.img = opt.img
      options.mapContainer = opt.mapContainer
      options.miniMapContainer = opt.miniMapContainer
      options.miniMapOptions = opt.miniMapOptions
      await waitForElement(containerId)
      if (map) map.remove()

      map = L.map(containerId, {
        minZoom: 0,
        zoomSnap: 0.01,
        zoomDelta: 1,
        maxZoom: 18,
        attributionControl: false,
        center: [0, 0],
        zoom: 0,
        zoomControl: options.zoomControl,
        crs: L.CRS.Simple
      })

      let originalImage = {
        w: options.img.width,
        h: options.img.height,
        url: options.img.src
      }

      let southWest = map.unproject([0, originalImage.h], 0)
      let northEast = map.unproject([originalImage.w, 0], 0)

      bounds = new L.LatLngBounds(southWest, northEast)

      let overlay = L.imageOverlay(originalImage.url, bounds).addTo(map)
      map.doubleClickZoom.disable()
      map.whenReady(() => {
        console.log('when ready in factory')
        map.setMaxBounds(bounds)
        let leafletSize = getLeafleatSize()
        console.log('leafletSize', leafletSize)
        resizeLeafLeat(leafletSize.w, leafletSize.h)
      })
      resolve({ map, overlay })
    })

  let convertToImageCoords = ({ blat, blng }) => {
    let lat = Math.round((options.img.height * blat) / bounds._southWest.lat)
    let lng = Math.round((options.img.width * blng) / bounds._northEast.lng)
    return { lat, lng }
  }

  let convertToLeafleatCoords = ({ blat, blng }) => {
    let lat = Math.round((bounds._southWest.lat * blat) / options.img.height)
    let lng = Math.round((bounds._northEast.lng * blng) / options.img.width)
    return { lat, lng }
  }

  let createReactangle = (number, coords, parentLayer, style, events) => {
    coords = coords.map(lll => {
      return lll.map(ll => {
        let { lat, lng } = convertToLeafleatCoords({
          blat: ll.lat,
          blng: ll.lng
        })
        return { lat, lng }
      })
    })
    let shape = L.rectangle(coords, style)
    shape.number = number
    events.map(event => shape.on(event.name, event.fn))
    shape.layerType = 'rectangle'
    shape.addTo(parentLayer)
  }

  let createCircle = (number, coords, radius, parentLayer, style, events) => {
    let tmp = _.cloneDeep(style)
    tmp.radius = radius
    let { lat, lng } = convertToLeafleatCoords({
      blat: coords[0],
      blng: coords[1]
    })
    let shape = L.circle([lat, lng], tmp)
    shape.number = number
    shape.layerType = 'circle'
    events.map(event => shape.on(event.name, event.fn))
    shape.addTo(parentLayer)
  }

  let createPolygon = (number, coords, parentLayer, style, events) => {
    coords = coords.map(lll => {
      return lll.map(ll => {
        let { lat, lng } = convertToLeafleatCoords({
          blat: ll.lat,
          blng: ll.lng
        })
        return { lat, lng }
      })
    })
    let shape = L.polygon(coords, style)
    shape.number = number
    shape.layerType = 'polygon'
    events.map(event => shape.on(event.name, event.fn))
    shape.addTo(parentLayer)
  }

  let createMarker = (
    number,
    coords,
    markerData,
    parentLayer,
    style,
    events,
    inlineStyle
  ) => {
    let { lat, lng } = convertToLeafleatCoords({
      blat: coords[0],
      blng: coords[1]
    })
    if (!inlineStyle) {
      inlineStyle = ''
    }
    let myIcon = L.divIcon({
      popupAnchor: [10, 20],
      iconSize: ['fit-content', 'fit-content'],
      type: 'dom',
      html: `<div number="${number}" style='${inlineStyle}' class="minimap-marker ${markerData.icon}" id="${markerData.id}">${markerData.name}</div>`
    })
    let shape = L.marker([lat, lng], { icon: myIcon })
    shape.number = number
    shape.layerType = 'marker'
    events.map(event => shape.on(event.name, event.fn))
    shape.addTo(parentLayer)
  }

  let removeLayerControl = layerControl =>
    new Promise((resolve, reject) => {
      if (layerControl) {
        map.removeControl(layerControl)
      }
      resolve()
    })

  let createLayerControl = (baseMaps, overlayMaps) =>
    new Promise((resolve, reject) => {
      let control = L.control.layers(baseMaps, overlayMaps).addTo(map)
      resolve(control)
    })

  const createGenericCircle = function createGenericCircle(
    layerId,
    coords,
    radius,
    style,
    events
  ) {
    const tempCircle = _.cloneDeep(style)
    tempCircle.radius = radius
    let { lat, lng } = convertToLeafleatCoords({
      blat: coords[0],
      blng: coords[1]
    })
    const shape = L.circle([lat, lng], tempCircle)
    shape.layerId = layerId
    shape.layerType = 'circle'
    events.map(event => shape.on(event.name, event.fn))
    return shape
  }

  const createGenericMarker = function createGenericMarker(
    layerId,
    coords,
    markerData,
    events,
    inlineStyle
  ) {
    let { lat, lng } = convertToLeafleatCoords({
      blat: coords[0],
      blng: coords[1]
    })
    if (!inlineStyle) {
      inlineStyle = ''
    }
    const icon = L.divIcon({
      popupAnchor: [10, 20],
      iconSize: ['fit-content', 'fit-content'],
      type: 'dom',
      html: `<div layer-id="${layerId}" style="${inlineStyle}" class="minimap-marker ${markerData.icon}" id="${markerData.id}">${markerData.name}</div>`
    })
    const shape = L.marker([lat, lng], { icon })
    shape.layerId = layerId
    shape.layerType = 'marker'
    events.map(event => shape.on(event.name, event.fn))
    return shape
  }

  const createGenericReactangle = function createGenericReactangle(
    layerId,
    coords,
    style,
    events
  ) {
    coords = coords.map(lll => {
      return lll.map(ll => {
        const { lat, lng } = convertToLeafleatCoords({
          blat: ll.lat,
          blng: ll.lng
        })
        return { lat, lng }
      })
    })
    const shape = L.rectangle(coords, style)
    shape.layerId = layerId
    events.map(event => shape.on(event.name, event.fn))
    shape.layerType = 'rectangle'
    return shape
  }

  const createGenericPolygon = function createGenericPolygon(
    layerId,
    coords,
    style,
    events
  ) {
    coords = coords.map(lll => {
      return lll.map(ll => {
        let { lat, lng } = convertToLeafleatCoords({
          blat: ll.lat,
          blng: ll.lng
        })
        return { lat, lng }
      })
    })
    const shape = L.polygon(coords, style)
    shape.layerId = layerId
    shape.layerType = 'polygon'
    events.map(event => shape.on(event.name, event.fn))
    return shape
  }

  return {
    buildMap,
    // updateMiniMap,
    resizeLeafLeat,
    removeLayerControl,
    createLayerControl,
    createReactangle,
    createCircle,
    createGenericCircle,
    createPolygon,
    createMarker,
    createGenericMarker,
    convertToImageCoords,
    createGenericPolygon,
    createGenericReactangle,
    convertToLeafleatCoords
  }
}

module.exports = PartLeafleat
