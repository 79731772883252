/* global _ */
const UUID = require('uuid')
const timezones = require('./time-zones.json')
require('./workorder-template.scss')
/** @ngInject */
function WorkorderTemplateController (
  $scope,
  $rootScope,
  $translate,
  $mdToast,
  Workflow,
  VariablesUtilsService,
  PartAssembly,
  KendoTreeViewHelper,
  WorkflowType,
  FormlyHelper,
  Workorder
) {
  const vm = this

  $scope.part = null

  const frequency = ['Daily', 'Weekly', 'Monthly', 'Yearly']

  const validKeys = [
    'id',
    'number',
    'name',
    'quantity',
    'optional',
    'mrpDefault',
    'isSerial',
    'bulkProduction',
    'unit'
  ]

  const types = [
    {
      value: 'sms',
      name: $translate.instant('WO.TEMPLATE.NOTIFICATIONS.SMS'),
      notAnOption: !$rootScope.appSettings.hasSMS
    },
    {
      value: 'whatsapp',
      name: $translate.instant('WO.TEMPLATE.NOTIFICATIONS.WHATSAPP'),
      notAnOption: !$rootScope.appSettings.hasSMS
    },
    {
      value: 'email',
      name: $translate.instant('WO.TEMPLATE.NOTIFICATIONS.EMAIL')
    }
  ]

  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ]

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  const stopMethods = [
    { value: 'never', name: $translate.instant('WO.TEMPLATE.NEVER') },
    {
      value: 'quantity',
      name: $translate.instant('WO.TEMPLATE.AFTER_X_TIMES')
    },
    { value: 'date', name: $translate.instant('WO.TEMPLATE.ON_DATE') }
  ]

  const getCustomFields = function getCustomFields () {
    return FormlyHelper.getCustomFields(Workorder)
  }

  const getVariables = function getVariables (isTitle = false) {
    return VariablesUtilsService.getMergedTagsVariables('Workorder', isTitle)
  }

  const findChildren = function findChildren (items = []) {
    const res = []
    if (items.length) {
      for (let i = 0; i < items.length; i++) {
        const relevantItem = items[i]
        const object = { ..._.pick(relevantItem, validKeys), uniqueId: UUID() }
        const innerItems = findChildren(relevantItem.nodes)
        if (innerItems.length) object.items = innerItems
        res.push({ ...object, isRoot: false, expanded: true })
      }
    }
    return res
  }

  const fixData = function fixData (d) {
    const innerItems = findChildren(d.nodes)
    return [
      {
        ..._.pick(d, validKeys),
        isRoot: true,
        items: innerItems,
        expanded: true
      }
    ]
  }

  const convertProductTreeToSkuArray = function convertProductTreeToSkuArray (
    items = [],
    root = false
  ) {
    let SKUs = new Set()
    for (let i = 0; i < items.length; i++) {
      const item = items[i]
      if (!root) {
        SKUs.add(item.number)
      }
      if (item.hasChildren) {
        SKUs = new Set([...SKUs, ...convertProductTreeToSkuArray(item.items)])
      }
    }
    return [...SKUs]
  }

  const manipulateWorkordersTree = function manipulateWorkordersTree (
    items = [],
    parentObject,
    parentMrpDefault = true
  ) {
    const res = []
    const { isRoot, parentQuantity } = parentObject
    for (let i = 0; i < items.length; i++) {
      const relevantItem = items[i]
      const object = _.pick(relevantItem, [...validKeys, 'uniqueId'])
      object.hasWorkflow = false
      object.checkboxDisabled = true
      object.quantity = object.quantity * parentQuantity * vm.model.quantity
      object.isSerial = relevantItem.isSerial
      object.bulkProduction = getBulkProduction(relevantItem)
      if (isRoot) {
        object.hasWorkflow = true
        object.checked = true
        object.quantity = vm.model.quantity
      } else if ($scope.workflowBySKU[object.number]) {
        object.hasWorkflow = true
        object.recordId = $scope.workflowBySKU[object.number].recordId
        object.checkboxDisabled = false
        object.checked =
          parentMrpDefault === false ? false : relevantItem.mrpDefault
      }
      const newQuantity = parseInt(relevantItem.quantity)
      const innerItems = manipulateWorkordersTree(
        relevantItem.items,
        {
          isRoot: false,
          parentQuantity: isNaN(newQuantity) ? 1 : newQuantity
        },
        relevantItem.mrpDefault
      )
      if (innerItems.length) object.items = innerItems
      res.push({ ...object, isRoot, expanded: true })
    }
    return res
  }

  const getBulkProduction = function getBulkProduction (part) {
    let bulkProduction = false
    if (part) {
      if (part.bulkProduction === 'BY_UNIT') {
        if (part.unit) {
          bulkProduction = !part.unit.each
        }
      } else if (part.bulkProduction === 'YES') {
        bulkProduction = true
      }
    }
    return bulkProduction
  }
  const convertWorkorderTreeToDB = function convertWorkorderTreeToDB (
    items = [],
    parentId = null
  ) {
    const res = []
    for (let i = 0; i < items.length; i++) {
      let nextParentId = parentId
      const item = items[i]
      const object = {
        ..._.pick(item, [...validKeys, 'recordId', 'uniqueId'])
      }
      if (item.isRoot) {
        object.recordId = vm.model.recordId
      }
      if (parentId) {
        object.parentId = parentId
      }
      if (
        !item.isRoot &&
        item.hasWorkflow &&
        item.checked &&
        item.quantity > 0
      ) {
        nextParentId = object.uniqueId
        res.push(object)
      }
      if (item.hasChildren) {
        res.push(...convertWorkorderTreeToDB(item.items, nextParentId))
      }
    }
    return res
  }

  const createConfigurations = function createConfigurations (
    uniqueId,
    items = [],
    configurationObject = {}
  ) {
    for (let i = 0; i < items.length; i++) {
      const item = items[i]
      if (!configurationObject[uniqueId]) {
        configurationObject[uniqueId] = []
      }
      const { id, number } = item
      configurationObject[uniqueId].push({ id, number })
      if (item.hasChildren) {
        createConfigurations(item.uniqueId, item.items, configurationObject)
      }
    }
  }

  $scope.finishWizard = async function finishWizard (msWizard) {
    let extraWorkorders = []
    const configurations = {}
    if ($scope.workordersTree) {
      extraWorkorders = convertWorkorderTreeToDB(
        $scope.workordersTree.getData()
      )
    }
    let mainProduct = null
    if ($scope.productTree) {
      const productTreeConfiguration = $scope.productTree.getData()
      mainProduct = productTreeConfiguration[0]
      createConfigurations('MAIN', mainProduct.items, configurations)
    }
    if (typeof vm.saveEvent === 'function') {
      const mainWorkorderModel = _.cloneDeep($scope.vm.model)
      if (!mainProduct && $scope.mainPart) {
        mainProduct = $scope.mainPart
      }
      mainWorkorderModel.isSerial = mainProduct ? mainProduct.isSerial : false
      mainWorkorderModel.bulkProduction = mainProduct
        ? getBulkProduction(mainProduct)
        : false
      // Add custom fields to the mainWorkorderModel
      const customFields = getCustomFields()
      customFields.forEach(field => {
        mainWorkorderModel[field.key] = vm.model[field.key]
      })

      const allWorkorders = [mainWorkorderModel, ...extraWorkorders]
      let showError = false
      allWorkorders.forEach(workorder => {
        if (
          workorder.quantity > 1000 &&
          (!workorder.bulkProduction ||
            (workorder.bulkProduction && workorder.isSerial))
        ) {
          showError = true
        }
      })
      if (showError) {
        console.error('Workorder units quantity limit is 1000')
        $mdToast.show(
          $mdToast.nextplus({
            position: $rootScope.toastLocation,
            parent: 'body',
            theme: 'error-toast',
            hideDelay: 3000
          })
        )
        $mdToast.updateTextContent(
          $translate.instant('WO.TEMPLATE.ERROR.QUANTITY_LIMIT')
        )
      } else {
        vm.saveEvent(configurations, extraWorkorders)
      }
    }
  }

  $scope.goToStep = async function goToStep (index) {
    if (index === 2) {
      // SUGGESTIONS
      const productTreeData = $scope.productTree.getData()
      const SKUs = convertProductTreeToSkuArray(productTreeData, true)
      try {
        $scope.loadingSuggestions = true
        if (!$scope.workordersTree) {
          if (
            !$scope.workflowBySKU ||
            Object.keys($scope.workflowBySKU).length === 0
          ) {
            $scope.workflowBySKU = await Workflow.findWorkflowsBySKUs({
              SKUs,
              type: vm.model.workorderTypeId
            }).$promise
          } else {
            $scope.workflowBySKU = {}
          }
          const data = manipulateWorkordersTree(
            JSON.parse(JSON.stringify(productTreeData)),
            { isRoot: true, parentQuantity: 1 }
          )
          $scope.workordersTree = KendoTreeViewHelper.treeViewInstance()
          $scope.workordersTree.setup(
            {
              dragAndDrop: false,
              dataSource: data
            },
            $scope
          )
        } else {
          const data = manipulateWorkordersTree(
            JSON.parse(JSON.stringify(productTreeData)),
            { isRoot: true, parentQuantity: 1 }
          )
          $scope.workordersTree.instance.dataSource.data(data)
        }
        $scope.$applyAsync()
      } catch (err) {
        $rootScope.showErrorToast('NP-1100')
      } finally {
        $scope.loadingSuggestions = false
      }
    }
  }

  $scope.nextStep = async function nextStep (msWizard) {
    try {
      msWizard.nextStep()
      const idx = msWizard.selectedIndex
      if (idx === 2) {
        $scope.goToStep(2)
      }
      $scope.$applyAsync()
    } catch (err) {
      console.error(err)
    }
  }

  $scope.productTreeRemoveItem = function productTreeRemoveItem (e, id) {
    const kItem = e.currentTarget.closest('.k-item')
    const div = e.currentTarget.closest('div')
    if (div.getAttribute('data-id') && div.getAttribute('data-id') === id) {
      $scope.productTree.instance.remove(kItem)
    }
    $scope.$applyAsync()
  }

  $scope.workorderTreeRemoveItem = function workorderTreeRemoveItem (e, id) {
    const kItem = e.currentTarget.closest('.k-item')
    const div = e.currentTarget.closest('div')
    if (div.getAttribute('data-id') && div.getAttribute('data-id') === id) {
      $scope.workordersTree.instance.remove(kItem)
    }
    $scope.$applyAsync()
  }

  this.$onInit = async () => {
    let groups = []
    if (
      $rootScope.currentUser &&
      $rootScope.currentUser.groups &&
      $rootScope.currentUser.groups.length > 0
    ) {
      groups = vm.groups.filter(
        group => $rootScope.currentUser.groups.indexOf(group.id) !== -1
      )
      if (!vm.model.groups || vm.model.groups.length === 0) {
        vm.model.groups = $rootScope.currentUser.groups
      }
    } else {
      groups = vm.groups
    }

    $scope.wizardForms = {}

    $scope.hasProductTree = false

    $scope.forceEndWizard = false

    $scope.mainPart = null

    const createProductTree = async function createProductTree (sku) {
      $scope.loadingTree = true
      $scope.forceEndWizard = false
      if ($scope.createToStock && sku) {
        try {
          const res = await PartAssembly.getProductTree({
            number: sku
          }).$promise
          if (res) {
            $scope.mainPart = res
          }
          if (res && res.nodes.length > 0) {
            $scope.hasProductTree = true
            $scope.productTree = KendoTreeViewHelper.treeViewInstance()
            $scope.productTree.setup(
              {
                dragAndDrop: false,
                dataSource: fixData(res)
              },
              $scope
            )
          } else {
            $scope.forceEndWizard = true
            $scope.hasProductTree = false
            $scope.productTree = null
          }
        } catch (err) {
          console.error(err)
        } finally {
          $scope.loadingTree = false
          $scope.$applyAsync()
        }
      } else {
        $scope.hasProductTree = false
        $scope.productTree = null
        $scope.loadingTree = false
        $scope.forceEndWizard = true
      }
    }

    if (vm.defaultWorkorderType && !vm.model.workorderTypeId) {
      vm.model.workorderTypeId = vm.defaultWorkorderType.id
    }
    let addIndex = 0
    if (vm.hasWorkorderNumber) {
      addIndex = 1
    }
    $scope.workorderTemplateFields = [
      ...(vm.hasWorkorderNumber
        ? [
            {
              className: 'display-flex layout-xs-column layout-md-row',
              fieldGroup: [
                {
                  key: 'customWorkorderNumber',
                  type: 'checkbox',
                  templateOptions: {
                    onChange: function (value, options) {
                      if (!value) {
                        vm.model.workorderNumber = null
                      }
                    }
                  }
                },
                // WORKORDER NUMBER
                {
                  key: 'workorderNumber',
                  type: 'input',
                  className: 'flex',
                  templateOptions: {
                    label: $translate.instant('WO.workorderNumber'),
                    placeholder: '#####',
                    className: 'flex forceDirLTR'
                  },
                  expressionProperties: {
                    'templateOptions.disabled': (
                      $viewValue,
                      $modelValue,
                      scope
                    ) => {
                      return !scope.model.customWorkorderNumber
                    },
                    'templateOptions.required': (
                      $viewValue,
                      $modelValue,
                      scope
                    ) => {
                      return scope.model.customWorkorderNumber
                    }
                  },
                  // Add validation, not start with NXT-
                  validators: {
                    notNxt: {
                      expression: function (value) {
                        return !value.startsWith('NXT-')
                      },
                      message: () => "Workorder number cannot start with 'NXT-'"
                    }
                  }
                }
              ]
            }
          ]
        : []),
      {
        key: 'name',
        type: 'input',
        className: 'flex',
        templateOptions: {
          type: 'text',
          required: !vm.immediately,
          label: vm.immediately
            ? $translate.instant('WO.workorderName')
            : $translate.instant('WO.TEMPLATE.NAME'),
          onChange: function (value, options) {}
        }
      },
      {
        key: 'workorderTypeId',
        type: 'modelSelect',
        templateOptions: {
          required: true,
          label: $translate.instant('WO.TEMPLATE.WORKORDER_TYPE'),
          findMethod: WorkflowType.find,
          mapObject: { id: 'id', name: 'name' },
          baseFilterObject: {
            where: {},
            fields: {
              id: true,
              name: true,
              createStock: true
            }
          },
          onInit: function (selectedOption, options, model) {
            if (selectedOption && selectedOption.originalModel.createStock) {
              $scope.createToStock = true
              if (vm.immediately && !$scope.hasProductTree && model.sku) {
                createProductTree(model.sku)
              }
            }
          },
          onChange: function (value, options, model, selectedOption) {
            if (selectedOption && selectedOption.originalModel.createStock) {
              $scope.createToStock = true
            } else {
              $scope.createToStock = false
            }
            vm.model.recordId = null
          }
        }
      },
      {
        key: 'sku',
        type: 'modelSelect',
        className: 'flex',
        templateOptions: {
          required: true,
          label: $translate.instant('WO.TEMPLATE.ASSOCIATED_PRODUCT'),
          options: [],
          findMethod: PartAssembly.find,
          mapObject: { id: 'number', name: 'number', displayName: 'name' },
          baseFilterObject: {
            where: {},
            fields: {
              id: true,
              number: true,
              name: true,
              isSerial: true,
              generateSerials: true,
              revisions: true,
              currentRevision: true,
              managedByRevision: true
            }
          },
          onInit: async function (selectedOption, options, model) {
            if (selectedOption && selectedOption.originalModel) {
              $scope.part = selectedOption.originalModel
              if ($scope.part.managedByRevision) {
                vm.model.validateRev = true
                $scope.workorderTemplateFields[
                  3 + addIndex
                ].templateOptions.required = true
              } else {
                vm.model.validateRev = false
                $scope.workorderTemplateFields[
                  3 + addIndex
                ].templateOptions.required = false
              }
            } else {
              $scope.part = null
            }
            FormlyHelper.runExpression({
              fields: $scope.workorderTemplateFields
            })
          },
          onChange: async function (value, options, model, selectedOption) {
            vm.model.recordId = null
            vm.model.workflowName = ''
            vm.model.rev = null
            if (selectedOption && selectedOption.originalModel) {
              $scope.part = selectedOption.originalModel
              if ($scope.part.managedByRevision) {
                vm.model.rev = $scope.part.currentRevision
                vm.model.validateRev = true
                $scope.workorderTemplateFields[
                  3 + addIndex
                ].templateOptions.required = true
              } else {
                vm.model.rev = null
                vm.model.validateRev = false
                $scope.workorderTemplateFields[
                  3 + addIndex
                ].templateOptions.required = false
              }
            } else {
              $scope.part = null
            }
            FormlyHelper.runExpression({
              fields: $scope.workorderTemplateFields
            })
          }
        },
        hideExpression: function ($viewValue, $modelValue, scope) {
          return !scope.model.workorderTypeId
        }
      },
      {
        key: 'rev',
        type: 'selectWithSearch',
        className: 'flex',
        templateOptions: {
          label: $translate.instant('WO.TEMPLATE.REVISION'),
          required: true,
          options: [],
          onChange: async function (value, options) {
            vm.model.recordId = null
            vm.model.workflowName = ''
          }
        },
        expressionProperties: {
          'templateOptions.options': async ($viewValue, $modelValue, scope) => {
            if (
              $scope.part?.managedByRevision &&
              $scope.part?.revisions?.length > 0
            ) {
              return $scope.part.revisions.map(rev => ({
                id: rev.revision,
                name: rev.revision
              }))
            }
            return []
          }
        },
        hideExpression: function ($viewValue, $modelValue, scope) {
          return (
            !scope.model.sku || !$scope.part || !$scope.part.managedByRevision
          )
        }
      },
      {
        key: 'recordId',
        type: 'modelSelect',
        templateOptions: {
          required: !$rootScope.appSettings.workorderAllowDefaultWorkflow,
          label: $translate.instant('WO.TEMPLATE.ASSOCIATED_WORKFLOW'),
          findMethod: Workflow.find,
          mapObject: { id: 'recordId', name: 'name' },
          baseFilterObject: () => {
            const where = {
              currentReleasedVersion: true,
              workflowTypeIds: { inq: vm.model.workorderTypeId },
              parts: {
                elemMatch: {
                  sku: vm.model.sku,
                  rev: vm.model.rev || null
                }
              }
            }
            return {
              where,
              fields: {
                id: true,
                recordId: true,
                name: true
              }
            }
          },
          onChange: async function (value, options, model, selectedOption) {
            vm.model.workflowName = selectedOption.name || ''
            if (vm.immediately) {
              if ($scope.productTree) {
                $scope.productTree = null
              }
              await createProductTree(vm.model.sku)
            }
          }
        },

        hideExpression: function ($viewValue, $modelValue, scope) {
          return (
            !scope.model.workorderTypeId ||
            !scope.model.sku ||
            !$scope.part ||
            ($scope.part.managedByRevision && !scope.model.rev)
          )
        }
      },
      {
        key: 'generateSerials',
        type: 'checkbox',
        templateOptions: {
          label: $translate.instant('WO.TEMPLATE.GENERATE_SERIALS')
        },
        expressionProperties: {
          'templateOptions.disabled': ($viewValue, $modelValue, scope) => {
            if ($scope.part) {
              if ($scope.part.isSerial && $scope.part.generateSerials) {
                return false
              } else {
                scope.model.generateSerials = false
                return true
              }
            } else {
              return true
            }
          }
        }
      },
      {
        key: 'quantity',
        type: 'input',
        className: 'flex',
        templateOptions: {
          required: true,
          min: 1,
          type: 'number',
          label: $translate.instant('WO.TEMPLATE.QUANTITY')
        }
      },
      {
        key: 'assignedUsers',
        type: 'selectUser',
        className: 'flex',
        templateOptions: {
          multiple: true,
          showConstants: false,
          label: $translate.instant('WO.TEMPLATE.ASSIGNEES'),
          onChange: function (value, options) {}
        }
      },
      {
        key: 'dueDateInHours',
        type: 'input',
        className: 'flex',
        templateOptions: {
          required: true,
          type: 'number',
          label: $translate.instant('WO.TEMPLATE.DUE_DATE_IN_HOURS')
        }
      },
      {
        key: 'releasedBefore',
        type: 'input',
        className: 'flex',
        templateOptions: {
          required: true,
          type: 'number',
          label: $translate.instant('WO.TEMPLATE.RELEASED_BEFORE')
        },
        hideExpression: function ($viewValue, $modelValue, scope) {
          return vm.immediately
        }
      },
      {
        key: 'groups',
        type: 'selectWithSearch',
        className: 'layout-column',
        templateOptions: {
          label: $translate.instant('WO.TEMPLATE.GROUPS'),
          options: groups,
          multiple: true
        }
      }
    ]

    if (this.hasSchedule) {
      $scope.workorderTemplateFields.push({
        key: 'scheduling',
        type: 'repeatingTemplate',
        className: 'scheduling-repeating-template',
        templateOptions: {
          layout: 'column',
          title: $translate.instant('WO.TEMPLATE.SCHEDULES'),
          btnText: $translate.instant('WO.TEMPLATE.BUTTON.ADD'),
          hideMovementButtons: true,
          addParameters: () => ({
            id: UUID(),
            every: 1,
            day: 1,
            month: 1,
            quantity: 100,
            stopMethod: 'quantity',
            date: new Date(),
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
          }),
          onChange: function () {},
          fields: [
            {
              key: 'frequency',
              type: 'segmentedButtons',
              className: 'flex',
              templateOptions: {
                multiple: false,
                options: frequency.map(freq => ({
                  id: freq,
                  name: $translate.instant(
                    `WO.TEMPLATE.SCHEDULE_OPTIONS.${freq}`
                  )
                })),
                label: $translate.instant('WO.TEMPLATE.FREQUENCY')
              }
            },
            {
              className: 'display-flex layout-row',
              fieldGroup: [
                {
                  className: 'margin-5 padding-top-25',
                  template: '<span translate="WO.TEMPLATE.ON"></span>'
                },
                {
                  key: 'day',
                  type: 'input',
                  className: 'width-150 margin-5',
                  templateOptions: {
                    type: 'number',
                    required: true,
                    min: 1,
                    max: 31,
                    label: $translate.instant('WO.TEMPLATE.DAY_IN_MONTH'),
                    onChange: function (value, options) {}
                  }
                },
                {
                  key: 'month',
                  type: 'select',
                  className: 'width-250 margin-5',
                  templateOptions: {
                    required: true,
                    options: months.map((month, idx) => ({
                      value: idx,
                      name: $translate.instant(`WO.TEMPLATE.MONTHS.${month}`)
                    })),
                    label: $translate.instant('WO.TEMPLATE.MONTH')
                  }
                }
              ],
              hideExpression: function ($viewValue, $modelValue, scope) {
                return scope.model.frequency !== 'Yearly'
              }
            },
            {
              className: 'display-flex layout-row',
              fieldGroup: [
                {
                  className: 'margin-5 padding-top-25',
                  template: '<span translate="WO.TEMPLATE.RECUR_ON_DAY"></span>'
                },
                {
                  key: 'day',
                  type: 'input',
                  className: 'width-150 margin-5',
                  templateOptions: {
                    type: 'number',
                    required: true,
                    min: 1,
                    max: 31,
                    label: $translate.instant('WO.TEMPLATE.DAY'),
                    onChange: function (value, options) {}
                  }
                },
                {
                  className: 'margin-5 padding-top-25',
                  template: '<span translate="WO.TEMPLATE.OF_EVERY"></span>'
                },
                {
                  key: 'every',
                  type: 'input',
                  className: 'width-150 margin-5',
                  templateOptions: {
                    type: 'number',
                    required: true,
                    min: 1,
                    label: $translate.instant('WO.TEMPLATE.OF_EVERY')
                  }
                },
                {
                  className: 'margin-5 padding-top-25',
                  template: '<span translate="WO.TEMPLATE.MONTH_S"></span>.'
                }
              ],
              hideExpression: function ($viewValue, $modelValue, scope) {
                return scope.model.frequency !== 'Monthly'
              }
            },
            {
              className: 'display-flex layout-row',
              fieldGroup: [
                {
                  className: 'margin-5 padding-top-25',
                  template: '<span translate="WO.TEMPLATE.RECUR_EVERY"></span>'
                },
                {
                  key: 'every',
                  type: 'input',
                  className: 'width-150 margin-5',
                  templateOptions: {
                    type: 'number',
                    required: true,
                    min: 1,
                    label: $translate.instant('WO.TEMPLATE.EVERY')
                  }
                },
                {
                  className: 'margin-5 padding-top-25',
                  template: '<span translate="WO.TEMPLATE.WEEK_S"></span>.'
                }
              ],
              hideExpression: function ($viewValue, $modelValue, scope) {
                return scope.model.frequency !== 'Weekly'
              }
            },
            {
              className: 'display-flex layout-row',
              fieldGroup: [
                {
                  className: 'margin-5 padding-top-25',
                  template: '<span translate="WO.TEMPLATE.RECUR_EVERY"></span>'
                },
                {
                  key: 'every',
                  type: 'input',
                  className: 'width-150 margin-5',
                  templateOptions: {
                    type: 'number',
                    required: true,
                    label: $translate.instant('WO.TEMPLATE.YEAR_S')
                  }
                },
                {
                  className: 'margin-5 padding-top-25',
                  template: '<span translate="WO.TEMPLATE.YEAR_S"></span>.'
                }
              ],
              hideExpression: function ($viewValue, $modelValue, scope) {
                return scope.model.frequency !== 'Yearly'
              }
            },
            {
              key: 'days',
              type: 'segmentedButtons',
              className: 'flex margin-top-10',
              templateOptions: {
                multiple: true,
                options: days.map((day, idx) => ({
                  id: idx,
                  name: $translate.instant(
                    `WO.TEMPLATE.SCHEDULE_OPTIONS.${day}`
                  )
                })),
                label: $translate.instant('WO.TEMPLATE.DAYS')
              },
              hideExpression: function ($viewValue, $modelValue, scope) {
                return scope.model.frequency !== 'Weekly'
              }
            },
            {
              className: 'display-flex layout-row',
              fieldGroup: [
                {
                  className: 'margin-5 padding-top-25',
                  template: '<span translate="WO.TEMPLATE.RECUR_EVERY"></span>'
                },
                {
                  key: 'every',
                  type: 'input',
                  className: 'width-150 margin-5',
                  templateOptions: {
                    type: 'number',
                    required: true,
                    min: 1,
                    label: $translate.instant('WO.TEMPLATE.EVERY')
                  }
                },
                {
                  className: 'margin-5 padding-top-25',
                  template: '<span translate="WO.TEMPLATE.DAY_S"></span>.'
                }
              ],
              hideExpression: function ($viewValue, $modelValue, scope) {
                return scope.model.frequency !== 'Daily'
              }
            },
            {
              className: 'display-flex layout-row',
              fieldGroup: [
                {
                  key: 'time',
                  type: 'timePicker',
                  className: 'flex',
                  templateOptions: {
                    required: true,
                    label: $translate.instant('WO.TEMPLATE.TIME'),
                    onChange: function (value, options) {}
                  }
                },
                {
                  key: 'timezone',
                  type: 'selectWithSearch',
                  className: 'flex time-zone-select',
                  templateOptions: {
                    required: true,
                    options: timezones.map(timezone => ({
                      id: timezone,
                      name: timezone
                    })),
                    label: $translate.instant('WO.TEMPLATE.TIME_ZONE'),
                    onChange: function (value, options) {}
                  }
                }
              ]
            },
            {
              template: `
                    <div layout="row" layout-align="start center">
                      <md-icon md-font-icon="icon-alert-outline" class="s20" style="color: #f44336; margin: 0rem 1rem;"></md-icon>
                      <h3><span translate="WO.TEMPLATE.REPEAT_MESSAGE"></span></h3>
                    </div>`,
              hideExpression: function ($viewValue, $modelValue, scope) {
                return (
                  !scope.model.stopMethod || scope.model.stopMethod !== 'never'
                )
              }
            },
            {
              key: 'stopMethod',
              type: 'radioMaterial',
              className: 'layout-column',
              templateOptions: {
                layout: 'column',
                required: false,
                label: $translate.instant(
                  'WO.TEMPLATE.STOP_CREATING_NEW_TASKS'
                ),
                options: stopMethods
              }
            },
            {
              className: 'display-flex layout-row',
              fieldGroup: [
                {
                  className: 'margin-5 padding-top-25',
                  template: '<span translate="WO.TEMPLATE.AFTER"></span>'
                },
                {
                  key: 'quantity',
                  type: 'input',
                  className: 'width-150 margin-5',
                  templateOptions: {
                    type: 'number',
                    required: true,
                    min: 1,
                    label: $translate.instant('WO.TEMPLATE.QUANTITY')
                  }
                },
                {
                  className: 'margin-5 padding-top-25',
                  template: '<span translate="WO.TEMPLATE.TIMES"></span>.'
                }
              ],
              hideExpression: function ($viewValue, $modelValue, scope) {
                return (
                  !scope.model.stopMethod ||
                  scope.model.stopMethod !== 'quantity'
                )
              }
            },
            {
              className: 'display-flex layout-row',
              fieldGroup: [
                {
                  className: 'margin-5 padding-top-25',
                  template: '<span translate="WO.TEMPLATE.ON"></span>'
                },
                {
                  key: 'date',
                  type: 'datePicker',
                  className: 'width-250 margin-5',
                  templateOptions: {
                    required: true,
                    minDate: new Date(),
                    label: $translate.instant('WO.TEMPLATE.DATE')
                  }
                }
              ],
              hideExpression: function ($viewValue, $modelValue, scope) {
                return (
                  !scope.model.stopMethod || scope.model.stopMethod !== 'date'
                )
              }
            }
          ]
        }
      })
    }

    if (this.hasNotifications) {
      $scope.workorderTemplateFields.push({
        key: 'notifications',
        type: 'repeatingTemplate',
        templateOptions: {
          layout: 'column',
          title: $translate.instant('WO.TEMPLATE.NOTIFICATIONS.TITLE'),
          btnText: $translate.instant('WO.TEMPLATE.BUTTON.ADD'),
          hideMovementButtons: true,
          addParameters: () => ({
            enabled: true,
            to: vm.model.assignedUsers,
            title: $translate.instant(
              'WO.TEMPLATE.NOTIFICATIONS.NOTIF_TITLE_TEMPLATE',
              {
                workorderNumber: '{{workorder.workorderNumber}}'
              }
            ),
            content: $translate.instant(
              'WO.TEMPLATE.NOTIFICATIONS.NOTIF_CONTENT_TEMPLATE',
              {
                workorderNumber: '{{workorder.workorderNumber}}',
                link: '{{workorder.link}}'
              }
            )
          }),
          onChange: function () {},
          fields: [
            {
              key: 'type',
              type: 'radioMaterial',
              className: 'flex',
              templateOptions: {
                options: types,
                required: true,
                label: $translate.instant(
                  'WO.TEMPLATE.NOTIFICATIONS.NOTIFICATION_TYPE'
                )
              }
            },
            {
              key: 'enabled',
              type: 'switch',
              className: 'flex',
              templateOptions: {
                required: true,
                label: $translate.instant('WO.TEMPLATE.NOTIFICATIONS.ENABLED')
              },
              hideExpression: function ($viewValue, $modelValue, scope) {
                return !scope.model.type
              }
            },
            {
              key: 'to',
              type: 'selectUser',
              className: 'margin-5',
              templateOptions: {
                required: true,
                multiple: true,
                label: $translate.instant('WO.TEMPLATE.NOTIFICATIONS.TO'),
                onChange: function (value, options) {}
              },
              expressionProperties: {
                'templateOptions.disabled': (
                  $viewValue,
                  $modelValue,
                  scope
                ) => {
                  return !scope.model.enabled
                }
              },
              hideExpression: function ($viewValue, $modelValue, scope) {
                return !scope.model.type
              }
            },
            {
              key: 'title',
              type: 'variableInput',
              className: 'layout-row',
              templateOptions: {
                showVariables: true,
                variables: () => getVariables(true),
                label: $translate.instant(
                  'WO.TEMPLATE.NOTIFICATIONS.EMAIL_TITLE'
                ),
                onChange: function () {}
              },
              expressionProperties: {
                'templateOptions.disabled': (
                  $viewValue,
                  $modelValue,
                  scope
                ) => {
                  return !scope.model.enabled
                }
              },
              hideExpression: function ($viewValue, $modelValue, scope) {
                return scope.model.type !== 'email'
              }
            },
            {
              key: 'content',
              type: 'inputTextAngular',
              className: 'layout-row',
              templateOptions: {
                showVariables: true,
                variables: () => getVariables(),
                style: 'width:100%',
                taToolbar:
                  "[['fontColor','bold','ul','ol','linkToDocument','indent','outdent','insertLink','clear','justifyLeft', 'justifyCenter', 'justifyRight']]",
                placeholder: $translate.instant(
                  'WO.TEMPLATE.NOTIFICATIONS.CONTENT'
                ),
                onChange: function () {}
              },
              expressionProperties: {
                'templateOptions.disabled': (
                  $viewValue,
                  $modelValue,
                  scope
                ) => {
                  return !scope.model.enabled
                }
              },
              hideExpression: function ($viewValue, $modelValue, scope) {
                return scope.model.type !== 'email'
              }
            },
            {
              key: 'content',
              type: 'inputTextAngular',
              className: 'layout-row',
              templateOptions: {
                showVariables: true,
                variables: () => getVariables(),
                style: 'width:100%',
                taToolbar: '[]',
                placeholder: $translate.instant(
                  'WO.TEMPLATE.NOTIFICATIONS.CONTENT'
                ),
                onChange: function () {}
              },
              expressionProperties: {
                'templateOptions.disabled': (
                  $viewValue,
                  $modelValue,
                  scope
                ) => {
                  return !scope.model.enabled
                }
              },
              hideExpression: function ($viewValue, $modelValue, scope) {
                return scope.model.type !== 'sms'
              }
            }
          ]
        }
      })
    }

    if (typeof vm.saveEvent === 'function') {
      const customFields = getCustomFields()
      $scope.workorderTemplateFields =
        $scope.workorderTemplateFields.concat(customFields)
    }
  }
}

module.exports = WorkorderTemplateController
