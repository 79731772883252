/* global _ */
'use strict'
/** @ngInject */
function uploadController ($scope, $translate, Resource, FileParser) {
  $scope.caption = $scope.to.caption || 'Upload'
  $scope.container = $scope.to.container || null
  $scope.disabled = $scope.to.disabled || false
  $scope.runFileParser = $scope.to.runFileParser || false
  $scope.parserInstantRun = $scope.to.parserInstantRun
  $scope.fileParser = $scope.to.fileParser || null
  $scope.workflowSessionItemId = $scope.to.workflowSessionItemId || null
  $scope.formDataId = $scope.to.formDataId || null
  $scope.keepOriginal = _.isNil($scope.to.keepOriginal)
    ? true
    : $scope.to.keepOriginal
  $scope.uniqueFiles = $scope.to.uniqueFiles || false

  $scope.filePreviewObject = {}
  let modelName = null
  let modelId = null
  if (!_.isNil($scope.workflowSessionItemId)) {
    modelName = 'WorkflowSessionItem'
    modelId = $scope.workflowSessionItemId
  } else if (!_.isNil($scope.formDataId)) {
    modelName = 'FormData'
    modelId = $scope.formDataId
  }
  const initialValue = _.get($scope.model, $scope.options.key)
  $scope.parseFile = async function parseFile () {
    const resourceId = _.get($scope.model, $scope.options.key)
    try {
      // eslint-disable-next-line
      await FileParser.parseFileWithParser({
        resourceId,
        fileParserId: $scope.fileParser,
        modelName,
        modelId
      }).$promise
    } catch (ex) {
      if ($scope.options.validation) {
        $scope.options.validation.errorExistsAndShouldBeVisible = true
        $scope.options.validation.messages = [
          {
            field: $scope.options.key,
            value: ex.data.error.message
          }
        ]
        // $scope.options.formControl.$setValidity('parserError', false)
      }
      _.set($scope.model, $scope.options.key, null)
      if ($scope.to.object) {
        $scope.model[$scope.to.object] = []
      }
      throw ex
    }
  }
  $scope.formlyFileSelected = function formlyFileSelected (results) {
    return new Promise(async (resolve, reject) => {
      try {
        if (results && results.length > 0) {
          if ($scope.to.mode === 'multiple') {
            // Get the current IDs
            const currentIds = _.get($scope.model, $scope.options.key, [])

            // Get the new IDs from the results
            const newIds = results.map(r => r.id)

            // If we already have IDs, we need to maintain their order and append new ones
            if (currentIds && currentIds.length > 0) {
              // Filter out any IDs that are no longer in the results
              const filteredCurrentIds = currentIds.filter(id =>
                newIds.includes(id)
              )

              // Get any new IDs that aren't in the current list
              const addedIds = newIds.filter(id => !currentIds.includes(id))

              // Combine the filtered current IDs with the new IDs
              const combinedIds = [...filteredCurrentIds, ...addedIds]

              // Set the combined IDs to the model
              _.set($scope.model, $scope.options.key, combinedIds)
            } else {
              // If we don't have any current IDs, just set the new ones
              _.set($scope.model, $scope.options.key, newIds)
            }
          } else {
            _.set($scope.model, $scope.options.key, results[0].id)
          }
          if ($scope.runFileParser && initialValue !== results[0].id) {
            $scope.options.validation.errorExistsAndShouldBeVisible = false

            if ($scope.to.parserInstantRun) {
              $scope.parseFile()
            }
          }
        } else {
          _.set($scope.model, $scope.options.key, null)
        }
        if ($scope.to.onFileSelect) {
          $scope.to.onFileSelect(results, $scope.options)
        }

        resolve()
      } catch (ex) {
        console.error(ex)
        reject(ex)
      }
    })
  }

  $scope.$watch('model', async function (newValue, oldValue, scope) {
    if (
      newValue &&
      oldValue &&
      $scope.filePreviewObject[$scope.to.object] !== newValue[$scope.to.object]
    ) {
      if (_.isEmpty(newValue[$scope.options.key])) {
        $scope.filePreviewObject[$scope.to.object] = {}
      } else if (newValue[$scope.to.object]) {
        $scope.filePreviewObject[$scope.to.object] = newValue[$scope.to.object]
      } else {
        const value = Array.isArray(newValue[$scope.options.key])
          ? newValue[$scope.options.key]
          : [newValue[$scope.options.key]]
        $scope.filePreviewObject[$scope.to.object] = await Resource.find({
          filter: { where: { id: { inq: value } } }
        }).$promise
        $scope.$applyAsync()
      }
    }
  })

  $scope.$watch(
    () => $scope.model[$scope.options.key],
    async (newValue, oldValue) => {
      if (!_.isEqual(newValue, oldValue)) {
        if (!newValue || _.isEmpty(newValue)) {
          $scope.filePreviewObject[$scope.to.object] = {}
        } else {
          const value = Array.isArray(newValue) ? newValue : [newValue]

          // Check if we already have these resources in the filePreviewObject
          const currentResources =
            $scope.filePreviewObject[$scope.to.object] || []
          const currentResourceIds = currentResources.map(r => r.id)

          // If we have all the resources and they're just in a different order,
          // we can reorder them without fetching from the server
          if (
            Array.isArray(currentResources) &&
            currentResources.length === value.length &&
            value.every(id => currentResourceIds.includes(id))
          ) {
            // Create a map of id -> resource for quick lookup
            const resourceMap = {}
            currentResources.forEach(resource => {
              resourceMap[resource.id] = resource
            })

            // Create a new array with resources in the same order as the IDs
            const sortedResources = value
              .map(id => resourceMap[id])
              .filter(Boolean)

            // Update the filePreviewObject with the sorted resources
            $scope.filePreviewObject[$scope.to.object] = sortedResources
          } else {
            // Fetch resources from the server
            const resources = await Resource.find({
              filter: { where: { id: { inq: value } } }
            }).$promise

            // Sort the resources in the same order as the IDs in the model
            if (
              Array.isArray(resources) &&
              resources.length > 0 &&
              Array.isArray(value)
            ) {
              // Create a map of id -> resource for quick lookup
              const resourceMap = {}
              resources.forEach(resource => {
                resourceMap[resource.id] = resource
              })

              // Create a new array with resources in the same order as the IDs
              const sortedResources = value
                .map(id => resourceMap[id])
                .filter(Boolean)

              // Update the filePreviewObject with the sorted resources
              $scope.filePreviewObject[$scope.to.object] = sortedResources
            } else {
              $scope.filePreviewObject[$scope.to.object] = resources
            }
          }

          $scope.$applyAsync()
        }
      }
    }
  )

  if ($scope.to.object && $scope.to.shouldUpdateObject) {
    $scope.$watch(
      'filePreviewObject[to.object]',
      function (newValue, oldValue) {
        if (!_.isEqual(newValue, oldValue)) {
          if (_.isArray(newValue)) {
            if ($scope.to.mode === 'multiple') {
              // Update the object with the new array
              $scope.model[$scope.to.object] = newValue

              // Update the model key with the new order of IDs
              if (newValue && newValue.length > 0) {
                const newIds = newValue.map(r => r.id)

                // Only update if the IDs have actually changed
                const currentIds = _.get($scope.model, $scope.options.key, [])
                if (!_.isEqual(newIds, currentIds)) {
                  _.set($scope.model, $scope.options.key, newIds)
                }
              }
            } else {
              $scope.model[$scope.to.object] = newValue[0].id
            }
          } else {
            $scope.model[$scope.to.object] = newValue
          }
        }
      },
      true // Deep watch to detect changes in array order
    )
  }
}

module.exports = uploadController
