/* global angular videojs */
/** @ngInject */
function swiper () {
  require('./swiper.scss')
  return {
    template: require('./swiper.html'),
    bindings: {
      resources: '<'
    },
    controller:
      /**
       * @ngInject
       */
      function Swiper ($scope, $window, $element, $compile) {
        const self = this
        this.loading = true
        this.mainSwiper = null
        this.thumbnailSwiper = null

        this.$onInit = async function () {
          const loadedSwiper = await import(
            /* webpackChunkName: "swiper.bundle" */ 'swiper/bundle'
          )
          $window.SwiperLib = loadedSwiper.Swiper
          await import(
            /* webpackChunkName: "swiper.css.bundle" */ 'swiper/css/bundle'
          )
          this.initSwipers()
        }
        this.initSwipers = function () {
          const mainSwiperWrapper = $element[0].querySelector(
            `.mainSwiper .swiper-wrapper`
          )
          this.resources.forEach(resource => {
            const div = angular.element('<div class="swiper-slide"></div>')
            switch (resource.resourceType) {
              case 'image':
                div.append(
                  `<img src="api/Resources/${resource.id}/medium/0" lightbox-src="api/Resources/${resource.id}/0/0" md-lightbox alt="${resource.originalFileName}" />`
                )
                break
              case 'video':
                div.append(
                  $compile(`<img preview="true" autoplay="false" class="customVideo" ta-insert-video="${resource.videoSrc}" ta-original-video="${resource.url}"
                poster="${resource.posterURL}" alt="Video poster" />`)(
                    $scope
                  )[0]
                )
                break
              case 'pdf':
                div.append(
                  $compile(
                    `<img class="customPdf" id="${resource.id}" src="./assets/images/pdf.jpg" ta-insert-video="${resource.url}" />`
                  )($scope)[0]
                )
                break
              case 'storyline':
                div.append(
                  $compile(
                    `<img class="customStoryline" id="${resource.id}" ta-insert-video="/public/${resource.folderName}/" />`
                  )($scope)[0]
                )
                break
              case 'other':
                div.append(
                  $compile(
                    `
                        <a target="${resource.target}" href="${resource.fileUrl}">
                        <span>
                          <md-icon md-font-icon="icon-download"></md-icon>
                          ${resource.originalFilename}
                        </span>
                        <md-tooltip>
                          <span translate="FM.DOWNLOAD">Download</span>
                        </md-tooltip>
                      </a>
                        `
                  )($scope)[0]
                )
                break
            }
            mainSwiperWrapper.appendChild($compile(div[0])($scope)[0])
          })

          const thumbNailSwiperWrapper = $element[0].querySelector(
            `.tumbsSwiper .swiper-wrapper`
          )
          this.resources.forEach(resource => {
            const div = angular.element(
              '<div layout="column" class="swiper-slide"></div>'
            )
            div.append(
              `<img
              lightbox-src="api/Resources/${resource.id}/0/0"
              ng-src="api/Resources/${resource.id}/medium/0"
            />`
            )
            div.append(`<span>${resource.originalFilename}</span>`)
            div.append(
              $compile(
                `<md-tooltip>
                ${resource.originalFilename}
              </md-tooltip>`
              )($scope)[0]
            )
            thumbNailSwiperWrapper.appendChild($compile(div[0])($scope)[0])
          })

          self.thumbnailSwiper = new $window.SwiperLib(
            $element[0].querySelector('.tumbsSwiper'),
            {
              rewind: true,
              spaceBetween: 10,
              slidesPerView: 4,
              freeMode: true,
              watchSlidesProgress: true,
              initialSlide: 0,
              grabCursor: true
            }
          )
          self.mainSwiper = new $window.SwiperLib(
            $element[0].querySelector('.mainSwiper'),
            {
              rewind: true,
              initialSlide: 0,
              spaceBetween: 5,
              grabCursor: true,
              pagination: {
                el: '.swiper-pagination',
                clickable: true
              },
              navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
              },
              noSwipingSelector: '.vjs-control-bar *',
              thumbs: {
                swiper: self.thumbnailSwiper
              }
            }
          )
          self.mainSwiper.on('transitionStart', () => {
            if (self.mainSwiper.previousRealIndex) {
              const currentItem =
                self.resources[self.mainSwiper.previousRealIndex]
              if (currentItem.resourceType === 'video') {
                const videoElement = $element[0].querySelector(
                  `.mainSwiper [data-swiper-slide-index="${self.mainSwiper.previousRealIndex}"] video`
                )
                if (videoElement) {
                  const videoElementId = videoElement.id
                  const myPlayer = videojs(videoElementId)
                  myPlayer.pause()
                }
              }
            }
          })
        }
        this.$onChanges = function (changes) {
          this.resources = changes.resources.currentValue
          if (changes.resources.previousValue.length) {
            this.destroySwipers()
            this.initSwipers()
          }
        }
        this.destroySwipers = function () {
          if (this.mainSwiper) this.mainSwiper.destroy()
          if (this.thumbnailSwiper) this.thumbnailSwiper.destroy()
          const mainSwiperWrapper = $element[0].querySelector(
            `.mainSwiper .swiper-wrapper`
          )
          while (mainSwiperWrapper.firstChild) {
            mainSwiperWrapper.removeChild(mainSwiperWrapper.firstChild)
          }
          const thumbNailSwiperWrapper = $element[0].querySelector(
            `.tumbsSwiper .swiper-wrapper`
          )
          while (thumbNailSwiperWrapper.firstChild) {
            thumbNailSwiperWrapper.removeChild(
              thumbNailSwiperWrapper.firstChild
            )
          }
        }
        this.$onDestroy = function () {
          this.destroySwipers()
        }
      }
  }
}

module.exports = swiper
