/* global angular _ kendo */

import CreateEditStockDialogController from './dialog/create-edit-stock.dialog.controller.js'

/** @ngInject */
function StockManagementController (
  $scope,
  $rootScope,
  $translate,
  $mdDialog,
  $state,
  $stateParams,
  Stock,
  PermissionUtils,
  Page,
  ResolvedPart,
  KendoGridHelper,
  ViewsService,
  DialogService,
  htmlWork,
  $mdToast,
  DateTimeFormatService
) {
  $scope.canDelete = PermissionUtils.isPermit('Stock', 'deleteById')
  $scope.stocksById = {}
  $scope.createEditStockDialog = function createEditStockDialog (stockId) {
    if (
      stockId &&
      $scope.stocksById[stockId].availableQuantity <
        $scope.stocksById[stockId].quantity
    ) {
      $rootScope.showErrorToast(
        $translate.instant(
          'PartAssembly.STOCK_MANAGEMENT_PAGE.CANNOT_EDIT_USED_STOCK'
        )
      )
      return
    }
    const {
      isCombinated,
      isSerial,
      number,
      combinationParts,
      hasExpirationDate,
      currentRevision,
      revisions,
      managedByRevision
    } = ResolvedPart
    $mdDialog
      .show({
        controller: CreateEditStockDialogController,
        multiple: true,
        fullscreen: true,
        resolve: {
          ResolvedStock: Stock => {
            if (stockId) {
              return Stock.findOne({ filter: { where: { id: stockId } } })
                .$promise
            }
            return null
          },
          ResolvedStockOptions: Stock => {
            return new Promise(async (resolve, reject) => {
              if (isCombinated) {
                const partNumbers = combinationParts.map(part => part.number)
                const stocks = await Stock.find({
                  filter: {
                    where: {
                      source: { inq: ['local', 'manual'] },
                      isSerial: false,
                      sku: { inq: partNumbers }
                    },
                    fields: {
                      sku: true,
                      workorderNumber: true
                    }
                  }
                }).$promise
                const stocksBySKU = _.groupBy(stocks, 'sku')
                resolve(
                  combinationParts.map(part => {
                    part.availableLotNumbers = (
                      stocksBySKU[part.number] || []
                    ).map(arr => arr.workorderNumber)
                    return part
                  })
                )
              }
              resolve([])
            })
          }
        },
        locals: {
          partNumber: number,
          isSerial: isSerial || false,
          isCombinated: isCombinated || false,
          hasExpirationDate: hasExpirationDate || false,
          currentRevision: currentRevision || null,
          revisions: revisions.filter(rev => rev.active) || [],
          managedByRevision: managedByRevision || false,
          partDesc: ResolvedPart.name
        },
        template: require('./dialog/create-edit-stock.dialog.html'),
        parent: angular.element(document.body),
        targetEvent: '',
        escapeToClose: true,
        clickOutsideToClose: false
      })
      .then(
        function () {
          $scope.kendoGrid.reloadData()
        },
        function () {}
      )
  }
  $scope.deleteStockDialog = async function deleteStockDialog (stockId) {
    const serialOrWorkOrderNumber =
      $scope.stocksById[stockId].serial &&
      $scope.stocksById[stockId].serial !== ''
        ? $scope.stocksById[stockId].serial
        : $scope.stocksById[stockId].workorderNumber
    DialogService.deleteDialog(
      $translate.instant(
        'PartAssembly.STOCK_MANAGEMENT_PAGE.REAL_DELETE',
        {},
        null,
        null,
        'sceParameters'
      ),
      serialOrWorkOrderNumber
    ).then(
      async function () {
        $rootScope.loadingProgress = true
        await Stock.deleteStock({
          stockId,
          deleteStockFromWorkorder: false
        })
          .$promise.then(() => {
            $mdToast.show(
              $mdToast.nextplus({
                position: $rootScope.toastLocation,
                parent: 'document.body',
                theme: 'success-toast',
                hideDelay: 3000
              })
            )
            $mdToast.updateTextContent(
              $translate.instant(
                'PartAssembly.STOCK_MANAGEMENT_PAGE.DELETE_SUCCESS'
              )
            )
            $scope.kendoGrid.reloadData()
          })
          .catch(e => {
            $rootScope.loadingProgress = false
            console.error(e)
            if (e?.data?.error?.details?.stockDetails) {
              $rootScope.showErrorToast(
                $translate.instant(e?.data?.error?.code, {
                  stockDetails: e?.data?.error?.details?.stockDetails
                })
              )
            } else {
              $rootScope.showErrorToast(
                $translate.instant(e?.data?.error?.code)
              )
            }
          })
          .finally(() => {
            $rootScope.loadingProgress = false
          })
      },
      function () {}
    )
  }

  const initScreen = async function initScreen () {
    $scope.title = $rootScope.title = $translate.instant(
      'PartAssembly.STOCK_MANAGEMENT_PAGE.TITLE',
      {
        partNumber: ResolvedPart.number
      }
    )
    Page.setTitleText($scope.title)
    $scope.PermissionUtils = PermissionUtils

    const defaultTableColumns = [
      // WORKORDER_NUMBER
      {
        uniqueId: '5f0fdf3f-a547-45cf-84ca-1faa43ed470d',
        field: 'workorderNumber',
        translateCode: 'PartAssembly.STOCK_MANAGEMENT_PAGE.WO_LOT'
        // media: '(min-width: 768px)',
      },
      // QUANTITY
      {
        uniqueId: '0590676f-cf9e-44cb-82af-c541e8523ee6',
        field: 'quantity',
        translateCode: 'PartAssembly.STOCK_MANAGEMENT_PAGE.QUANTITY',
        type: 'number'
        // media: '(min-width: 768px)',
      },
      // AVAILABLE_QUANTITY
      {
        uniqueId: 'f3812b61-fd71-47a2-8899-1d276897d8fb',
        field: 'availableQuantity',
        translateCode: 'PartAssembly.STOCK_MANAGEMENT_PAGE.AVAILABLE_QUANTITY',
        type: 'number'
        // media: '(min-width: 768px)',
      },
      // REVISION
      {
        uniqueId: '2c9bf4be-e56b-4e0d-ad30-4402174c7225',
        field: 'skuRev',
        translateCode: 'PartAssembly.STOCK_MANAGEMENT_PAGE.REVISION',
        type: 'string'
        // media: '(min-width: 768px)',
      },
      // EXPIRATION_DATE
      {
        uniqueId: '71bb156a-2a8c-496a-94e3-a8e8accddf58',
        field: 'expirationDate',
        translateCode: 'PartAssembly.STOCK_MANAGEMENT_PAGE.EXPIRATION_DATE',
        type: 'date',
        // media: '(min-width: 768px)',
        template: data => {
          return data.expirationDate === null
            ? '--'
            : DateTimeFormatService.formatDateTime(data.expirationDate, 'date')
        }
      }
    ]

    if (ResolvedPart.isSerial) {
      defaultTableColumns.push({
        uniqueId: 'fc60e7f1-eb07-4e36-bfeb-385d8c1d5803',
        field: 'serial',
        translateCode: 'PartAssembly.STOCK_MANAGEMENT_PAGE.SERIAL'
        // media: '(min-width: 768px)',
      })
    } else if (ResolvedPart.isCombinated) {
      for (let i = 0; i < ResolvedPart.combinationParts.length; i++) {
        const combinationPart = ResolvedPart.combinationParts[i]
        defaultTableColumns.push({
          uniqueId: combinationPart.number,
          field: `combinationParts[${i}].lotNumber`,
          translateCode: `(${combinationPart.number}) ${combinationPart.name}`
          // media: '(min-width: 768px)',
        })
      }
    }
    const stockStatusOptions = [
      {
        id: 'FREE',
        name: $translate.instant('PartAssembly.STOCK_MANAGEMENT_PAGE.FREE')
      },
      {
        id: 'USED',
        name: $translate.instant('PartAssembly.STOCK_MANAGEMENT_PAGE.USED')
      }
    ]

    defaultTableColumns.push(
      ...[
        // STOCK_STATUS
        {
          uniqueId: 'e0b0b5c9-0b0a-4b0e-8b0a-0b0a0b0a0b0a',
          field: 'stockStatus',
          translateCode: 'PartAssembly.STOCK_MANAGEMENT_PAGE.STOCK_STATUS',
          type: 'string',
          values: stockStatusOptions.map(o => ({ text: o.name, value: o.id })),
          // media: '(min-width: 768px)',
          filterable: {
            mode: 'row',
            cell: {
              showOperators: false,
              operator: 'eq',
              suggestionOperator: 'eq',
              template: function (args) {
                args.element.kendoDropDownList({
                  filter: 'contains',
                  autoBind: false,
                  dataTextField: 'name',
                  dataValueField: 'id',
                  dataSource: new kendo.data.DataSource({
                    data: stockStatusOptions
                  }),
                  valuePrimitive: true
                })
              }
            }
          }
          // media: '(min-width: 768px)',
        },
        // ACTIONS
        {
          uniqueId: '1a55d0a4-c906-472a-8004-39b37c03cb12',
          field: 'id',
          translateCode: 'COMMON.ACTIONS',
          headerAttributes: {
            class: 'actions-column-header'
          },
          filterable: false,
          sortable: false,
          // media: '(min-width: 768px)',
          trustedTemplate: data => {
            $scope.stocksById[data.id] = data
            let html = '<div layout="row" layout-align="center center">'
            html += `<div layout="row" layout-align="center center">`
            html += `<md-button class="md-icon-button" ng-click="createEditStockDialog('${data.id}')">
                  <md-tooltip><span translate="PartAssembly.STOCK_MANAGEMENT_PAGE.EDIT_WO_LOT"></span></md-tooltip>
                  <md-icon md-font-icon="icon-pencil" class="s18"></md-icon>
                </md-button>`
            html += `<md-button class="md-icon-button" ng-if="canDelete" ng-click="deleteStockDialog('${data.id}')">
                  <md-tooltip><span translate="PartAssembly.STOCK_MANAGEMENT_PAGE.DELETE"></span></md-tooltip>
                  <md-icon md-font-icon="icon-delete" class="s16 md-warn"></md-icon>
                </md-button>
            </div>`
            return html
          }
        }
      ]
    )

    const basicFields = {
      id: true,
      workorderNumber: true,
      quantity: true,
      serial: true,
      skuRev: true,
      combinationParts: true,
      modified: true,
      source: true,
      stockStatus: true,
      availableQuantity: true,
      productionQuantity: true,
      expirationDate: true
    }

    const where = {
      source: { inq: ['local', 'manual'] },
      sku: ResolvedPart.number,
      productionQuantity: { gt: 0 }
    }

    const baseFilter = {
      where,
      order: 'created DESC',
      fields: basicFields
    }

    const { columns, selectedViewId } =
      ViewsService.getTablesColumns(defaultTableColumns)

    const { newBaseFilter, filters } = ViewsService.getViewCustomFilters(
      selectedViewId,
      _.cloneDeep(baseFilter)
    )

    const defaultTableSetup = {
      // stateName
      // ignoreParams
      find: Stock.find,
      count: Stock.count,
      cleanBaseFilter: baseFilter,
      baseFilter: newBaseFilter,
      selectedViewId,
      columns: defaultTableColumns,
      extraDataBound: () => {
        $scope.rows = {}
        $scope.kendoGrid.instance._data = $scope.kendoGrid.instance._data.map(
          stock => {
            const combinationParts = []
            const combinationPartsLotByNumber =
              stock.combinationParts && Array.isArray(stock.combinationParts)
                ? stock.combinationParts.reduce((acc, part) => {
                    acc[part.number] = part.lotNumber
                    return acc
                  }, {})
                : {}
            ResolvedPart.combinationParts.forEach(combinationPart => {
              if (combinationPartsLotByNumber[combinationPart.number]) {
                combinationParts.push({
                  number: combinationPart.number,
                  lotNumber: combinationPartsLotByNumber[combinationPart.number]
                })
              } else {
                combinationParts.push({
                  number: combinationPart.number,
                  lotNumber: null
                })
              }
            })
            stock.combinationParts = combinationParts
            return stock
          }
        )
      }
    }

    const defaultTableToolbarSetup = {
      // stateName
      columns: defaultTableColumns,
      currentColumnIds: columns.map(c => c.uniqueId),
      filters,
      selectedViewId,
      title: $scope.title
    }

    $scope.kendoGrid = await KendoGridHelper.GridInstance(
      defaultTableSetup,
      $scope,
      columns
    )

    await $scope.kendoGrid.isBound()

    $scope.tableToolbar = await ViewsService.GridToolBarInstance(
      defaultTableToolbarSetup,
      $scope.kendoGrid,
      $scope
    )

    $scope.$applyAsync()
  }

  initScreen()

  $scope.headerOptions = {
    icon: 'icon-import',
    template: require('app/templates/headers/simple.html'),
    title: $scope.title,
    fabButton: {
      template: require('app/templates/headers/fab-button.html'),
      action: $scope.createEditStockDialog,
      icon: 'icon-plus',
      href: '',
      state: '',
      showExpression: () => PermissionUtils.isPermit('Stock', 'create')
    }
  }
}

module.exports = {
  StockManagementController
}
