/* global _ */
const debug = require('debug')('nextplus:model-select-helper')

module.exports = class ModelSelectHelper {
  constructor (findMethod, baseFilterObject, mapObject, model, key, deleted) {
    this.originalFindMethod = findMethod
    this.model = model
    this.key = key
    this.optionsToDisplay = []
    this.mapObject = { ...mapObject }
    this.displayKey =
      typeof this.mapObject.name === 'string' ? this.mapObject.name : 'name'
    this.isInitialized = false
    this.skip = 0
    this.limit = 20
    this.deleted = deleted || false
    this.setBaseFilterObject(baseFilterObject)
  }

  findMethod ({ filter }) {
    // Remove empty arrays from where object
    filter?.where?.and.forEach((condition, index) => {
      if (condition?.or?.length === 0) {
        filter.where.and.splice(index, 1)
      }
    })
    return this.originalFindMethod({ filter }).$promise
  }

  setBaseFilterObject (baseFilterObject) {
    this.baseFilterObject = baseFilterObject
    const where = { and: [{}, { or: [] }] }
    if (this.baseFilterObject?.where) {
      where.and[0] = this.baseFilterObject.where
    }
    this.filterObject = {
      where,
      fields: this.baseFilterObject?.fields,
      deleted: this.baseFilterObject?.deleted,
      skip: this.skip,
      limit: this.limit
    }
  }

  reset () {
    this.skip = 0
    this.limit = 20
    this.isInitialized = false
  }

  alreadyInit () {
    return this.isInitialized
  }

  async getFirstOptions () {
    const filterObject = _.cloneDeep(this.filterObject)
    const options = await this.findMethod({ filter: filterObject })
    this.isInitialized = true
    this.firstOptions = options
    this.setOptionsToDisplay(options)
  }

  async initOptions (preloadOptions) {
    debug('initOptions')
    const options = []
    let selectedValues = _.get(this.model, this.key)
    if (selectedValues && !Array.isArray(selectedValues)) {
      selectedValues = [selectedValues]
    }
    if (preloadOptions) {
      preloadOptions.forEach(option => {
        option._preloaded = true
      })
      options.push(...preloadOptions)
    } else {
      const filterObject = _.cloneDeep(this.filterObject)
      if (selectedValues && selectedValues.length) {
        const { id } = this.mapObject
        if (this.deleted) {
          filterObject.deleted = true
        }
        const duplicatedFilter = _.cloneDeep(filterObject)
        duplicatedFilter.where.and[1].or.push({
          [id]: { inq: selectedValues }
        })
        options.push(...(await this.findMethod({ filter: duplicatedFilter })))

        // filterObject.limit = filterObject.limit - selectedValues.length
      } else {
        options.push(...(await this.findMethod({ filter: filterObject })))
      }
    }

    if (!selectedValues || selectedValues.length === 0) {
      this.isInitialized = true
      this.firstOptions = options
    }
    this.setOptionsToDisplay(options)
  }

  getOptionsToDisplay () {
    debug('getOptionsToDisplay', this.optionsToDisplay)
    return [...this.optionsToDisplay]
  }

  setOptionsToDisplay (options, append = false) {
    const that = this
    const manipulatedOptions = options.map(option => {
      if (typeof that.mapObject.name === 'function') {
        return {
          [that.mapObject.id]: option[that.mapObject.id],
          id: option[that.mapObject.id],
          name: that.mapObject.name(option),
          _preloaded: option._preloaded,
          [that.mapObject.displayName]: option[that.mapObject.displayName],
          originalModel: option
        }
      } else {
        return {
          id: option[that.mapObject.id],
          [that.mapObject.id]: option[that.mapObject.id],
          [that.mapObject.name]: option[that.mapObject.name],
          _preloaded: option._preloaded,
          [that.mapObject.displayName]: option[that.mapObject.displayName],
          originalModel: option
        }
      }
    })
    if (append) {
      this.optionsToDisplay = this.optionsToDisplay.concat(manipulatedOptions)
    } else {
      this.optionsToDisplay = manipulatedOptions
    }
    return manipulatedOptions
  }

  async loadMore () {
    debug('loadMore')
    const allVisibleOptionsArePreloaded = this.optionsToDisplay.every(
      opt => opt._preloaded
    )
    if (
      this.optionsToDisplay.length >= this.limit ||
      allVisibleOptionsArePreloaded
    ) {
      this.skip = this.optionsToDisplay.length
      const filterObject = _.cloneDeep(this.filterObject)
      filterObject.skip = this.skip
      const options = await this.findMethod({ filter: filterObject })
      debug('loadMore: options', options)
      const manipulatedOptions = this.setOptionsToDisplay(options, true)
      return manipulatedOptions
    } else {
      debug(
        'loadMore: no more options to load (optionsToDisplay.length >= limit)'
      )
      return []
    }
  }

  async makeSearch (query) {
    debug('makeSearch')
    let { name, searchableKeys } = this.mapObject
    if (!searchableKeys || searchableKeys.length === 0) {
      searchableKeys = [name]
    }
    const conditions = []
    if (!query || query === '') {
      this.skip = 0
      if (this.filterObject.where?.and?.[1]?.or) {
        this.filterObject.where.and[1].or =
          this.filterObject.where.and[1].or.filter(
            condition => !searchableKeys.includes(Object.keys(condition)[0])
          )
      }
      this.filterObject.skip = 0
      this.filterObject.limit = 20
      this.setOptionsToDisplay(this.firstOptions)

      // this.optionsToDisplay = this.firstOptions
      return this.optionsToDisplay
    } else {
      if (!this.filterObject.where) {
        this.filterObject.where = {}
      }
      searchableKeys.forEach(key => {
        conditions.push({
          [key]: {
            regexp: `/${query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}/i`
          }
        })
      })
      this.filterObject.where.and[1].or = conditions

      const filterObject = _.cloneDeep(this.filterObject)
      const options = await this.findMethod({
        filter: filterObject
      })
      this.setOptionsToDisplay(options)
      // this.optionsToDisplay = options
      return this.optionsToDisplay
    }
  }

  findCandidates (word) {
    debug('findCandidates')
    let results = this.fuse.search(word)
    let k = 5
    if (results.length > 5) {
      for (; k < results.length; k++) {
        if (results[k].score > 0.4) {
          break
        }
      }
    }
    results = results.splice(0, k)
    return results.filter(r => r.score < 0.00001)
  }

  ocrCallback (words, eventObject, callback) {
    debug('ocrCallback')
    const that = this
    console.log('words', words)
    if (!_.isArray(words) && !_.isUndefined(words.text)) {
      // In case of barcode scan
      const candidates = that.findCandidates(words.text)
      if (candidates.length === 1) {
        _.set(that.model, that.key, candidates[0].item.id)
        return true
      } else {
        return words.text
      }
    } else {
      if (that.visionService.androidWithNewVersion) {
        const resultsFound = false
        for (let w = 0; w < words.length; w++) {
          const candidates = that.findCandidates(words[w])
          if (candidates.length === 1) {
            _.set(that.model, that.key, candidates[0].item.id)
            return true
          }
        }
        if (!resultsFound) {
          eventObject.returnWords = false
          eventObject.callback = callback
          that.visionService.openOCRSelectDialog(
            words,
            eventObject,
            `${that.type}_${that.key}`
          )
        }
      } else {
        return words
      }
    }
  }
}
