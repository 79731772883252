/* global _ angular */

/** @ngInject */
function gridToolbar () {
  require('./grid-toolbar.scss')
  return {
    template: require('./grid-toolbar.html'),
    bindings: {
      instance: '<',
      downloadFunction: '&'
    },
    controller:
      /**
       * @ngInject
       */
      function gridToolbar (
        $rootScope,
        $scope,
        $window,
        $ocLazyLoad,
        TableSetup,
        UserModel,
        ViewsService,
        $mdDialog
      ) {
        this.$onChanges = async changeObj => {
          if (
            changeObj &&
            !_.isUndefined(changeObj.instance) &&
            changeObj.instance.currentValue
          ) {
            $scope.instance = changeObj.instance.currentValue
          }
        }

        this.$onInit = async function () {
          const that = this
          $scope.instance = that.instance
          $scope.downloadFunction = that.downloadFunction()
          const defaultTableColumns = $scope.instance.getColumns()
          const title = $scope.instance.getTitle()
          $scope.selectedViewId = $scope.instance.getSelectedViewId()
          $scope.allowDownload = $scope.instance.getAllowDownload()
          $scope.customButtons = $scope.instance.getCustomButtons()
          $scope.excelExport = $scope.instance.getExcelExport()
          $scope.allowAi =
            $scope.instance.getAllowAi() &&
            $rootScope.appSettings.aiLLMServiceEnabled
          $scope.gridOptions = that.instance.tableRef.gridOptions
          $scope.instance.openViewDialog = $scope.openViewDialog =
            async function openViewDialog (viewId = null) {
              try {
                const { columns, newViewId, filters } =
                  await ViewsService.openViewDialog(
                    $scope.instance.getStateName(),
                    title,
                    defaultTableColumns,
                    viewId,
                    $scope.instance.getColumnIds(),
                    $scope.instance.getFilters()
                  )

                $scope.selectedViewId = newViewId
                if (!filters) {
                  $scope.instance.setColumnIds(columns, newViewId)
                } else {
                  $scope.instance.setColumnsAndFilters(
                    columns,
                    newViewId,
                    filters
                  )
                }
              } catch (e) {
                console.error(e)
              }
            }
          $scope.print = async function print () {
            try {
              const gridWrapper =
                that.instance.tableRef.instance.wrapper[0].parentNode
              gridWrapper.classList.add('pdf-export')
              const originalParent = gridWrapper.parentNode
              // Append the grid to the body to make it visible
              document.body.appendChild(gridWrapper)
              document.querySelector('#main').style.display = 'none'
              $window.print()
              originalParent.appendChild(gridWrapper)
              document.querySelector('#main').style.display = ''
              gridWrapper.classList.remove('pdf-export')
            } catch (err) {
              throw new Error('Ooops, something went wrong, ' + err)
            }
          }
          $scope.downloadExcel = async function downloadExcel () {
            $rootScope.loadingProgress = true
            if (!('JSZip' in $window)) {
              const { default: JSZip } = await import(
                /* webpackChunkName: "jszip" */ 'jszip'
              )
              $window.JSZip = JSZip
            }

            const rawData = $scope.instance.getTableData()

            // manipulate data based on template or trustedTemplate of the column
            const columns = $scope.instance.getColumns()
            const excelData = []
            rawData.forEach(row => {
              const excelRow = {}
              columns.forEach(column => {
                if (column.trustedTemplate) {
                  excelRow[column.field] = column.trustedTemplate(row, false)
                } else if (column.template) {
                  excelRow[column.field] = column.template(row, false)
                } else {
                  excelRow[column.field] = row[column.field] || ''
                }
              })
              excelData.push(excelRow)
            })

            // Update the dataSource temporarily
            const originalData =
              that.instance.tableRef.instance.dataSource.data()
            that.instance.tableRef.instance.dataSource.data(excelData)

            // Perform the Excel export
            that.instance.tableRef.instance.saveAsExcel()

            // Restore the original data
            that.instance.tableRef.instance.dataSource.data(originalData)

            $rootScope.loadingProgress = false
          }
          $scope.selectView = async function selectView (option) {
            $rootScope.loadingProgress = true
            // $scope.instance.setSelectedViewId(option.id)
            $scope.selectedViewId = option.id
            const stateName = $scope.instance.getStateName()
            const userStateName = stateName.replace(/\./g, '_') // eslint-disabled-line
            const userViews = $rootScope.currentUser.views || {}
            userViews[userStateName] = option.id
            try {
              await UserModel.prototype$patchAttributes(
                { id: $rootScope.currentUser.id },
                { views: userViews }
              ).$promise
              $rootScope.currentUser.views = userViews
            } catch (err) {
              console.log(err)
            }
            const { columns, filters } = await ViewsService.getTablesColumns(
              defaultTableColumns,
              stateName
            )
            $rootScope.loadingProgress = false
            if (!filters) {
              $scope.instance.setColumnIds(columns, option.id)
            } else {
              $scope.instance.setColumnsAndFilters(columns, option.id, filters)
            }
          }

          $scope.deleteView = async function deleteView (option) {
            const stateName = $scope.instance.getStateName()
            $rootScope.loadingProgress = true
            const viewId = $scope.instance.getSelectedViewId()
            if (viewId === option.id) {
              $scope.instance.setSelectedViewId(null)
              $scope.selectedViewId = null
            }
            try {
              await TableSetup.destroyById({ id: option.id }).$promise
              if (
                $rootScope.appSettings.tableSetups &&
                $rootScope.appSettings.tableSetups[stateName]
              ) {
                const idx = _.findIndex(
                  $rootScope.appSettings.tableSetups[stateName],
                  {
                    id: option.id
                  }
                )
                if (idx > -1) {
                  $rootScope.appSettings.tableSetups[stateName].splice(idx, 1)
                }
              }
            } catch (err) {
              console.error(err)
            } finally {
              $rootScope.loadingProgress = false
            }
          }

          $scope.openViewsPopover = async function openViewsPopover (
            $mdMenu,
            e
          ) {
            $rootScope.loadingProgress = true
            const stateName = $scope.instance.getStateName()
            $scope.tableViewOptions = []
            const tableSetups = $rootScope.appSettings.tableSetups[stateName]
              ? _.cloneDeep($rootScope.appSettings.tableSetups[stateName])
              : []

            if (tableSetups && tableSetups.length) {
              const defaultTableSetup = _.remove(tableSetups, { default: true })
              if (defaultTableSetup && defaultTableSetup.length) {
                $scope.tableViewOptions.push(...defaultTableSetup)
              }
              $scope.tableViewOptions.push(...tableSetups)
            }
            $rootScope.loadingProgress = false
            $mdMenu.open(e)
          }
          $scope.askAi = async function askAi () {
            try {
              $scope.askAiLoading = true
              const stateName = $scope.instance.getStateName()
              return import(
                /* webpackChunkName: "ChatModule" */ '../../modules/main/chat/chat.module'
              )
                .then(async mod => {
                  $ocLazyLoad.inject('chat', mod.default)
                  const extraDataCsv = $scope.instance.getAiInjectionString()
                  const dialogData = {
                    tableData: $scope.instance.getTableData(),
                    columns: $scope.instance
                      .getTablesColumns()
                      .filter(column => column.sendToAi)
                      .filter(column => column.field !== 'id'),
                    downloadFunction: $scope.downloadFunction,
                    stateName,
                    extraDataCsv
                  }

                  return $mdDialog.show({
                    /** @ngInject */
                    controller: 'AskAiDialogController',
                    locals: dialogData,
                    template: require('../../modules/main/chat/controllers/ask-ai-dialog.html'),
                    parent: angular.element(document.body),
                    multiple: true,
                    fullscreen: true,
                    clickOutsideToClose: false
                  })
                })
                .catch(err => {
                  console.error(err)
                  throw new Error('Ooops, something went wrong')
                })
                .finally(() => {
                  global.graphUrls = {}
                  $scope.askAiLoading = false
                })
            } catch (ex) {
              console.error(ex)
              $scope.askAiLoading = false
            }
          }
        }
      }
  }
}

module.exports = gridToolbar
