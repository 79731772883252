/* global _ Audio barcodeDetectorPolyfill cancelAnimationFrame requestAnimationFrame */
import { BrowserStream } from './browser-stream'
require('./vision.dialog.scss')
/** @ngInject */
function VisionDialogController (
  $scope,
  $rootScope,
  $element,
  $mdDialog,
  $window,
  $timeout,
  locals,
  visionService,
  $sce
) {
  // State variables
  let detector = null
  let animationFrameId = null
  let videoElement = null
  let defaultDevice = null
  let canvas = null
  let browserStream = null

  // Constants
  const DETECTION_INTERVAL = 500 // Throttle detection to every 500ms
  let lastDetectionTime = 0

  // Callback setup
  const callback = locals.callback
    ? locals.callback
    : word => {
        console.log('call back !!!!', word)
      }
  const returnWords = locals.returnWords || false

  // Initialize BarcodeDetector
  if (!('BarcodeDetector' in $window)) {
    if (!barcodeDetectorPolyfill) {
      $scope.error = 'Barcode detection is not supported in this browser'
      $scope.userAgent = navigator.userAgent
    } else {
      $window.BarcodeDetector = barcodeDetectorPolyfill.BarcodeDetectorPolyfill
    }
  }

  // Initialize scope variables
  $scope.isLandscape = $window.matchMedia('(orientation: landscape)').matches
  $scope.optionsModel = { value: null, noDevice: false }
  $scope.cameraOptions = []
  $scope.imageCaptured = false
  $scope.barcodeOverlayHtml = $sce.trustAsHtml('')
  $scope.videoRendered = false

  // OCR-related functions
  $scope.captureImage = function captureImage () {
    if (!canvas) {
      canvas = document.querySelector('#canvas')
    }
    if (!videoElement) {
      videoElement = document.querySelector('#video')
    }
    canvas.width = videoElement.videoWidth
    canvas.height = videoElement.videoHeight
    const ctx = canvas.getContext('2d')
    ctx.drawImage(
      videoElement,
      0,
      0,
      videoElement.videoWidth,
      videoElement.videoHeight
    )
    $scope.imageCaptured = true
    if (browserStream) {
      browserStream.stopStreams()
    }
  }

  $scope.retry = function retry () {
    $scope.imageCaptured = false
    $timeout(
      () => {
        initializeVideoStream($scope.selectedDeviceId)
      },
      0,
      false
    )
  }

  $scope.sendImageForProcessing = function sendImageForProcessing () {
    switch ($scope.visionOption) {
      case 'ocr':
        {
          const dataURI = canvas.toDataURL('image/jpeg')
          visionService.sendOCRImageAndroidEvent(dataURI, callback, returnWords)
          $scope.cancel()
        }
        break
      default:
    }
  }

  // Dialog control functions
  $scope.cancel = function () {
    cleanup()
    $mdDialog.cancel()
  }

  // Event handlers
  const onOrientationChange = function (event) {
    $scope.isLandscape = event.target.screen.orientation.angle % 180 !== 0
    $scope.$apply()
    updateBarcodeOverlay()
  }

  const onResize = function () {
    cachedVideoDimensions = null // Reset cache on resize
    updateBarcodeOverlay()
  }

  const onVideoMetadataLoaded = function () {
    updateBarcodeOverlay()
  }

  const onVideoPlaying = function () {
    $scope.videoRendered = true
    $scope.$apply()
    startBarcodeDetection()
    updateBarcodeOverlay() // Ensure overlay is rendered when video starts playing
  }

  // Cleanup function
  const cleanup = function () {
    if (animationFrameId) {
      cancelAnimationFrame(animationFrameId)
      animationFrameId = null
    }
    if (browserStream) {
      browserStream.stopStreams()
    }
    if (videoElement) {
      videoElement.removeEventListener('loadedmetadata', onVideoMetadataLoaded)
      videoElement.removeEventListener('playing', onVideoPlaying)
    }
    $window.removeEventListener('orientationchange', onOrientationChange)
    $window.removeEventListener('resize', onResize)
  }

  // Barcode detection loop using requestAnimationFrame
  const detectBarcode = async function () {
    if (
      !detector ||
      !videoElement ||
      !videoElement.readyState ||
      videoElement.readyState < 2
    ) {
      animationFrameId = requestAnimationFrame(detectBarcode)
      return
    }

    const now = Date.now()
    if (now - lastDetectionTime >= DETECTION_INTERVAL) {
      try {
        const codes = await detector.detect(videoElement)
        lastDetectionTime = now

        if (codes && codes.length > 0) {
          $scope.optionsModel.value = codes[0].rawValue
          const audio = new Audio('assets/audio/beep.mp3')
          audio.play()
          cleanup()
          $scope.$apply()
          setTimeout(() => {
            $mdDialog.hide({
              mode: 'barcode',
              value: $scope.optionsModel.value
            })
          }, 1000)
          return
        }
      } catch (err) {
        console.error('Barcode detection error:', err)
      }
    }

    animationFrameId = requestAnimationFrame(detectBarcode)
  }

  const startBarcodeDetection = function () {
    if (!detector) {
      detector = new $window.BarcodeDetector()
    }
    if (animationFrameId) {
      cancelAnimationFrame(animationFrameId)
    }
    animationFrameId = requestAnimationFrame(detectBarcode)
  }

  // Video dimensions calculation with caching
  let cachedVideoDimensions = null
  const videoDimensions = function (video) {
    if (!video || !video.videoWidth) return null

    if (
      cachedVideoDimensions &&
      cachedVideoDimensions.videoWidth === video.videoWidth &&
      cachedVideoDimensions.videoHeight === video.videoHeight &&
      cachedVideoDimensions.offsetWidth === video.offsetWidth &&
      cachedVideoDimensions.offsetHeight === video.offsetHeight
    ) {
      return cachedVideoDimensions
    }

    const videoRatio = video.videoWidth / video.videoHeight
    let width = video.offsetWidth
    let height = video.offsetHeight
    const elementRatio = width / height

    if (elementRatio > videoRatio) width = height * videoRatio
    else height = width / videoRatio

    cachedVideoDimensions = {
      width,
      height,
      videoWidth: video.videoWidth,
      videoHeight: video.videoHeight,
      offsetWidth: video.offsetWidth,
      offsetHeight: video.offsetHeight
    }

    return cachedVideoDimensions
  }

  // Overlay update function
  const updateBarcodeOverlay = function () {
    if (!videoElement) return

    const dimensions = videoDimensions(videoElement)
    if (!dimensions) return

    $timeout(() => {
      const overlayHtml = $scope.renderBestScanPosition()
      $scope.barcodeOverlayHtml = $sce.trustAsHtml(overlayHtml)
    })
  }

  // Render barcode overlay SVG
  $scope.renderBestScanPosition = function renderBestScanPosition () {
    if (!videoElement) return '<div></div>'

    const dimensions = videoDimensions(videoElement)
    if (!dimensions) return '<div></div>'

    const xMidPoint = dimensions.width / 2
    const yMidPoint = dimensions.height / 2
    const barcodeWidth = 0.8 * dimensions.width
    const barcodeHeight = _.clamp(barcodeWidth / 3, 0, 0.8 * dimensions.height)
    const qrWidth = 0.4 * dimensions.width
    const qrHeight = qrWidth
    const left = xMidPoint - barcodeWidth / 2
    const right = xMidPoint + barcodeWidth / 2
    const top = yMidPoint - barcodeHeight / 2
    const bottom = yMidPoint + barcodeHeight / 2
    const leftQR = xMidPoint - qrWidth / 2
    const rightQR = xMidPoint + qrWidth / 2
    const topQR = yMidPoint - qrHeight / 2
    const bottomQR = yMidPoint + qrHeight / 2

    return `<svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      x='0'
      y='0'
      width='${dimensions.width}'
      height='${dimensions.height}'
      viewBox='0, 0, ${dimensions.width}, ${dimensions.height}'
    >
      <g>
        <path
          d=' M ${left},${top}
              L ${left},${bottom}
              L ${right},${bottom}
              L ${right},${top}
              L ${left},${top} z'
          fill='#0066ff'
          opacity='0.35'
          stroke-width='1'
          stroke-opacity='0.1'
          class="opacity"
        />
        <path
          d='M ${left},${yMidPoint} L ${right},${yMidPoint}'
          fill-opacity='0'
          stroke='#ed553b'
          stroke-width='3'
          class='guide-line'
        />
      </g>
      <g>
        <path
          d=' M ${leftQR},${topQR}
              L ${leftQR},${top}
              L ${rightQR},${top}
              L ${rightQR},${topQR}
              L ${leftQR},${topQR} z'
          fill='#0066ff'
          opacity='0.35'
          stroke-width='0'
          stroke-opacity='0.1'
          class="opacity"
        />
      </g>
      <g>
        <path
          d=' M ${leftQR},${bottom}
              L ${leftQR},${bottomQR}
              L ${rightQR},${bottomQR}
              L ${rightQR},${bottom}
              L ${leftQR},${bottom} z'
          fill='#0066ff'
          opacity='0.35'
          stroke-width='0'
          stroke-opacity='0.1'
          class="opacity"
        />
      </g>
    </svg>`
  }

  // Initialize video stream
  const initializeVideoStream = async function (deviceId) {
    if (!browserStream) {
      browserStream = new BrowserStream()
    }

    videoElement = document.getElementById('video')
    if (!videoElement) return

    // Set up video event listeners
    videoElement.addEventListener('loadedmetadata', onVideoMetadataLoaded)
    videoElement.addEventListener('playing', onVideoPlaying)

    try {
      await browserStream.startStreamProcess(deviceId, videoElement)
    } catch (err) {
      console.error('Failed to start video stream:', err)
      $scope.error = 'Failed to start video stream'
      $scope.$apply()
    }
  }

  // Camera selection handler
  $scope.cameraSelect = async function (deviceId, idx) {
    cleanup()

    $scope.selectedDeviceId = deviceId
    $scope.selectedDeviceIndex = idx
    $scope.imageCaptured = false

    $window.localStorage.setItem('vision-camera-position', JSON.stringify(idx))

    if ($scope.visionOption === 'barcode') {
      await initializeVideoStream(deviceId)
    } else {
      if (browserStream) {
        browserStream.stopStreams()
      }
      await initializeVideoStream(deviceId)
    }
  }

  // Mode switch handler
  $scope.switchMode = async function (mode) {
    cleanup()

    $scope.visionOption = mode
    $scope.imageCaptured = false

    if (mode === 'ocr') {
      if (browserStream) {
        browserStream.stopStreams()
      }
      await initializeVideoStream($scope.selectedDeviceId)
    } else if (mode === 'barcode') {
      await initializeVideoStream($scope.selectedDeviceId)
    }

    $window.localStorage.setItem(
      `vision-option-${locals.key}`,
      JSON.stringify(mode)
    )
  }

  // Dialog initialization
  const initVisionDialog = async function () {
    $element[0].style.zIndex = 90

    // Initialize scope variables
    $scope.visionOption = $rootScope.hasOcr
      ? JSON.parse(
          $window.localStorage.getItem(`vision-option-${locals.key}`)
        ) || 'barcode'
      : 'barcode'

    $scope.selectedDeviceIndex =
      JSON.parse($window.localStorage.getItem('vision-camera-position')) || 0

    // Set up event listeners
    $window.addEventListener('orientationchange', onOrientationChange)
    $window.addEventListener('resize', onResize)

    try {
      // Get available devices
      const devices = await navigator.mediaDevices.enumerateDevices()
      const videoInputDevices = devices.filter(
        device => device.kind === 'videoinput'
      )

      if (!videoInputDevices.length) {
        $scope.optionsModel.noDevice = true
        return
      }

      defaultDevice = videoInputDevices[0].deviceId
      videoInputDevices.forEach((device, index) => {
        $scope.cameraOptions.push({
          id: device.deviceId,
          name: device.label || `Camera ${index + 1}`
        })
      })

      // Initialize with selected device
      const selectedDevice = $scope.cameraOptions[$scope.selectedDeviceIndex]
      $scope.selectedDeviceId = selectedDevice
        ? selectedDevice.id
        : defaultDevice

      if ($scope.visionOption === 'barcode') {
        await initializeVideoStream($scope.selectedDeviceId)
      } else {
        await initializeVideoStream($scope.selectedDeviceId)
      }
    } catch (err) {
      console.error('Failed to initialize vision dialog:', err)
      $scope.error = 'Failed to initialize camera'
    }
  }

  // Cleanup on scope destroy
  $scope.$on('$destroy', cleanup)

  // Initialize
  initVisionDialog()
}

module.exports = VisionDialogController
